import React, { useState, useEffect, useMemo } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { orderService } from '../../services/orderService';
import { FaPlus, FaClipboardList, FaSearch, FaClock, FaUtensils, FaCheckCircle, FaTruck, FaShoppingCart } from 'react-icons/fa';
import AddOrderSidePanel from './AddOrderSidePanel';
import { foodTruckService } from '../../services/foodTruckService';
import PaymentDialog from './PaymentDialog';
import Loader from '../Common/Loader';
import OrderCard from './OrderCard';
import { Drawer, Box, Button, Typography, Grid2 } from '@mui/material';
import OrderWeekNavigator from './OrderWeekNavigator';
import { format } from 'date-fns';
import { formatCurrency } from '../../utils/helpers';

function Orders() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddOrderSidepanelOpen, setIsAddOrderSidepanelOpen] = useState(false);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [error, setError] = useState(null);
  const [services, setServices] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeekDate, setSelectedWeekDate] = useState(new Date());
  const [weekOrders, setWeekOrders] = useState({});
  
  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    let orderUnsubscribe;

    const initializeServices = () => {
      try {
        const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
        const orderSrv = orderService.getInstance(foodTruckId);
        setServices({ foodTruckSrv, orderSrv });
        return { foodTruckSrv, orderSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadData = async () => {
      try {
        setLoadingOrders(true);
        const { foodTruckSrv, orderSrv } = initializeServices();

        // Écouter les commandes de la semaine
        orderUnsubscribe = orderSrv.listenToWeekOrders({
          onUpdate: (ordersByDay) => {
            setWeekOrders(ordersByDay);
            setLoadingOrders(false);
          },
          onError: (error) => {
            console.error("Error fetching week orders:", error);
            setError("Une erreur s'est produite lors du chargement des commandes.");
            setLoadingOrders(false);
          },
          date: selectedWeekDate
        });

        // Charger les informations du food truck
        const truckData = await foodTruckSrv.getFoodTruck();
        setPaymentTypeList(truckData?.paymentTypeList || []);

      } catch (error) {
        handleError(error);
      }
    };

    loadData();

    return () => {
      if (orderUnsubscribe) orderUnsubscribe();
    };
  }, [foodTruckId, contextLoading, selectedWeekDate]);

  useEffect(() => {
    // Filtrer les commandes du jour sélectionné pour la vue principale
    const selectedDayKey = format(selectedDate, 'yyyy-MM-dd');
    setOrders(weekOrders[selectedDayKey] || []);
  }, [weekOrders, selectedDate]);

  const handleError = (error) => {
    console.error("An error occurred:", error);
    setError(error.message || "Une erreur inattendue s'est produite");
    setTimeout(() => setError(null), 5000);
  };

  const filteredOrders = useMemo(() => {
    return orders.filter(order =>
      order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.items.some(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order.customerName && order.customerName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [orders, searchTerm]);

  const groupedOrders = useMemo(() => {
    return filteredOrders.reduce((acc, order) => {
      if (!acc[order.status]) {
        acc[order.status] = [];
      }
      acc[order.status].push(order);
      return acc;
    }, {});
  }, [filteredOrders]);

  const getNextStatus = (currentStatus) => {
    const statusOrder = ['pending', 'preparing', 'ready', 'delivered'];
    const currentIndex = statusOrder.indexOf(currentStatus);
    return currentIndex < statusOrder.length - 1 ? statusOrder[currentIndex + 1] : null;
  };

  const handleNextStatus = async (orderId, currentStatus) => {
    if (!services?.orderSrv) return;

    try {
      const nextStatus = getNextStatus(currentStatus);
      if (nextStatus) {
        await services.orderSrv.updateOrderStatus(orderId, nextStatus);
      }
    } catch (error) {
      console.error("handleNextStatus", error);
      handleError(error);
    }
  };


  const handlePaymentStatusChange = async (order) => {
    setCurrentOrder(order);
    setPaymentDialogOpen(true);
  };

  const handleConfirmPayment = async (paymentDetails) => {
    console.log("handleConfirmPayment", paymentDetails);
    if (!services?.orderSrv || !currentOrder) return;

    try {
      const payments = Object.entries(paymentDetails).map(([method, amount]) => ({
        id: crypto.randomUUID(),
        method: getPaymentMethod(method),
        amount: Number(amount),
        timestamp: new Date(),
      }));
      console.log("payments", payments);

      await services.orderSrv.updateOrderPayment(currentOrder.id, {
        payments
      });
      setPaymentDialogOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const getPaymentMethod = (type) => {
    switch (type.toLowerCase()) {
      case 'espèces':
      case 'especes':
      case 'cash':
        return { type: 'cash' };
      case 'carte':
      case 'cb':
      case 'card':
        return { type: 'card' };
      case 'ticket':
      case 'tickets resto':
      case 'tr':
        return { type: 'ticket' };
      case 'application':
      case 'app':
        return { type: 'app' };
      default:
        return { type: 'other', description: type };
    }
  };

  const calculateRemainingAmount = (order) => {
    if (!order.payments) return order.totalPrice;
    
    const totalPaid = order.payments.reduce((sum, payment) => sum + payment.amount, 0);
    return order.totalPrice - totalPaid;
  };

  const renderOrderPaymentStatus = (order) => {
    const totalPaid = order.payments?.reduce((sum, payment) => sum + payment.amount, 0) || 0;
    const remaining = order.totalPrice - totalPaid;

    return (
      <div className="mt-2 space-y-1">
        <div className="text-sm">
          {order.payments?.map(payment => (
            <div key={payment.id} className="flex justify-between">
              <span>{payment.method.type === 'other' ? payment.method.description : payment.method.type}</span>
              <span>{formatCurrency(payment.amount)}</span>
            </div>
          ))}
        </div>
        <div className="flex justify-between font-medium">
          <span>Total payé:</span>
          <span>{formatCurrency(totalPaid)}</span>
        </div>
        {remaining > 0 && (
          <div className="flex justify-between text-red-600">
            <span>Restant:</span>
            <span>{formatCurrency(remaining)}</span>
          </div>
        )}
        <Button
          variant="contained"
          color={remaining > 0 ? "primary" : "secondary"}
          size="small"
          fullWidth
          onClick={() => handlePaymentStatusChange(order)}
        >
          {remaining > 0 ? "Ajouter un paiement" : "Modifier le paiement"}
        </Button>
      </div>
    );
  };
  const handleAddOrder = async (newOrderData) => {
    if (!services?.orderSrv) return;

    try {
      await services.orderSrv.addOrder(newOrderData);
      setIsAddOrderSidepanelOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const renderOrderColumn = (status, icon, title, bgColor) => (
    <div className={`flex-1 p-4 rounded-lg ${bgColor}`}>
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
        <span className="ml-2 bg-white text-gray-700 rounded-full px-2 py-1 text-sm">
          {groupedOrders[status]?.length || 0}
        </span>
      </h2>
      <div className="space-y-4">
        {groupedOrders[status]?.map(order => (
          <OrderCard
            key={order.id}
            order={order}
            onStatusChange={handleNextStatus}
            onPaymentChange={handlePaymentStatusChange}
            paymentStatus={renderOrderPaymentStatus(order)}
          />
        ))}
      </div>
    </div>
  );

  const renderDeliveredOrders = () => {
    const deliveredOrders = groupedOrders['delivered'] || [];
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FaTruck style={{ marginRight: '0.5rem' }} />
          Commandes livrées
          <span className="ml-2 bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-sm">
            {deliveredOrders.length}
          </span>
        </Typography>
        <Grid2 container spacing={2}>
          {deliveredOrders.map(order => (
            <Grid2 key={order.id} xs={12} sm={6} md={4}>
              <OrderCard
                order={order}
                onStatusChange={handleNextStatus}
                onPaymentChange={handlePaymentStatusChange}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    );
  };

  const renderOrders = () => {
    if (loadingOrders) return (<Loader />);
    if (filteredOrders.length === 0) {
      return <EmptyView message={orders.length === 0 ? "Aucune commande pour aujourd'hui" : "Aucune commande ne correspond à votre recherche"} />;
    }
    return (
      <>
        <div className="flex space-x-4 overflow-x-auto pb-4">
          {renderOrderColumn('pending', <FaClock className="text-yellow-500" />, 'En attente', 'bg-yellow-100')}
          {renderOrderColumn('preparing', <FaUtensils className="text-blue-500" />, 'En préparation', 'bg-blue-100')}
          {renderOrderColumn('ready', <FaCheckCircle className="text-green-500" />, 'Prêt', 'bg-green-100')}
        </div>
        {renderDeliveredOrders()}
      </>
    );
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaShoppingCart className="mr-3 text-emerald-500" />
          Commandes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={() => setIsAddOrderSidepanelOpen(true)}
        >
          Ajouter
        </Button>
      </Box>
  
      {/* Navigateur de semaine */}
      <Box sx={{ mb: 3 }}>
        <OrderWeekNavigator
          selectedDate={selectedDate}
          selectedWeekDate={selectedWeekDate}
          onDateChange={(newDate) => {
            setSelectedDate(newDate);
            // Les commandes seront mises à jour via l'effet
          }}
          weekOrders={weekOrders}
          loading={loadingOrders}
          onWeekChange={(newDate) => {
            setSelectedWeekDate(newDate);
            // Les commandes seront mises à jour via l'effet
          }}
        />
      </Box>
  
      {/* Barre de recherche */}
      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une commande..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border border-gray-300 rounded-md pl-10 pr-4 py-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      {renderOrders()}

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        paymentTypeList={paymentTypeList}
        totalAmount={currentOrder ? calculateRemainingAmount(currentOrder) : 0}
        onConfirm={handleConfirmPayment}
        existingPayments={currentOrder?.payments}
      />

      <Drawer
        anchor="right"
        open={isAddOrderSidepanelOpen}
        onClose={() => setIsAddOrderSidepanelOpen(false)}
      >
        <Box sx={{ width: 800 }}>
          <AddOrderSidePanel
            onClose={() => setIsAddOrderSidepanelOpen(false)}
            onAddOrder={handleAddOrder}
          />
        </Box>
      </Drawer>
    </Box>
  );
}

const EmptyView = ({ message }) => (
  <div className="flex flex-col items-center justify-center h-64 bg-white rounded-lg shadow-md">
    <FaClipboardList className="text-gray-300 text-5xl mb-4" />
    <p className="text-gray-600 text-xl font-medium text-center">{message}</p>
  </div>
);

export default Orders;