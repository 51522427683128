import { Typography } from '@mui/material';

function SectionTitle({ children }) {
    return (
        <Typography
            variant="h6"
            sx={{
                mt: 4,
                mb: 2,
                fontWeight: 'bold',
                color: 'primary.main',
                borderBottom: '2px solid',
                borderColor: 'primary.main',
                paddingBottom: 1
            }}
        >
            {children}
        </Typography>
    );
}


export default SectionTitle;