import React, { useContext, useState, useEffect } from "react";
import { auth } from "../services/firebase";
import { signOut } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminTruckId, setAdminTruckId] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAdminClaims = async (user) => {
    try {
      // Force token refresh to ensure latest claims
      await user.getIdToken(true);
      const idTokenResult = await user.getIdTokenResult();
      const adminClaims = idTokenResult.claims.admin;

      console.log("checkAdminClaims", {
        idTokenResult:idTokenResult,
        adminClaims:adminClaims,
      });
      if (adminClaims && typeof adminClaims === 'object') {
        // Chercher un truck ID avec la valeur true
        const truckEntry = Object.entries(adminClaims).find(([_, value]) => value === true);

      console.log("checkAdminClaims", {
        truckEntry:truckEntry
      });
        if (truckEntry) {
          console.log("checkAdminClaims returns true");
          setIsAdmin(true);
          setAdminTruckId(truckEntry[0]);
          return;
        }
      }

      // Si on arrive ici, pas de droits admin valides
      setIsAdmin(false);
      setAdminTruckId(null);
    } catch (error) {
      console.error("Error checking admin claims:", error);
      setIsAdmin(false);
      setAdminTruckId(null);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await checkAdminClaims(user);
      } else {
        setIsAdmin(false);
        setAdminTruckId(null);
      }
      setLoading(false);
    });

    // Setup listener for token changes to catch claim updates
    const tokenListener = auth.onIdTokenChanged(async (user) => {
      if (user) {
        await checkAdminClaims(user);
      }
    });

    return () => {
      unsubscribe();
      tokenListener();
    };
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setIsAdmin(false);
      setAdminTruckId(null);
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    isAdmin,
    adminTruckId,
    loading,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
}

// Hook pour accéder à l'ID du food truck
export function useTruckId() {
  const { adminTruckId } = useAuth();
  if (!adminTruckId) {
    throw new Error("No truck ID available. User might not be authenticated or not an admin.");
  }
  return adminTruckId;
}