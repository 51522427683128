import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Divider,
    Switch,
    FormControlLabel
} from '@mui/material';
import { FaUser, FaUserPlus } from 'react-icons/fa';
import ClientSearchOrGuestName from './ClientSearchOrGuestName';
import SectionTitle from '../Common/Title';

const ClientSelectionSection = ({ selectedClient, setSelectedClient, newOrder, handleCustomerNameChange }) => {
    const [isNewCustomer, setIsNewCustomer] = useState(false);

    const toggleCustomerType = () => {
        setIsNewCustomer(!isNewCustomer);
        if (selectedClient) {
            setSelectedClient(null);
        }
    };

    return (
        <div>
            <SectionTitle>Client</SectionTitle>
            <FormControlLabel
                control={
                    <Switch
                        checked={isNewCustomer}
                        onChange={toggleCustomerType}
                        name="customerType"
                        color="primary"
                    />
                }
                label={isNewCustomer ? "Nouveau client" : "Client existant"}
            />
            <Divider sx={{ my: 2 }} />

            {isNewCustomer ? (
                <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <FaUserPlus style={{ marginRight: '8px' }} />
                        Entrez le nom pour la nouvelle commande
                    </Typography>
                    <TextField
                        fullWidth
                        label="Nom de la commande"
                        value={newOrder.customerName}
                        onChange={handleCustomerNameChange}
                        margin="normal"
                        required
                        variant="outlined"
                    />
                </Box>
            ) : (
                <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <FaUser style={{ marginRight: '8px' }} />
                        Recherchez un client existant
                    </Typography>
                    <ClientSearchOrGuestName onSelectClient={setSelectedClient} />
                    {selectedClient && (
                        <Box sx={{ mt: 2, p: 2, bgcolor: 'primary.light', borderRadius: 1 }}>
                            <Typography variant="body1">
                                Client sélectionné: <strong>{selectedClient.name}</strong>
                                {selectedClient.email && ` (${selectedClient.email})`}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default ClientSelectionSection;