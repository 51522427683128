import React, { useState, useEffect } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { statsService } from '../../services/dashboardService';
import {
  FaUsers,
  FaMoneyBillWave,
  FaShoppingCart,
  FaStar,
  FaUserCircle,
  FaTachometerAlt
} from 'react-icons/fa';
import { formatCurrency } from '../../utils/helpers';
import OrderCalendar from './OrderCalendar';
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  useTheme
} from '@mui/material';
import Loader from '../Common/Loader';

const StatCard = ({ icon: Icon, title, value, subValue, color }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={1}
      sx={{
        height: '100%',
        minWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[8],
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: 6,
          backgroundColor: color || theme.palette.primary.main,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        }
      }}
    >
      <CardContent sx={{
        p: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 180,
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 2
        }}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '0.875rem',
              fontWeight: 500,
              textTransform: 'uppercase',
              letterSpacing: '0.5px'
            }}
          >
            {title}
          </Typography>
          <Icon
            style={{
              fontSize: '2rem',
              color: color || theme.palette.primary.main,
              opacity: 0.8,
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              fontSize: '1.75rem',
              lineHeight: 1.2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {value}
          </Typography>

          {subValue && (
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                color: theme.palette.text.secondary,
                fontSize: '0.875rem'
              }}
            >
              {subValue}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};


// Composant principal Dashboard
function Dashboard() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [stats, setStats] = useState({
    totalClients: 0,
    signedClients: 0,
    monthlyOrders: 0,
    monthlyRevenue: 0,
    bestSellingItem: { name: '', quantity: 0 },
    bestClient: { name: '', orderCount: 0 }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [service, setService] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (!foodTruckId || contextLoading) {
      setService(null);
      return;
    }

    try {
      const statsServiceInstance = statsService.getInstance(foodTruckId);
      setService(statsServiceInstance);
      setError(null);
    } catch (error) {
      console.error("Error initializing stats service:", error);
      setError(error.message);
      setService(null);
    }
  }, [foodTruckId, contextLoading]);

  useEffect(() => {
    if (!service) return;

    let unsubscribeClients;
    let unsubscribeOrders;

    const setupListeners = async () => {
      try {
        setLoading(true);

        unsubscribeClients = service.listenClientsCount(
          (clients) => {
            setStats(prevStats => ({
              ...prevStats,
              totalClients: clients.length,
              signedClients: clients.filter(client => client.email).length
            }));
          },
          (error) => {
            console.error("Error fetching clients:", error);
            setError("Erreur lors du chargement des clients");
          }
        );

        unsubscribeOrders = service.listenMonthlyOrders(
          (orders) => {
            const monthlyRevenue = orders.reduce((sum, order) => sum + order.totalPrice, 0);
            const monthlyOrders = orders.length;
            const itemsSold = orders.flatMap(order => order.items);

            const itemCounts = itemsSold.reduce((acc, item) => {
              acc[item.name] = (acc[item.name] || 0) + item.quantity;
              return acc;
            }, {});

            const bestSellingItem = Object.entries(itemCounts).reduce((best, [name, quantity]) =>
              quantity > best.quantity ? { name, quantity } : best
              , { name: '', quantity: 0 });

            const clientOrders = orders.reduce((acc, order) => {
              if (order.customerId && order.customerId !== 'Anonymous') {
                acc[order.customerId] = (acc[order.customerId] || 0) + 1;
              }
              return acc;
            }, {});

            const bestClient = Object.entries(clientOrders).reduce((best, [customerId, orderCount]) =>
              orderCount > best.orderCount ? { customerId, orderCount } : best
              , { customerId: '', orderCount: 0 });

            let clientName = 'Aucun client éligible';
            if (bestClient.customerId) {
              const clientOrder = orders.find(order => order.customerId === bestClient.customerId);
              clientName = clientOrder ? clientOrder.customerName : 'Client inconnu';
            }

            setStats(prevStats => ({
              ...prevStats,
              monthlyOrders,
              monthlyRevenue,
              bestSellingItem,
              bestClient: { name: clientName, orderCount: bestClient.orderCount }
            }));
          },
          (error) => {
            console.error("Error fetching orders:", error);
            setError("Erreur lors du chargement des commandes");
          }
        );

      } catch (error) {
        console.error("Error setting up listeners:", error);
        setError("Erreur lors de l'initialisation des écouteurs");
      } finally {
        setLoading(false);
      }
    };

    setupListeners();

    return () => {
      if (unsubscribeClients) unsubscribeClients();
      if (unsubscribeOrders) unsubscribeOrders();
    };
  }, [service]);

  if (contextLoading || loading) return <Loader />;
  if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const statCards = [
    {
      icon: FaUsers,
      title: "Clients",
      value: stats.totalClients,
      subValue: `${stats.signedClients} inscrits`,
      color: theme.palette.primary.main
    },
    {
      icon: FaShoppingCart,
      title: "Commandes ce mois",
      value: stats.monthlyOrders,
      color: theme.palette.success.main
    },
    {
      icon: FaMoneyBillWave,
      title: "Revenu mensuel",
      value: formatCurrency(stats.monthlyRevenue),
      color: theme.palette.warning.main
    },
    {
      icon: FaStar,
      title: "Meilleure vente",
      value: stats.bestSellingItem.name,
      subValue: `Quantité : ${stats.bestSellingItem.quantity}`,
      color: theme.palette.info.main
    },
    {
      icon: FaUserCircle,
      title: "Meilleur client du mois",
      value: stats.bestClient.name,
      subValue: `Commandes : ${stats.bestClient.orderCount}`,
      color: theme.palette.secondary.main
    }
  ];

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaTachometerAlt className="mr-3 text-emerald-500" />
          Tableau de bord
        </Typography>
      </Box>

      <Grid2
        container
        spacing={3}
        sx={{
          mb: 4,
        }}
      >
        {statCards.map((card, index) => (
          <Grid2
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              minWidth: '200px',
              flex: '1 1 0px', // Pour que les cartes aient la même largeur dans leur colonne
            }}
          >
            <StatCard {...card} />
          </Grid2>
        ))}
      </Grid2>

      <Box sx={{ mt: 4 }}>
        <OrderCalendar />
      </Box>
    </Box>
  );
}

export default Dashboard;