import { db } from './firebase';
import { collection, onSnapshot, query, where, Timestamp } from 'firebase/firestore';

export class StatsService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.usersCollection = `foodTrucks/${foodTruckId}/users`;
    this.ordersCollection = `foodTrucks/${foodTruckId}/orders`;
  }

  /**
   * Écoute le nombre de clients
   * @param {Function} onUpdate - Callback pour les mises à jour
   * @param {Function} onError - Callback pour les erreurs
   * @returns {Function} Fonction de désabonnement
   */
  listenClientsCount(onUpdate, onError) {
    try {
      const q = query(
        collection(db, this.usersCollection),
        where('isAdmin', '==', false)
      );

      return onSnapshot(q,
        (snapshot) => {
          const userData = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(item => !item.isDeleted);
          onUpdate(userData);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up clients count listener:', error);
      onError?.(error);
    }
  }

  /**
   * Écoute les commandes du mois en cours
   * @param {Function} onUpdate - Callback pour les mises à jour
   * @param {Function} onError - Callback pour les erreurs
   * @returns {Function} Fonction de désabonnement
   */
  listenMonthlyOrders(onUpdate, onError) {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      today.setDate(1);
      const tomorrow = new Date(today);
      tomorrow.setMonth(tomorrow.getMonth() + 1);

      const ordersQuery = query(
        collection(db, this.ordersCollection),
        where('pickupTime', '>=', Timestamp.fromDate(today)),
        where('pickupTime', '<', Timestamp.fromDate(tomorrow)),
      );

      return onSnapshot(ordersQuery,
        (snapshot) => {
          const orderData = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(order => !order.isDeleted && !order.isCanceled);
          onUpdate(orderData);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up monthly orders listener:', error);
      onError?.(error);
    }
  }

  /**
   * Écoute les statistiques quotidiennes des commandes
   * @param {Date} startDate - Date de début
   * @param {Date} endDate - Date de fin
   * @param {Function} callback - Callback pour les résultats
   * @returns {Function} Fonction de désabonnement
   */
  listenDailyOrderStats(startDate, endDate, callback) {
    try {
      const ordersQuery = query(
        collection(db, this.ordersCollection),
        where('createdAt', '>=', Timestamp.fromDate(startDate)),
        where('createdAt', '<=', Timestamp.fromDate(endDate))
      );

      return onSnapshot(ordersQuery,
        (snapshot) => {
          const dailyStats = {};

          const orderData = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(order => !order.isDeleted && !order.isCanceled);
          orderData.forEach((order) => {
            const date = order.createdAt.toDate().toISOString().split('T')[0];

            if (!dailyStats[date]) {
              dailyStats[date] = { count: 0, revenue: 0 };
            }

            dailyStats[date].count += 1;
            dailyStats[date].revenue += order.totalPrice || 0;
          });

          callback(dailyStats);
        },
        (error) => {
          console.error("Error fetching daily order stats:", error);
          callback({}, error);
        }
      );
    } catch (error) {
      console.error('Error setting up daily stats listener:', error);
      callback({}, error);
    }
  }

  /**
   * Formatte les statistiques quotidiennes pour l'affichage
   * @param {Object} dailyStats - Statistiques brutes
   * @returns {Object} Statistiques formatées
   */
  formatDailyStats(dailyStats) {
    return Object.entries(dailyStats).map(([date, stats]) => ({
      date,
      ...stats,
      averageOrderValue: stats.count > 0 ? stats.revenue / stats.count : 0
    }));
  }
}

// Export singleton instance factory
export const statsService = {
  instances: new Map(),

  /**
   * Récupère ou crée une instance du service pour un food truck
   * @param {string} foodTruckId - ID du food truck
   * @returns {StatsService} Instance du service
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }

    if (!this.instances.has(foodTruckId)) {
      this.instances.set(foodTruckId, new StatsService(foodTruckId));
    }

    return this.instances.get(foodTruckId);
  }
};