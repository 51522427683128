import React, { useMemo, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
    Chip,
    Menu,
    Drawer,
} from '@mui/material';
import {
    FaPlus,
    FaEllipsisV,
    FaEdit,
    FaTrash,
    FaSearch,
    FaPepperHot,
} from 'react-icons/fa';
import { useFirebase } from '../Auth/FirebaseProvider';
import Loader from '../Common/Loader';
import { ingredientsService } from '../../services/ingredientService';
import IngredientForm, { INGREDIENT_TYPES } from './IngredientForm';

// Composant principal de gestion des ingrédients
function IngredientEditor() {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ingredientService, setIngredientService] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [availabilityFilter, setAvailabilityFilter] = useState('all');
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

    useEffect(() => {
        if (!foodTruckId || contextLoading) return;

        let unsubscribe;

        try {
            // Initialiser le service des ingrédients (à implémenter)
            const service = ingredientsService.getInstance(foodTruckId);
            setIngredientService(service);

            unsubscribe = service.listenIngredients(
                (ingredientsData) => {
                    setIngredients(ingredientsData);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching ingredients:", error);
                    setError('Erreur lors du chargement des ingrédients. Veuillez réessayer.');
                    setLoading(false);
                }
            );
        } catch (error) {
            console.error("Error initializing ingredients service:", error);
            setError("Erreur lors de l'initialisation du service. Veuillez réessayer.");
            setLoading(false);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [foodTruckId, contextLoading]);

    const handleDelete = async (ingredientId) => {
        if (!ingredientService || !window.confirm('Êtes-vous sûr de vouloir supprimer cet ingrédient ?')) {
            return;
        }

        try {
            await ingredientService.deleteIngredient(ingredientId);
            setSnackbar({
                open: true,
                message: 'Ingrédient supprimé avec succès',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error deleting ingredient:', error);
            setSnackbar({
                open: true,
                message: 'Erreur lors de la suppression. Veuillez réessayer.',
                severity: 'error'
            });
        }
        handleCloseMenu();
    };

    const handleSave = async (ingredientData) => {
        if (!ingredientService) return;

        try {
            if (selectedIngredient) {
                await ingredientService.updateIngredient({
                    ...ingredientData,
                    id: selectedIngredient.id
                });
                setSnackbar({
                    open: true,
                    message: 'Ingrédient mis à jour avec succès',
                    severity: 'success'
                });
            } else {
                await ingredientService.addIngredient(ingredientData);
                setSnackbar({
                    open: true,
                    message: 'Nouvel ingrédient ajouté avec succès',
                    severity: 'success'
                });
            }
            handleCloseSidePanel();
        } catch (error) {
            console.error('Error saving ingredient:', error);
            setSnackbar({
                open: true,
                message: 'Erreur lors de la sauvegarde. Veuillez réessayer.',
                severity: 'error'
            });
        }
    };

    const filteredAndSortedIngredients = useMemo(() => {
        return [...ingredients]
            .filter(ingredient =>
                (availabilityFilter === 'all' ||
                    (availabilityFilter === 'available' && !ingredient.isOutOfStock) ||
                    (availabilityFilter === 'unavailable' && ingredient.isOutOfStock)) &&
                ingredient.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => {
                const compareA = a[orderBy];
                const compareB = b[orderBy];

                if (compareA < compareB) return order === 'asc' ? -1 : 1;
                if (compareA > compareB) return order === 'asc' ? 1 : -1;
                return 0;
            });
    }, [ingredients, availabilityFilter, searchTerm, orderBy, order]);

    const renderSupplementInfo = (ingredient) => {
        if (ingredient.type === INGREDIENT_TYPES.BASE || !ingredient.price) {
            return <Typography variant="body2" color="text.secondary">Non</Typography>;
        }

        return (
            <Box>
                <Chip
                    label={`${ingredient.price.toFixed(2)} €`}
                    color="primary"
                    size="small"
                    sx={{ mb: 0.5 }}
                />
                <Typography variant="caption" display="block" color="text.secondary">
                    {ingredient.minQuantity} - {ingredient.maxQuantity} max
                </Typography>
            </Box>
        );
    };

    // Handlers pour l'UI
    const handleOpenSidePanel = (ingredient = null) => {
        setSelectedIngredient(ingredient);
        setIsSidePanelOpen(true);
        handleCloseMenu();
    };

    const handleCloseSidePanel = () => {
        setSelectedIngredient(null);
        setIsSidePanelOpen(false);
    };

    const handleOpenMenu = (event, ingredient) => {
        setAnchorEl(event.currentTarget);
        setSelectedIngredient(ingredient);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (contextLoading || loading) return <Loader />;
    if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FaPepperHot className="mr-3 text-emerald-500" />
                    Les ingrédients
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FaPlus />}
                    onClick={() => handleOpenSidePanel()}
                >
                    Ajouter un ingrédient
                </Button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="availability-filter-label">Disponibilité</InputLabel>
                    <Select
                        labelId="availability-filter-label"
                        value={availabilityFilter}
                        onChange={(e) => setAvailabilityFilter(e.target.value)}
                        label="Disponibilité"
                    >
                        <MenuItem value="all">Tous</MenuItem>
                        <MenuItem value="available">Disponible</MenuItem>
                        <MenuItem value="unavailable">Indisponible</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <FaSearch style={{ marginRight: '0.5rem' }} />,
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Nom
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'type'}
                                    direction={orderBy === 'type' ? order : 'asc'}
                                    onClick={() => handleRequestSort('type')}
                                >
                                    Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'price'}
                                    direction={orderBy === 'price' ? order : 'asc'}
                                    onClick={() => handleRequestSort('price')}
                                >
                                    Supplément
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'isOutOfStock'}
                                    direction={orderBy === 'isOutOfStock' ? order : 'asc'}
                                    onClick={() => handleRequestSort('isOutOfStock')}
                                >
                                    Disponible
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedIngredients.map((ingredient) => (
                            <TableRow key={ingredient.id}>
                                <TableCell>{ingredient.name}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={ingredient.type === INGREDIENT_TYPES.BASE ? 'Base' : 'Standard'}
                                        color={ingredient.type === INGREDIENT_TYPES.BASE ? 'primary' : 'default'}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>
                                    {renderSupplementInfo(ingredient)}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={ingredient.isOutOfStock ? 'Indisponible' : 'Disponible'}
                                        color={ingredient.isOutOfStock ? 'error' : 'success'}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={(e) => handleOpenMenu(e, ingredient)}>
                                        <FaEllipsisV />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => handleOpenSidePanel(selectedIngredient)}>
                    <FaEdit style={{ marginRight: '0.5rem' }} /> Modifier
                </MenuItem>
                <MenuItem onClick={() => handleDelete(selectedIngredient.id)}>
                    <FaTrash style={{ marginRight: '0.5rem' }} /> Supprimer
                </MenuItem>
            </Menu>

            <Drawer
                anchor="right"
                open={isSidePanelOpen}
                onClose={handleCloseSidePanel}
            >
                <Box sx={{ width: 800 }}>
                    <IngredientForm
                        ingredient={selectedIngredient}
                        onSave={handleSave}
                        onClose={handleCloseSidePanel}
                    />
                </Box>
            </Drawer>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default IngredientEditor;