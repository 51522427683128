import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Alert,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '@mui/material';
import {
    FaTimes,
    FaSave,
} from 'react-icons/fa';
import SectionTitle from '../Common/Title';
import SizeTable from '../Common/SizeTable';

function MenuItemCategoryEditPanel({ category, onSave, onClose }) {
    const [formData, setFormData] = useState({
        id: category?.id || '',
        name: category?.name || '',
        complexityPoints: category?.complexityPoints || 0,
        isHidden: category?.isHidden || false,
        order: category?.order || 0,
        sizes: category?.sizes || []
    });
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) || 0 : value,
        }));
    };

    const handleSizeChange = (index, field, value) => {
        setFormData(prevData => ({
            ...prevData,
            sizes: prevData.sizes.map((size, i) =>
                i === index ? { ...size, [field]: value } : size
            ),
        }));
    };

    const handleAddSize = (newSize) => {
        setFormData(prevData => ({
            ...prevData,
            sizes: [...prevData.sizes, newSize],
        }));
    };

    const handleDeleteSize = (index) => {
        setFormData(prevData => ({
            ...prevData,
            sizes: prevData.sizes.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            await onSave(formData);
        } catch (error) {
            console.error("Error saving category:", error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            position: 'relative',
            bgcolor: 'background.default',
        }}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 3,
                pb: 10
            }}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" fontWeight="bold" color="primary.main">
                            {category?.id ? 'Modifier la catégorie' : 'Nouvelle catégorie'}
                        </Typography>
                        <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                            <FaTimes />
                        </IconButton>
                    </Box>

                    {category?.id && (
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                                ID : <span style={{ userSelect: 'all', fontFamily: 'monospace' }}>{category.id}</span>
                            </Typography>
                        </Box>
                    )}

                    <SectionTitle>Informations générales</SectionTitle>
                    <TextField
                        fullWidth
                        label="Nom de la catégorie"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                        variant="outlined"
                    />

                    <SectionTitle>Points de complexité</SectionTitle>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Les points de complexité influencent le nombre de commandes possibles par créneau horaire.
                    </Alert>
                    <TextField
                        fullWidth
                        label="Points de complexité"
                        name="complexityPoints"
                        type="number"
                        value={formData.complexityPoints}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">points</InputAdornment>,
                            inputProps: { min: 0, max: 100 }
                        }}
                        helperText="Définit la complexité de préparation des produits de cette catégorie (0-100 points)"
                    />

                    <SectionTitle>Visibilité</SectionTitle>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!formData.isHidden}
                                onChange={(e) => handleChange({
                                    target: {
                                        name: 'isHidden',
                                        type: 'checkbox',
                                        checked: !e.target.checked
                                    }
                                })}
                            />
                        }
                        label="Visible sur l'application"
                    />

                    <SectionTitle>Tailles disponibles</SectionTitle>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Configurez les différentes tailles disponibles pour les produits de cette catégorie
                    </Typography>
                    <SizeTable
                        sizes={formData.sizes}
                        onChange={handleSizeChange}
                        onAdd={handleAddSize}
                        onDelete={handleDeleteSize}
                    />
                </Box>
            </Box>

            <Box sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                zIndex: 1
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isSaving}
                    startIcon={<FaTimes />}
                    sx={{
                        minWidth: '120px',
                        color: 'text.secondary',
                        borderColor: 'text.secondary',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    Annuler
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isSaving && (
                        <CircularProgress size={24} sx={{ mr: 2 }} />
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isSaving}
                        startIcon={<FaSave />}
                        sx={{
                            minWidth: '120px',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            }
                        }}
                    >
                        {isSaving ? 'Enregistrement...' : 'Enregistrer'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default MenuItemCategoryEditPanel;