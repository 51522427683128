import React, { useState, useEffect } from 'react';
import { ingredientsService } from '../../services/ingredientService';
import { categoriesService } from '../../services/categoriesService';
import { menuService } from '../../services/menuService';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Snackbar,
  Alert,
  Chip,
  IconButton,
  Menu,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Drawer,
  Tooltip,
} from '@mui/material';
import {
  FaPlus,
  FaEllipsisV,
  FaEdit,
  FaTrash,
  FaSearch,
  FaPizzaSlice,
} from 'react-icons/fa';
import MenuItemForm from './MenuItemForm';
import Loader from '../Common/Loader';
import { useFirebase } from '../Auth/FirebaseProvider';
import { getDisplayPrice } from '../Common/Price';


function MenuEditor() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [foodCategories, setFoodCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [orderBy, setOrderBy] = useState('category');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visibilityFilter, setVisibilityFilter] = useState('all');
  const [availabilityFilter, setAvailabilityFilter] = useState('all');
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    let menuUnsubscribe;
    let supplementUnsubscribe;

    const initializeServices = () => {
      try {
        const menuSrv = menuService.getInstance(foodTruckId);
        const catService = categoriesService.getInstance(foodTruckId);
        const ingredientSrv = ingredientsService.getInstance(foodTruckId);
        setServices({ menuSrv, catService, ingredientSrv });
        return { menuSrv, catService, ingredientSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadInitialData = async () => {
      try {
        setLoading(true);
        const { menuSrv, catService, ingredientSrv } = initializeServices();

        // Configurer l'écouteur du menu
        menuUnsubscribe = menuSrv.listenMenu(
          (menuData) => {
            setMenu(menuData);
            setLoading(false);
          },
          (error) => {
            console.error("Error listening to menu:", error);
            setError('Erreur lors du chargement du menu. Veuillez réessayer.');
            setLoading(false);
          }
        );

        // Configurer l'écouteur des ingrédients
        supplementUnsubscribe = ingredientSrv.listenIngredients(
          (ingredients) => {
            setIngredients(ingredients);
            // Filtrer pour n'avoir que les ingrédients qui peuvent être des suppléments
            const supplements = ingredients.filter(ingredient =>
              ingredient.type === 'standard' && // Type standard
              ingredient.canBeExtra 
            ).map(ingredient => ({
              id: ingredient.id,
              name: ingredient.name,
              price: ingredient.price,
              canBeExtra: ingredient.canBeExtra ?? false,
              discountPrice: ingredient.discountPrice,
              minQuantity: ingredient.minQuantity || 0,
              maxQuantity: ingredient.maxQuantity || 1,
              isOutOfStock: ingredient.isOutOfStock,
              allergens: ingredient.allergens || []
            }));

            setAvailableSupplements(supplements);
          },
          (error) => {
            console.error("Error listening to supplements:", error);
            setError(error.message);
          }
        );

        // Charger les catégories
        const categories = await catService.getCategories();
        setFoodCategories(categories);

      } catch (error) {
        console.error("Error loading initial data:", error);
        setError('Erreur lors du chargement des données. Veuillez réessayer.');
        setLoading(false);
      }
    };

    loadInitialData();

    return () => {
      if (menuUnsubscribe) menuUnsubscribe();
      if (supplementUnsubscribe) supplementUnsubscribe();
    };
  }, [foodTruckId, contextLoading]);

  const handleSave = async (itemData) => {
    if (!services?.menuSrv) return;

    try {
      if (selectedItem) {
        // Mise à jour d'un item existant
        await services.menuSrv.updateMenu([{ ...selectedItem, ...itemData }]);
        setSnackbar({ open: true, message: 'Article mis à jour avec succès', severity: 'success' });
      } else {
        // Ajout d'un nouvel item
        await services.menuSrv.addMenuItem(itemData);
        setSnackbar({ open: true, message: 'Nouvel article ajouté avec succès', severity: 'success' });
      }
      handleCloseSidePanel();
    } catch (error) {
      console.error('Error saving item:', error);
      setSnackbar({ open: true, message: 'Erreur lors de la sauvegarde. Veuillez réessayer.', severity: 'error' });
    }
  };

  const handleDelete = async (itemId) => {
    if (!services?.menuSrv || !window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      return;
    }

    try {
      await services.menuSrv.deleteMenuItem(itemId);
      setSnackbar({
        open: true,
        message: 'Article supprimé avec succès',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting item:', error);
      setSnackbar({
        open: true,
        message: 'Erreur lors de la suppression. Veuillez réessayer.',
        severity: 'error'
      });
    }
    handleCloseMenu();
  };

  const reloadCategories = async () => {
    if (!services?.catService) return;

    try {
      const categories = await services.catService.getCategories();
      setFoodCategories(categories);
    } catch (error) {
      console.error("Error reloading categories:", error);
      setSnackbar({
        open: true,
        message: 'Erreur lors du rechargement des catégories',
        severity: 'error'
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuItem(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleOpenSidePanel = (item = null) => {
    setSelectedItem(item);
    setIsSidePanelOpen(true);
    handleCloseMenu();
  };

  const handleCloseSidePanel = () => {
    setSelectedItem(null);
    setIsSidePanelOpen(false);
  };

  const getIngredientsTooltip = (ingredientsIds) => {
    if (!ingredientsIds || ingredientsIds.length === 0) {
      return "Aucun ingrédient";
    }

    const supplementsWithDetails = ingredientsIds
      .map(supp => ingredients.find(s => s.id === supp.id))
      .filter(Boolean);

    return (
      <div style={{ padding: '8px' }}>
        <table>
          <tbody>
            {supplementsWithDetails.map((supplement) => (
              <tr key={supplement.id}>
                <td style={{ paddingRight: '16px' }}>{supplement.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const getSupplementsTooltip = (supplementIds) => {
    if (!supplementIds || supplementIds.length === 0) {
      return "Aucun supplément disponible";
    }

    const supplementsWithDetails = supplementIds
      .map(supp => availableSupplements.find(s => s.id === supp.id))
      .filter(Boolean);

    return (
      <div style={{ padding: '8px' }}>
        <table>
          <tbody>
            {supplementsWithDetails.map((supplement) => (
              <tr key={supplement.id}>
                <td style={{ paddingRight: '16px' }}>{supplement.name}</td>
                <td style={{ textAlign: 'right' }}>{supplement.price.toFixed(2)} €</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const filteredAndSortedMenu = [...menu]
    .filter(item =>
      (selectedCategories.length === 0 || (item.category && selectedCategories.includes(item.categoryId))) &&
      (visibilityFilter === 'all' ||
        (visibilityFilter === 'visible' && !item.isHidden) ||
        (visibilityFilter === 'hidden' && item.isHidden)) &&
      (availabilityFilter === 'all' ||
        (availabilityFilter === 'available' && !item.isOutOfStock) ||
        (availabilityFilter === 'unavailable' && item.isOutOfStock)) &&
      (item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.category && item.category.name.toLowerCase().includes(searchTerm.toLowerCase())))
    )
    .sort((a, b) => {
      let compareA = a[orderBy];
      let compareB = b[orderBy];

      if (orderBy === 'category') {
        compareA = a.category ? a.category.name : '';
        compareB = b.category ? b.category.name : '';
      } else if (orderBy === 'price') {
        const priceA = a.prices && a.prices.length > 0 ? a.prices[0].priceTTC : 0;
        const priceB = b.prices && b.prices.length > 0 ? b.prices[0].priceTTC : 0;
        return order === 'asc' ? priceA - priceB : priceB - priceA;
      }

      if (compareA < compareB) {
        return order === 'asc' ? -1 : 1;
      }
      if (compareA > compareB) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

  if (contextLoading || loading) return <Loader />;
  if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaPizzaSlice className="mr-3 text-emerald-500" />
          Les produits
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={() => handleOpenSidePanel()}
        >
          Ajouter un article
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="category-filter-label">Catégories</InputLabel>
          <Select
            labelId="category-filter-label"
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            input={<OutlinedInput label="Catégories" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={foodCategories.find(cat => cat.id === value)?.name} />
                ))}
              </Box>
            )}
          >
            {foodCategories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                <Checkbox checked={selectedCategories.indexOf(category.id) > -1} />
                <ListItemText primary={category.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="visibility-filter-label">Visibilité</InputLabel>
          <Select
            labelId="visibility-filter-label"
            value={visibilityFilter}
            onChange={(e) => setVisibilityFilter(e.target.value)}
            label="Visibilité"
          >
            <MenuItem value="all">Tous</MenuItem>
            <MenuItem value="visible">Visible</MenuItem>
            <MenuItem value="hidden">Caché</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="availability-filter-label">Disponibilité</InputLabel>
          <Select
            labelId="availability-filter-label"
            value={availabilityFilter}
            onChange={(e) => setAvailabilityFilter(e.target.value)}
            label="Disponibilité"
          >
            <MenuItem value="all">Tous</MenuItem>
            <MenuItem value="available">Disponible</MenuItem>
            <MenuItem value="unavailable">Indisponible</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          size="small"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <FaSearch style={{ marginRight: '0.5rem' }} />,
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Nom
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'category'}
                  direction={orderBy === 'category' ? order : 'asc'}
                  onClick={() => handleRequestSort('category')}
                >
                  Catégorie
                </TableSortLabel>
              </TableCell>
              <TableCell>Visible</TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'price'}
                  direction={orderBy === 'price' ? order : 'asc'}
                  onClick={() => handleRequestSort('price')}
                >
                  Prix
                </TableSortLabel>
              </TableCell>
              <TableCell>Ingrédients</TableCell>
              <TableCell>Suppléments</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedMenu.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <Chip
                    label={item.categoryId ?
                      (foodCategories.find(cat => cat.id === item.categoryId)?.name || 'Catégorie inconnue') :
                      'Non catégorisé'
                    }
                    color="primary"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={item.isHidden ? 'Caché' : 'Visible'}
                    color={item.isHidden ? 'default' : 'success'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={item.isOutOfStock ? 'Indisponible' : 'Disponible'}
                    color={item.isOutOfStock ? 'error' : 'success'}
                    size="small"
                  />
                </TableCell>
                <TableCell>{getDisplayPrice(item)}</TableCell>
                <TableCell>
                  <Tooltip
                    title={getIngredientsTooltip(item.ingredients)}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'background.paper',
                          color: 'text.primary',
                          '& .MuiTooltip-arrow': {
                            color: 'background.paper',
                          },
                          boxShadow: 1,
                          borderRadius: 1,
                          maxWidth: 'none'
                        },
                      },
                    }}
                  >
                    <Chip
                      label={`${item.ingredients?.length || 0} ingrédient${item.ingredients?.length > 1 ? 's' : ''}`}
                      size="small"
                      color={item.ingredients?.length > 0 ? "info" : "default"}
                      variant="outlined"
                    />
                  </Tooltip>

                </TableCell>
                <TableCell>
                  <Tooltip
                    title={getSupplementsTooltip(item.supplements)}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'background.paper',
                          color: 'text.primary',
                          '& .MuiTooltip-arrow': {
                            color: 'background.paper',
                          },
                          boxShadow: 1,
                          borderRadius: 1,
                          maxWidth: 'none'
                        },
                      },
                    }}
                  >
                    <Chip
                      label={`${item.supplements?.length || 0} supplément${item.supplements?.length > 1 ? 's' : ''}`}
                      size="small"
                      color={item.supplements?.length > 0 ? "info" : "default"}
                      variant="outlined"
                    />
                  </Tooltip>

                </TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleOpenMenu(e, item)}>
                    <FaEllipsisV />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Action menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleOpenSidePanel(menuItem)}>
          <FaEdit style={{ marginRight: '0.5rem' }} /> Modifier
        </MenuItem>
        <MenuItem onClick={() => handleDelete(menuItem.id)}>
          <FaTrash style={{ marginRight: '0.5rem' }} /> Supprimer
        </MenuItem>
      </Menu>

      {/* Side panel for adding/editing */}
      <Drawer
        anchor="right"
        open={isSidePanelOpen}
        onClose={handleCloseSidePanel}
      >
        <Box sx={{ width: 800 }}>
          <MenuItemForm
            item={selectedItem}
            foodCategories={foodCategories}
            supplements={availableSupplements}
            ingredients={ingredients}
            onSave={handleSave}
            onClose={handleCloseSidePanel}
            onCategoriesUpdated={reloadCategories}
          />
        </Box>
      </Drawer>



      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MenuEditor;