import { db } from './firebase';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';

export class FoodTruckService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.foodTruckPath = `foodTrucks/${foodTruckId}`;
    this.loyaltyPath = `foodTrucks/${foodTruckId}/loyaltyProgram/current`;
    // On utilise le 1er janvier 2024 comme date de référence
    this.referenceDate = new Date(2024, 0, 1);
  }

  /**
   * Récupère les informations du food truck
   * @returns {Promise<Object|null>} Informations du food truck
   */
  async getFoodTruck() {
    try {
      const docRef = doc(db, this.foodTruckPath);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const res = {
          ...data,
          weeklySchedule: this.normalizeWeeklySchedule(data.weeklySchedule)
        };
        return res;
      }
      return null;
    } catch (error) {
      console.error('Error fetching food truck:', error);
      throw error;
    }
  }
  /**
   * Normalise un timestamp en utilisant la date de référence
   * @private
   * @param {Timestamp} timestamp - Timestamp à normaliser
   * @param {boolean} [isEndTime=false] - Indique si c'est une heure de fin
   * @param {Date} [startTime=null] - Date de début pour comparaison
   * @returns {Timestamp} Timestamp normalisé
   */
  normalizeTime(timestamp, isEndTime = false, startTime = null) {
    const date = timestamp.toDate();
    
    // Création de la date normalisée
    const normalizedDate = new Date(
      this.referenceDate.getFullYear(),
      this.referenceDate.getMonth(),
      this.referenceDate.getDate(),
      date.getHours(),
      date.getMinutes(),
      0,
      0
    );

    // Si c'est une heure de fin et qu'on a une heure de début
    if (isEndTime && startTime) {
      // On compare les timestamps normalisés pour voir si l'heure de fin est avant l'heure de début
      if (normalizedDate.getTime() <= startTime.getTime()) {
        // Si oui, on ajoute un jour à la date de fin
        normalizedDate.setDate(normalizedDate.getDate() + 1);
      }
    }

    return Timestamp.fromDate(normalizedDate);
  }

  /**
   * Normalise les horaires du planning hebdomadaire
   * @private
   * @param {Array} weeklySchedule - Planning hebdomadaire à normaliser
   * @returns {Array} Planning hebdomadaire avec horaires normalisés
   */
  normalizeWeeklySchedule(weeklySchedule) {
    if (!weeklySchedule) return [];

    return weeklySchedule.map(dailySchedule => ({
      ...dailySchedule,
      locations: dailySchedule.locations.map(location => {
        // D'abord normaliser l'heure de début
        const normalizedStartTime = this.normalizeTime(location.startTime);
        // Puis l'heure de fin en passant l'heure de début normalisée
        const normalizedEndTime = this.normalizeTime(
          location.endTime, 
          true, 
          normalizedStartTime.toDate()
        );

        return {
          ...location,
          startTime: normalizedStartTime,
          endTime: normalizedEndTime
        };
      })
    }));
  }

  /**
   * Récupère le programme de fidélité actuel
   * @returns {Promise<Object|null>} Programme de fidélité
   */
  async getLoyaltyProgram() {
    try {
      const docRef = doc(db, this.loyaltyPath);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching loyalty program:', error);
      throw error;
    }
  }

  /**
   * Met à jour les informations du food truck
   * @param {Object} info - Nouvelles informations
   */
  async updateFoodTruck(info) {
    try {
      const docRef = doc(db, this.foodTruckPath);
      await setDoc(docRef, this.sanitizeFoodTruckData(info), { merge: true });
    } catch (error) {
      console.error('Error updating food truck:', error);
      throw error;
    }
  }

  /**
   * Met à jour le programme de fidélité
   * @param {Object} info - Nouvelles informations de fidélité
   */
  async updateLoyaltyProgram(info) {
    try {
      const docRef = doc(db, this.loyaltyPath);
      await setDoc(docRef, this.sanitizeLoyaltyData(info), { merge: true });
    } catch (error) {
      console.error('Error updating loyalty program:', error);
      throw error;
    }
  }

  /**
   * Nettoie les données du food truck avant sauvegarde
   * @private
   * @param {Object} data - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
  sanitizeFoodTruckData(data) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  /**
   * Nettoie les données de fidélité avant sauvegarde
   * @private
   * @param {Object} data - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
  sanitizeLoyaltyData(data) {
    if (!data.levels) return data;

    return {
      ...data,
      levels: data.levels.map(level => ({
        ...level,
        price: Number(level.price) || 0, // Convert to number, default to 0 if invalid
        benefits: level.benefits.map(benefit => ({
          ...benefit,
          ...this.sanitizeBenefitSpecificData(benefit)
        }))
      }))
    };
  }

  /**
   * Nettoie les données spécifiques à chaque type d'avantage
   * @private
   * @param {Object} benefit - Données de l'avantage à nettoyer
   * @returns {Object} Données nettoyées
   */
  sanitizeBenefitSpecificData(benefit) {
    const base = {
      description: benefit.description || '',
      availableDays: benefit.availableDays || [],
      startTime: benefit.startTime || null,
      endTime: benefit.endTime || null
    };

    switch (benefit.type) {
      case 'fidelityPoint':
        return {
          ...base,
          categoryPoints: benefit.categoryPoints?.filter(cp => cp.categoryId && cp.points) || [],
          categoryRewards: benefit.categoryRewards?.filter(cr => cr.categoryId && cr.pointsCost) || []
        };
      case 'freeItem':
        return {
          ...base,
          freeItems: benefit.freeItems?.filter(Boolean) || [],
          minOrderAmount: Number(benefit.minOrderAmount) || 0
        };
      case 'percentageDiscount':
        return {
          ...base,
          discountPercentage: Number(benefit.discountPercentage) || 0,
          minOrderAmount: Number(benefit.minOrderAmount) || 0
        };
      case 'fixedDiscount':
        return {
          ...base,
          discountAmount: Number(benefit.discountAmount) || 0,
          minOrderAmount: Number(benefit.minOrderAmount) || 0
        };
      default:
        return base;
    }
  }
}

// Export singleton instance factory
export const foodTruckService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {FoodTruckService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new FoodTruckService(foodTruckId);
  }
};