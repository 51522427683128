import { db } from './firebase';
import { 
  getDoc, 
  doc, 
  collection, 
  onSnapshot, 
  query, 
  where, 
  setDoc, 
  getDocs, 
  limit, 
  orderBy 
} from 'firebase/firestore';

export class ClientService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.collectionPath = `foodTrucks/${foodTruckId}/users`;
  }

  /**
   * Écoute les changements des clients en temps réel
   * @param {Function} onUpdate - Callback appelé lors des mises à jour
   * @param {Function} onError - Callback appelé en cas d'erreur
   * @returns {Function} Fonction pour arrêter l'écoute
   */
  listenClients(onUpdate, onError) {
    const q = query(
      collection(db, this.collectionPath),
      where('isAnonymous', '==', false),
      where('isAdmin', '==', false)
    );

    return onSnapshot(q,
      (snapshot) => {
        const userData = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(item => !item.isDeleted);
        onUpdate(userData);
      },
      onError
    );
  }

  /**
   * Recherche des clients par terme
   * @param {string} searchTerm - Terme de recherche
   * @returns {Promise<Array>} Liste des clients correspondants
   */
  async searchClients(searchTerm) {
    const customersRef = collection(db, this.collectionPath);
    const searchTermLower = searchTerm.toLowerCase();

    const queries = [
      // Email
      query(
        customersRef,
        where('email', '>=', searchTermLower),
        where('email', '<=', searchTermLower + '\uf8ff'),
        orderBy('email'),
        limit(10)
      ),
      // Prénom
      query(
        customersRef,
        where('firstName', '>=', searchTermLower),
        where('firstName', '<=', searchTermLower + '\uf8ff'),
        orderBy('firstName'),
        limit(10)
      ),
      // Nom
      query(
        customersRef,
        where('lastName', '>=', searchTermLower),
        where('lastName', '<=', searchTermLower + '\uf8ff'),
        orderBy('lastName'),
        limit(10)
      )
    ];

    try {
      const snapshots = await Promise.all(queries.map(q => getDocs(q)));
      const results = new Map();

      // Fusionner les résultats
      snapshots.forEach(snapshot => {
        snapshot.docs.forEach(doc => {
          results.set(doc.id, { id: doc.id, ...doc.data() });
        });
      });

      // Filtrer et formater les résultats
      return Array.from(results.values())
        .filter(customer =>
          customer.firstName?.toLowerCase().includes(searchTermLower) ||
          customer.lastName?.toLowerCase().includes(searchTermLower) ||
          customer.email?.toLowerCase().includes(searchTermLower)
        )
        .map(this.formatClientData);
    } catch (error) {
      console.error('Error searching clients:', error);
      throw error;
    }
  }

  /**
   * Met à jour un client
   * @param {Object} user - Données du client à mettre à jour
   */
  async updateClient(user) {
    try {
      const docRef = doc(db, this.collectionPath, user.id);
      await setDoc(docRef, user, { merge: true });
    } catch (error) {
      console.error('Error updating client:', error);
      throw error;
    }
  }

  /**
   * Récupère un client par son ID
   * @param {string} userId - ID du client
   * @returns {Promise<Object>} Données du client
   */
  async getClientById(userId) {
    try {
      if (!userId) {
        throw new Error('ID utilisateur non défini');
      }

      const userDoc = await getDoc(doc(db, this.collectionPath, userId));

      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const userData = userDoc.data();
      if (userData.isDeleted) {
        throw new Error('Utilisateur supprimé');
      }

      return this.formatClientData({
        id: userDoc.id,
        ...userData,
      });
    } catch (error) {
      console.error('Error fetching client:', error);
      throw error;
    }
  }

  /**
   * Formate les données d'un client
   * @private
   * @param {Object} clientData - Données brutes du client
   * @returns {Object} Données formatées
   */
  formatClientData(clientData) {
    return {
      id: clientData.id,
      firstName: clientData.firstName || '',
      lastName: clientData.lastName || '',
      email: clientData.email || '',
      loyaltyPoints: clientData.loyaltyPoints || 0,
      loyalty: clientData.loyalty ? {
        ...clientData.loyalty,
        name: clientData.loyalty.name,
        price: clientData.loyalty.price,
        startDate: clientData.loyalty.startDate?.toDate(),
        expirationDate: clientData.loyalty.expirationDate?.toDate()
      } : null,
      isAnonymous: clientData.isAnonymous || false,
      isAdmin: clientData.isAdmin || false,
      lastReadNotificationId: clientData.lastReadNotificationId || null,
    };
  }
}

// Export singleton instance factory
export const clientService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {ClientService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new ClientService(foodTruckId);
  }
};