import React, { useState, FormEvent } from 'react';
import { auth } from '../../services/firebase';
import { signInWithEmailAndPassword, User, IdTokenResult, AuthError } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface AdminClaims {
  [truckId: string]: boolean;
}

interface CustomIdTokenResult extends IdTokenResult {
  claims: {
    admin?: AdminClaims;
    [key: string]: any;
  };
}

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const verifyAdminClaims = async (user: User): Promise<boolean> => {
    const idTokenResult = await user.getIdTokenResult() as CustomIdTokenResult;
    const adminClaims = idTokenResult.claims.admin;

    if (adminClaims && typeof adminClaims === 'object') {
      return Object.values(adminClaims).some(value => value === true);
    }
    return false;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Force token refresh to ensure latest claims
      await user.getIdToken(true);
      
      const hasAdminAccess = await verifyAdminClaims(user);
      
      if (hasAdminAccess) {
        navigate('/');
      } else {
        setError("Vous n'avez pas les droits d'administration nécessaires.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Login error:", error);
      let errorMessage: string;

      if (error instanceof Error && 'code' in error) {
        const authError = error as AuthError;
        switch (authError.code) {
          case 'auth/user-not-found':
            errorMessage = 'Aucun utilisateur trouvé avec cet email';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Mot de passe invalide';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Ce compte a été désactivé';
            break;
          case 'auth/too-many-requests':
            errorMessage = 'Trop de tentatives échouées. Veuillez réessayer plus tard.';
            break;
          default:
            errorMessage = 'Échec de la connexion. Veuillez vérifier vos identifiants.';
        }
      } else {
        errorMessage = 'Une erreur inattendue est survenue';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Connexion à l'administration du Food Truck
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Adresse e-mail
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Adresse e-mail"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Mot de passe
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                required
                className="appearance-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Mot de passe"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-indigo-600 hover:text-indigo-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Cacher' : 'Afficher'}
              </button>
            </div>
          </div>

          {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Se souvenir de moi
              </label>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                loading ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Connexion en cours...
                </>
              ) : (
                'Se connecter'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;