import { FirebaseService } from '../services/FirebaseService';

export const allergensService = {
  /**
   * Crée une instance du service des allergènes
   * @param {string} foodTruckId - ID du food truck
   */
  create(foodTruckId) {
    const collectionPath = `foodTrucks/${foodTruckId}/allergens`;
    return new FirebaseService(collectionPath);
  },
  
  /**
   * Récupère tous les allergènes
   * @param {string} foodTruckId - ID du food truck
   */
  async getAllergens(foodTruckId) {
    const service = this.create(foodTruckId);
    return service.getAll({ orderBy: 'name' });
  },
  
};