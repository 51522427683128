import React from 'react';

function Button({ children, variant = 'primary', ...props }) {
  const baseStyle = "px-4 py-2 rounded font-bold";
  const variants = {
    primary: "bg-emerald-600 text-white hover:bg-emerald-700",
    outline: "border border-emerald-600 text-emerald-600 hover:bg-emerald-100",
  };

  return (
    <button 
      className={`${baseStyle} ${variants[variant]}`}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;