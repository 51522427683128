import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Alert,
    Grid2,
} from '@mui/material';
import {
    FaTimes,
    FaSave,
    FaClock,
    FaUsers,
    FaChartPie,
} from 'react-icons/fa';
import SectionTitle from '../Common/Title';

const SettingField = ({ field, label, icon: Icon, tooltip, min, max, unit = "min", value, onChange, error, helperText }) => (
    <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon size={16} />
            {label}
        </Typography>
        <TextField
            fullWidth
            value={value}
            onChange={onChange}
            type="number"
            error={!!error}
            helperText={helperText}
            size="small"
            InputProps={{
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                inputProps: { min, max }
            }}
        />
    </Box>
);

function TruckSlotsEditPanel({ foodTruck, onClose, onUpdate }) {
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState({});

    const validateSettings = (settings) => {
        const newErrors = {};

        if (settings.duration < 5 || settings.duration > 60) {
            newErrors.duration = "La durée doit être entre 5 et 60 minutes";
        }

        if (settings.maxOrdersPerSlot < 1 || settings.maxOrdersPerSlot > 20) {
            newErrors.maxOrdersPerSlot = "Le nombre de commandes doit être entre 1 et 20";
        }

        if (settings.maxPointsPerSlot < 1 || settings.maxPointsPerSlot > 100) {
            newErrors.maxPointsPerSlot = "Le nombre de points doit être entre 1 et 100";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [settings, setSettings] = useState(foodTruck.timeSlotSettings || {
        duration: 15,
        maxOrdersPerSlot: 4,
        maxPointsPerSlot: 20
    });

    const handleChange = (field, value) => {
        setSettings(prev => ({
            ...prev,
            [field]: parseInt(value, 10) || 0
        }));
    };

    const handleSubmit = async () => {
        const isValid = validateSettings(settings);
        if (!isValid) return;

        setIsUpdating(true);
        try {
            await onUpdate({
                ...foodTruck,
                timeSlotSettings: settings
            });
            onClose();
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 3,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    Configuration des créneaux
                </Typography>
                <IconButton onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </Box>

            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                width: '100%',
                p: 3
            }}>
                <Alert severity="info" sx={{ mb: 4 }}>
                    Ces paramètres définissent la façon dont les clients peuvent réserver leurs commandes.
                </Alert>

                <Grid2 >
                    <Grid2>
                        <SectionTitle>Durée des créneaux</SectionTitle>
                        <SettingField
                            field="duration"
                            label="Durée d'un créneau"
                            icon={FaClock}
                            tooltip="Définit la durée d'un créneau de commande"
                            min={5}
                            max={60}
                            unit="minutes"
                            value={settings.duration}
                            onChange={(e) => handleChange('duration', e.target.value)}
                            error={errors.duration}
                            helperText={errors.duration}
                        />

                    </Grid2>

                    <Grid2 item xs={12}>
                        <SectionTitle>Limites par créneau</SectionTitle>
                        <SettingField
                            field="maxOrdersPerSlot"
                            label="Nombre maximum de commandes simultanées"
                            icon={FaUsers}
                            tooltip="Nombre maximum de commandes acceptées par créneau"
                            min={1}
                            max={20}
                            unit="commandes"
                            value={settings.maxOrdersPerSlot}
                            onChange={(e) => handleChange('maxOrdersPerSlot', e.target.value)}
                            error={errors.maxOrdersPerSlot}
                            helperText={errors.maxOrdersPerSlot}
                        />
                        <SettingField
                            field="maxPointsPerSlot"
                            label="Points de complexité maximum"
                            icon={FaChartPie}
                            tooltip="Nombre maximum de points de complexité par créneau"
                            min={1}
                            max={100}
                            unit="points"
                            value={settings.maxPointsPerSlot}
                            onChange={(e) => handleChange('maxPointsPerSlot', e.target.value)}
                            error={errors.maxPointsPerSlot}
                            helperText={errors.maxPointsPerSlot}
                        />
                    </Grid2>
                </Grid2>
            </Box>

            <Box sx={{
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isUpdating}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    startIcon={<FaSave />}
                    onClick={handleSubmit}
                    disabled={isUpdating}
                >
                    {isUpdating ? 'Enregistrement...' : 'Enregistrer'}
                </Button>
            </Box>
        </Box>
    );
}

export default TruckSlotsEditPanel;