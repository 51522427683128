import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import {
    FaTimes,
    FaSave,
    FaPlus,
    FaTrash,
} from 'react-icons/fa';
import SectionTitle from '../Common/Title';

const SOCIAL_PLATFORMS = ["instagram", "facebook", "twitter", "web"];


function TruckInfoEditPanel({ foodTruck, onClose, onUpdate }) {
    const [editedTruck, setEditedTruck] = useState({ ...foodTruck });
    const [isUpdating, setIsUpdating] = useState(false);


    const handleChange = (field, value) => {
        setEditedTruck(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        setIsUpdating(true);
        await onUpdate(editedTruck);
        setIsUpdating(false);
    };



    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 3,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    Modifier le food truck
                </Typography>
                <IconButton onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </Box>

            {/* Content */}
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 3
            }}>
                <SectionTitle>Informations générales</SectionTitle>
                <TextField
                    fullWidth
                    label="Nom"
                    value={editedTruck.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={editedTruck.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    multiline
                    rows={4}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Téléphone"
                    value={editedTruck.phoneNumber}
                    onChange={(e) => handleChange('phoneNumber', e.target.value)}
                    margin="normal"
                />

                <SectionTitle>Réseaux sociaux</SectionTitle>
                {editedTruck.socialMedia.map((social, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <Select
                                value={social.platform}
                                onChange={(e) => {
                                    const newSocialMedia = [...editedTruck.socialMedia];
                                    newSocialMedia[index] = { ...social, platform: e.target.value };
                                    handleChange('socialMedia', newSocialMedia);
                                }}
                                size="small"
                            >
                                {SOCIAL_PLATFORMS.map((platform) => (
                                    <MenuItem key={platform} value={platform}>
                                        {platform}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ flex: 2 }}
                            size="small"
                            placeholder="URL"
                            value={social.url}
                            onChange={(e) => {
                                const newSocialMedia = [...editedTruck.socialMedia];
                                newSocialMedia[index] = { ...social, url: e.target.value };
                                handleChange('socialMedia', newSocialMedia);
                            }}
                        />
                        <IconButton
                            color="error"
                            onClick={() => {
                                const newSocialMedia = editedTruck.socialMedia.filter((_, i) => i !== index);
                                handleChange('socialMedia', newSocialMedia);
                            }}
                        >
                            <FaTrash />
                        </IconButton>
                    </Box>
                ))}
                <Button
                    startIcon={<FaPlus />}
                    onClick={() => {
                        handleChange('socialMedia', [...editedTruck.socialMedia, { platform: '', url: '' }]);
                    }}
                    variant="outlined"
                    size="small"
                >
                    Ajouter un réseau social
                </Button>
            </Box>

            {/* Footer */}
            <Box sx={{
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    startIcon={<FaSave />}
                    onClick={handleSubmit}
                    disabled={isUpdating}
                >
                    Enregistrer
                </Button>
            </Box>
        </Box>
    );
}

export default TruckInfoEditPanel;