import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Alert,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { FaTrash } from 'react-icons/fa';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FaTimes, FaSave, FaEdit } from 'react-icons/fa';
import { formatCurrency, formatDate } from '../../utils/helpers';
import SectionTitle from '../Common/Title';
import { foodTruckService } from '../../services/foodTruckService';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { clientService } from '../../services/clientService';
import { orderService } from '../../services/orderService';
import Loader from '../Common/Loader';

const CustomerEditSidePanel = ({
    customerId,
    foodTruckId,
    onClose,
    onSuccess,
    readOnly = false
}) => {
    // États de base
    const [customer, setCustomer] = useState(null);
    const [customerOrders, setCustomerOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isSavingLoyalty, setIsSavingLoyalty] = useState(false);
    const [services, setServices] = useState(null);

    // États loyalty
    const [loyaltyProgram, setLoyaltyProgram] = useState(null);
    const [isEditingLoyalty, setIsEditingLoyalty] = useState(false);
    const [editedLoyalty, setEditedLoyalty] = useState(null);

    // États UI
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    // Initialisation des services
    useEffect(() => {
        if (!foodTruckId) return;

        try {
            const clientSrv = clientService.getInstance(foodTruckId);
            const orderSrv = orderService.getInstance(foodTruckId);
            const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
            setServices({ clientSrv, orderSrv, foodTruckSrv });
        } catch (err) {
            console.error("Error initializing services:", err);
            setError("Erreur d'initialisation des services");
        }
    }, [foodTruckId]);

    // Chargement des données
    useEffect(() => {
        if (!services || !customerId) return;

        const loadData = async () => {
            setIsLoading(true);
            try {
                // Chargement parallèle des données
                const [customerData, ordersData, programData] = await Promise.all([
                    services.clientSrv.getClientById(customerId),
                    services.orderSrv.getClientOrders(customerId),
                    services.foodTruckSrv.getLoyaltyProgram()
                ]);

                setCustomer(customerData);
                setCustomerOrders(ordersData);
                setLoyaltyProgram(programData);
                setError(null);
            } catch (err) {
                console.error("Error loading customer data:", err);
                setError("Erreur lors du chargement des données");
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [services, customerId]);

    // Mise à jour des données de fidélité lors du chargement du client
    useEffect(() => {
        if (customer?.loyalty) {
            setEditedLoyalty({
                levelId: customer.loyalty.levelId,
                name: customer.loyalty.name,
                price: customer.loyalty.price,
                startDate: convertTimestampToDayjs(customer.loyalty.startDate),
                expirationDate: convertTimestampToDayjs(customer.loyalty.expirationDate)
            });
        }
    }, [customer?.loyalty]);

    const convertTimestampToDayjs = (timestamp) => {
        // Si pas de timestamp, retourner null
        if (!timestamp) return null;

        try {
            // Si c'est un Timestamp Firebase
            if (timestamp?.toDate instanceof Function) {
                return dayjs(timestamp.toDate());
            }

            // Si c'est déjà une Date
            if (timestamp instanceof Date) {
                return dayjs(timestamp);
            }

            // Si c'est déjà un objet dayjs
            if (dayjs.isDayjs(timestamp)) {
                return timestamp;
            }

            // Si c'est un string ou un number (timestamp Unix)
            const date = dayjs(timestamp);
            return date.isValid() ? date : null;

        } catch (error) {
            console.error('Error converting timestamp to dayjs:', error);
            return null;
        }
    };

    const convertDayjsToTimestamp = (date) => {
        // Si pas de date ou date invalide, retourner null
        if (!date || !dayjs.isDayjs(date) || !date.isValid()) {
            return null;
        }

        try {
            // Convertir en Timestamp Firebase
            return Timestamp.fromDate(date.toDate());
        } catch (error) {
            console.error('Error converting dayjs to timestamp:', error);
            return null;
        }
    };

    // Fonction de mise à jour du client
    const updateCustomer = async (updatedData) => {
        if (!services) return;

        try {
            await services.clientSrv.updateClient(updatedData);
            setCustomer(updatedData);
            if (onSuccess) {
                onSuccess(updatedData);
            }
            return true;
        } catch (err) {
            console.error("Error updating customer:", err);
            setError("Erreur lors de la mise à jour du client");
            return false;
        }
    };

    const handleSaveLoyalty = async (e) => {
        e?.preventDefault();

        if (!editedLoyalty?.levelId) {
            setError('Veuillez sélectionner un niveau de fidélité');
            return;
        }

        setIsSavingLoyalty(true);
        setError(null);

        try {
            const updatedLoyalty = {
                levelId: editedLoyalty.levelId,
                name: editedLoyalty.name,
                price: editedLoyalty.price,
                startDate: Timestamp.fromDate(dayjs().startOf('day').toDate()),
                expirationDate: convertDayjsToTimestamp(editedLoyalty.expirationDate)
            };

            const updatedCustomer = {
                ...customer,
                loyalty: updatedLoyalty
            };

            const success = await updateCustomer(updatedCustomer);
            if (success) {
                setIsEditingLoyalty(false);
                setSuccessMessage('Abonnement fidélité mis à jour avec succès');
            }
        } finally {
            setIsSavingLoyalty(false);
        }
    };


    const calculateTotalSpent = (orders) => {
        return orders.reduce((total, order) => total + (order.totalPrice ?? 0), 0);
    };

    const calculateExpirationDate = (levelId) => {
        if (!loyaltyProgram?.levels) return null;

        const level = loyaltyProgram.levels.find(l => l.id === levelId);
        if (!level) return null;

        const startDate = dayjs().startOf('day');

        // Si la durée est 0 ou non définie, on met la date à dans 10 ans
        if (!level.duration || level.duration.value === 0) {
            return dayjs().add(10, 'year').startOf('day');
        }

        return startDate.add(level.duration.value, level.duration.unit);
    };

    const handleLoyaltyChange = (field, value) => {
        setEditedLoyalty(prev => {
            if (field === 'levelId') {
                const selectedLevel = loyaltyProgram?.levels?.find(l => l.id === value);
                if (!selectedLevel) return prev;

                return {
                    ...prev,
                    levelId: value,
                    name: selectedLevel.name,
                    price: selectedLevel.price,
                    startDate: dayjs().startOf('day'),
                    expirationDate: calculateExpirationDate(value)
                };
            }
            return { ...prev, [field]: value };
        });
    };

    const handleSave = async () => {

        setIsSaving(true);
        setError(null);

        try {
            // Préparer l'objet à sauvegarder
            const customerToSave = {
                ...customer,
                loyalty: editedLoyalty ? {
                    levelId: editedLoyalty.levelId,
                    name: editedLoyalty.name,
                    price: editedLoyalty.price,
                    startDate: convertDayjsToTimestamp(editedLoyalty.startDate),
                    expirationDate: convertDayjsToTimestamp(editedLoyalty.expirationDate)
                } : null
            };

            await services.clientSrv.updateClient(customerToSave);
            setCustomer(customerToSave);
            setSuccessMessage('Les modifications ont été enregistrées');
            if (onSuccess) onSuccess(customerToSave);

        } catch (err) {
            console.error('Error saving customer:', err);
            setError('Erreur lors de la sauvegarde');
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteLoyalty = async () => {
        setIsSavingLoyalty(true);
        setError(null);

        try {
            setEditedLoyalty(null);
            const updatedCustomer = {
                ...customer,
                loyalty: null
            };

            await services.clientSrv.updateClient(updatedCustomer);
            setCustomer(updatedCustomer);
            setIsEditingLoyalty(false);
            setSuccessMessage('Abonnement fidélité supprimé avec succès');
            setShowDeleteConfirm(false);
            if (onSuccess) onSuccess(updatedCustomer);

        } catch (err) {
            console.error('Error deleting loyalty:', err);
            setError('Erreur lors de la suppression de l\'abonnement fidélité');
        } finally {
            setIsSavingLoyalty(false);
        }
    };

    const getLevelName = (levelId) => {
        if (!loyaltyProgram?.levels) return 'Non défini';
        const level = loyaltyProgram.levels.find(l => l.id === levelId);
        return level ? level.name : 'Non défini';
    };

    const getDurationText = (levelId) => {
        if (!loyaltyProgram?.levels) return '';
        const level = loyaltyProgram.levels.find(l => l.id === levelId);
        if (!level?.duration) return '';

        const { value, unit } = level.duration;
        const unitTexts = {
            day: value > 1 ? 'jours' : 'jour',
            week: value > 1 ? 'semaines' : 'semaine',
            month: value > 1 ? 'mois' : 'mois',
            year: value > 1 ? 'ans' : 'an'
        };

        return `(${value} ${unitTexts[unit]})`;
    };

    if (isLoading) {
        return (
          <Box sx={{
            width: '500px', 
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Loader />
          </Box>
        );
      }
    
    return (
        <Box sx={{
            width: '500px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            p: 3,
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    {readOnly ? 'Détails client' : 'Modifier client'}
                </Typography>
                <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                    <FaTimes />
                </IconButton>
            </Box>

            {error && (
                <Alert
                    severity="error"
                    sx={{ mb: 2 }}
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            )}

            {customer && customer.id && (
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        ID : <span style={{ userSelect: 'all', fontFamily: 'monospace' }}>{customer.id}</span>
                    </Typography>
                </Box>
            )}

            <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 10 }}>
                {customer && (
                    <>
                        <SectionTitle>Informations personnelles</SectionTitle>
                        <TextField
                            label="Prénom"
                            value={customer.firstName || ''}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Nom"
                            value={customer.lastName || ''}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Email"
                            value={customer.email || ''}
                            fullWidth
                            margin="normal"
                            disabled
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Points de fidélité"
                            type="number"
                            value={customer.loyaltyPoints}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2
                        }}>
                            <SectionTitle>Abonnement Fidélité</SectionTitle>
                            {!readOnly && (
                                <Box>
                                    {customer.loyalty && (
                                        <Button
                                            startIcon={<FaTrash />}
                                            onClick={() => setShowDeleteConfirm(true)}
                                            size="small"
                                            color="error"
                                            sx={{ mr: 1 }}
                                        >
                                            Supprimer
                                        </Button>
                                    )}
                                    <Button
                                        startIcon={isEditingLoyalty ? null : <FaEdit />}
                                        onClick={() => setIsEditingLoyalty(!isEditingLoyalty)}
                                        size="small"
                                        color={isEditingLoyalty ? "error" : "primary"}
                                    >
                                        {isEditingLoyalty ? 'Annuler' : 'Modifier'}
                                    </Button>
                                </Box>
                            )}
                        </Box>

                        {isEditingLoyalty ? (
                            <Box sx={{ mt: 2 }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Niveau de fidélité</InputLabel>
                                    <Select
                                        value={editedLoyalty?.levelId || ''}
                                        onChange={(e) => handleLoyaltyChange('levelId', e.target.value)}
                                        label="Niveau de fidélité"
                                    >
                                        {loyaltyProgram?.levels?.map(level => (
                                            <MenuItem key={level.id} value={level.id}>
                                                {level.name} {getDurationText(level.id)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {editedLoyalty?.levelId && (
                                    <DatePicker
                                        label="Date d'expiration"
                                        value={editedLoyalty?.expirationDate}
                                        onChange={(date) => handleLoyaltyChange('expirationDate', date)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                margin: "normal",
                                                helperText: editedLoyalty?.expirationDate?.isAfter(dayjs().add(9, 'year'))
                                                    ? "Abonnement valable 10 ans"
                                                    : `Durée : ${getDurationText(editedLoyalty.levelId)}`
                                            }
                                        }}
                                        disablePast
                                        minDate={editedLoyalty?.startDate || dayjs()}
                                    />
                                )}

                                <Button
                                    variant="contained"
                                    onClick={handleSaveLoyalty}
                                    disabled={isSavingLoyalty || !editedLoyalty?.levelId}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    startIcon={isSavingLoyalty ? <CircularProgress size={20} /> : <FaSave />}
                                >
                                    {isSavingLoyalty ? 'Sauvegarde...' : 'Sauvegarder l\'abonnement'}
                                </Button>
                            </Box>
                        ) : (customer.loyalty ? (
                            <>
                                <Typography variant="body1">
                                    Niveau: <strong>{getLevelName(customer.loyalty.levelId)}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Prix: <strong>{formatCurrency(customer.loyalty.price)}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Date de début: <strong>{formatDate(customer.loyalty.startDate)}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Date d'expiration: <strong>{formatDate(customer.loyalty.expirationDate)}</strong>
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="body1">
                                Ce client n'a pas d'abonnement fidélité actif.
                            </Typography>
                        )
                        )}

                        <SectionTitle>Statistiques des commandes</SectionTitle>
                        <Typography variant="body1">
                            Nombre de commandes : <strong>{customerOrders.length}</strong>
                        </Typography>
                        <Typography variant="body1">
                            Total dépensé : <strong>{formatCurrency(calculateTotalSpent(customerOrders))}</strong>
                        </Typography>
                    </>
                )}
            </Box>

            {/* Snackbar for success message */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage('')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSuccessMessage('')}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Footer with global save button */}
            {!readOnly && !isEditingLoyalty && (
                <Box sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'background.paper',
                    borderTop: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    p: 2,
                    zIndex: 1
                }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        disabled={isSaving}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={isSaving || readOnly}
                        startIcon={isSaving ? <CircularProgress size={20} /> : <FaSave />}
                    >
                        {isSaving ? 'Enregistrement...' : 'Enregistrer'}
                    </Button>
                </Box>

            )}
            {/* Dialog de confirmation de suppression */}
            <Dialog
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
            >
                <DialogTitle>
                    Confirmer la suppression
                </DialogTitle>
                <DialogContent>
                    Êtes-vous sûr de vouloir supprimer l'abonnement fidélité de ce client ?
                    Cette action est irréversible.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowDeleteConfirm(false)}
                        color="primary"
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleDeleteLoyalty}
                        color="error"
                        variant="contained"
                        disabled={isSavingLoyalty}
                        startIcon={isSavingLoyalty ? <CircularProgress size={20} /> : <FaTrash />}
                    >
                        {isSavingLoyalty ? 'Suppression...' : 'Supprimer'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>

    );
};

export default CustomerEditSidePanel;