import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import Button from '../Common/Button';
import { Menu, LogOut, User, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

function Header() {
  const { currentUser, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      setIsMenuOpen(false);
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <header className="bg-emerald-700 text-white p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold hover:text-emerald-100 transition duration-300">
          Garnii
        </Link>

        {currentUser && (
          <div className="flex items-center space-x-4">
            <div className="hidden md:flex items-center space-x-4">
              <div className="w-10 h-10 bg-emerald-100 text-emerald-700 rounded-full flex justify-center items-center font-bold">
                {currentUser.email.charAt(0).toUpperCase()}
              </div>
              <span className="text-sm lg:text-base">
                {currentUser.email}
              </span>
              <Button 
                onClick={handleLogout} 
                variant="outline" 
                className="flex items-center bg-transparent hover:bg-emerald-600 text-white border-white"
              >
                <LogOut size={18} className="mr-2" />
                Déconnexion
              </Button>
            </div>

            <div className="md:hidden relative">
              <Button onClick={() => setIsMenuOpen(!isMenuOpen)} variant="outline">
                <Menu size={24} />
              </Button>
              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <div className="px-4 py-2 text-sm text-gray-700">
                    {currentUser.email}
                  </div>
                  <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-emerald-50" onClick={() => setIsMenuOpen(false)}>
                    <User size={18} className="inline mr-2" />
                    Profil
                  </Link>
                  <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-emerald-50" onClick={() => setIsMenuOpen(false)}>
                    <Settings size={18} className="inline mr-2" />
                    Paramètres
                  </Link>
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-emerald-50">
                    <LogOut size={18} className="inline mr-2" />
                    Déconnexion
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {!currentUser && (
          <Button href="/login" variant="outline" className="bg-transparent hover:bg-emerald-600 text-white border-white">
            Connexion
          </Button>
        )}
      </div>
    </header>
  );
}

export default Header;