import React, { useMemo } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isToday, addWeeks, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Box, IconButton, Typography, Stack, Tooltip, Paper } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const DayCard = ({ date, isSelected, stats, onClick, loading }) => {
  const isCurrentDay = isToday(date);
  const isClosed = !loading && (!stats || stats.slotsCount === 0);

  return (
    <Paper
      onClick={() => onClick(date)}
      elevation={isSelected ? 2 : 0}
      sx={{
        flex: '1 1 0%',
        cursor: 'pointer',
        py: 2,
        px: 3,
        borderRadius: 2,
        bgcolor: isClosed ? 'grey.50' : 'background.paper',
        transition: 'box-shadow 0.2s',
        border: 2,
        borderColor: isSelected ? 'primary.main' : isCurrentDay ? 'primary.light' : 'transparent',
        position: 'relative',
        minWidth: '140px',
        '&:after': isSelected ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          backgroundColor: 'primary.main',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        } : {}
      }}
    >
      <Stack spacing={1} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="baseline">
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: isSelected ? 'primary.main' : 'text.primary'
            }}
          >
            {isCurrentDay ? "Aujourd'hui" : format(date, 'EEE', { locale: fr })}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: isSelected ? 'primary.main' : 'text.primary'
            }}
          >
            {format(date, 'd')}
          </Typography>
        </Stack>

        {loading ? (
          <Typography variant="caption" color="text.secondary">...</Typography>
        ) : isClosed ? (
          <Typography variant="body2" color="error.main" sx={{ fontWeight: 500 }}>
            Fermé
          </Typography>
        ) : (
          <Stack spacing={2} direction="row" alignItems="center" sx={{ mt: 0.5 }}>
            <Tooltip title="Taux d'occupation">
              <Stack alignItems="center">
                <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                  {Math.round(stats.occupationRate)}%
                </Typography>
              </Stack>
            </Tooltip>
            <Tooltip title="Commandes/Créneaux">
              <Stack alignItems="center">
                <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                  {stats.ordersCount}/{stats.slotsCount}
                </Typography>
              </Stack>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

const TimelineWeekNavigator = ({
  selectedDate,
  selectedWeekDate,
  onDateChange,
  weekStats,
  loading,
  onWeekChange
}) => {
  const weekDays = useMemo(() => {
    const start = startOfWeek(selectedWeekDate, { weekStartsOn: 1 });
    const end = endOfWeek(selectedWeekDate, { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  }, [selectedWeekDate]);

  return (
    <Box sx={{ py: 1.5 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ px: 2, mb: 1.5 }}
      >
        <IconButton
          size="small"
          onClick={() => onWeekChange(addWeeks(selectedWeekDate, -1))}
        >
          <FaChevronLeft />
        </IconButton>

        <Stack spacing={0.5}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Semaine du {format(weekDays[0], 'd MMMM', { locale: fr })}
          </Typography>
        </Stack>

        <IconButton
          size="small"
          onClick={() => onWeekChange(addWeeks(selectedWeekDate, 1))}
        >
          <FaChevronRight />
        </IconButton>
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        sx={{
          px: 3,
          overflowX: 'auto',
          pb: 1,
          '&::-webkit-scrollbar': {
            height: 6,
            bgcolor: 'background.paper'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 3,
            bgcolor: 'primary.light'
          }
        }}
      >
        {weekDays.map(date => (
          <DayCard
            key={format(date, 'yyyy-MM-dd')}
            date={date}
            isSelected={isSameDay(date, selectedDate)}
            stats={weekStats[format(date, 'yyyy-MM-dd')]}
            onClick={onDateChange}
            loading={loading}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default TimelineWeekNavigator;