import React from 'react';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Chip,
    IconButton,
} from '@mui/material';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { DAYS_OF_WEEK } from '../../utils/helpers';
import SectionTitle from '../Common/Title';


const BenefitForm = ({ benefit, setBenefit, categories, menuItems }) => {
    console.log("BenefitForm", {
        benefit: benefit,
        categories: categories,
        menuItems: menuItems,
    })
    const handleBaseChange = (e) => {
        const { name, value } = e.target;

        // Liste des champs qui doivent être des nombres
        const numberFields = [
            'minOrderAmount',
            'discountAmount',
            'discountPercentage',
            'points',
            'pointsCost'
        ];

        if (name === 'type') {
            setBenefit(prev => ({
                ...prev,
                [name]: value,
                'inputType': value,
            }));
        } else if (numberFields.includes(name)) {
            // Conversion en nombre et validation
            const numberValue = value === '' ? 0 : Number(value);
            if (!isNaN(numberValue)) {
                setBenefit(prev => ({
                    ...prev,
                    [name]: numberValue
                }));
            }
        } else {
            setBenefit(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleDaysChange = (event) => {
        const value = event.target.value;
        setBenefit(prev => ({
            ...prev,
            availableDays: typeof value === 'string' ? value.split(',').map(Number) : value
        }));
    };

    const handleCategoryPointsChange = (index, field, value) => {
        setBenefit(prev => {
            const newCategoryPoints = [...prev.categoryPoints];
            if (field === 'points') {
                // Conversion en nombre pour les points
                const numberValue = Number(value);
                if (!isNaN(numberValue)) {
                    newCategoryPoints[index] = {
                        ...newCategoryPoints[index],
                        [field]: numberValue
                    };
                }
            } else {
                newCategoryPoints[index] = {
                    ...newCategoryPoints[index],
                    [field]: value
                };
            }
            return { ...prev, categoryPoints: newCategoryPoints };
        });
    };

    const removeCategoryPoint = (index) => {
        setBenefit(prev => ({
            ...prev,
            categoryPoints: prev.categoryPoints.filter((_, i) => i !== index)
        }));
    };

    const addCategoryPoint = () => {
        setBenefit(prev => ({
            ...prev,
            categoryPoints: [...prev.categoryPoints, { categoryId: '', points: 0 }]
        }));
    };

    const handleCategoryRewardsChange = (index, field, value) => {
        setBenefit(prev => {
            const newCategoryRewards = [...prev.categoryRewards];
            if (field === 'pointsCost') {
                const numberValue = Number(value);
                if (!isNaN(numberValue)) {
                    newCategoryRewards[index] = {
                        ...newCategoryRewards[index],
                        [field]: numberValue
                    };
                }
            } else {
                newCategoryRewards[index] = {
                    ...newCategoryRewards[index],
                    [field]: value
                };
            }
            return { ...prev, categoryRewards: newCategoryRewards };
        });
    };

    const removeCategoryReward = (index) => {
        setBenefit(prev => ({
            ...prev,
            categoryRewards: prev.categoryRewards.filter((_, i) => i !== index)
        }));
    };

    const addCategoryReward = () => {
        setBenefit(prev => ({
            ...prev,
            categoryRewards: [...prev.categoryRewards, { categoryId: '', pointsCost: 0 }]
        }));
    };

    return (
        <Box sx={{
            flexGrow: 1,
            overflowY: 'auto',
            pb: 10,
            p: 3, // Ajouté pour matcher LoyaltyLevelEditPanel
        }}>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 10 }}>
                <SectionTitle>Informations générales</SectionTitle>
                {/* Type d'avantage */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Type d'avantage</InputLabel>
                    <Select
                        name="type"
                        value={benefit.type}
                        onChange={handleBaseChange}
                        label="Type d'avantage"
                    >
                        <MenuItem value="fidelityPoint">🎯 Points de fidélité</MenuItem>
                        <MenuItem value="freeItem">🎁 Article gratuit</MenuItem>
                        <MenuItem value="percentageDiscount">💯 Réduction en pourcentage</MenuItem>
                        <MenuItem value="fixedDiscount">💰 Réduction fixe</MenuItem>
                    </Select>
                </FormControl>

                {/* Description */}
                <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={benefit.description}
                    onChange={handleBaseChange}
                    margin="normal"
                    placeholder="Description de l'avantage"
                />

                <SectionTitle>Disponibilité</SectionTitle>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Jours disponibles</InputLabel>
                    <Select
                        multiple
                        value={benefit.availableDays}
                        onChange={handleDaysChange}
                        input={<OutlinedInput label="Jours disponibles" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.length === 0 ? (
                                    <Chip label="Tous les jours" size="small" variant="outlined" />
                                ) : (
                                    selected.map((dayId) => (
                                        <Chip
                                            key={dayId}
                                            label={DAYS_OF_WEEK.find(day => day.id === dayId)?.name}
                                            size="small"
                                            color="primary"
                                        />
                                    ))
                                )}
                            </Box>
                        )}
                    >
                        {DAYS_OF_WEEK.map((day) => (
                            <MenuItem key={day.id} value={day.id}>
                                <Checkbox checked={benefit.availableDays?.indexOf(day.id) > -1} />
                                <ListItemText primary={day.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2 }}>
                    <TextField
                        label="Heure de début"
                        type="time"
                        name="startTime"
                        value={benefit.startTime}
                        onChange={handleBaseChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Heure de fin"
                        type="time"
                        name="endTime"
                        value={benefit.endTime}
                        onChange={handleBaseChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />
                </Box>

                {/* Champs spécifiques selon le type */}
                {benefit.type === 'fidelityPoint' && (
                    <>
                        <SectionTitle>Points par catégorie</SectionTitle>
                        {(benefit.categoryPoints||[]).map((cp, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    mb: 2,
                                    p: 2,
                                    bgcolor: 'action.hover',
                                    borderRadius: 1
                                }}
                            >
                                <FormControl sx={{ flex: 2 }}>
                                    <Select
                                        size="small"
                                        value={cp.categoryId}
                                        onChange={(e) => handleCategoryPointsChange(index, 'categoryId', e.target.value)}
                                    >
                                        {categories.map(cat => (
                                            <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    size="small"
                                    type="number"
                                    sx={{ flex: 1 }}
                                    value={cp.points}
                                    onChange={(e) => handleCategoryPointsChange(index, 'points', Number(e.target.value))}
                                    label="Points"
                                />
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => removeCategoryPoint(index)}
                                >
                                    <FaTrash />
                                </IconButton>
                            </Box>
                        ))}
                        <Chip
                            icon={<FaPlus />}
                            label="Ajouter une catégorie"
                            onClick={addCategoryPoint}
                            color="primary"
                            variant="outlined"
                            sx={{ mt: 1 }}
                        />

                        <SectionTitle>Récompenses par catégorie</SectionTitle>
                        {(benefit.categoryRewards||[]).map((cr, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    mb: 2,
                                    p: 2,
                                    bgcolor: 'action.hover',
                                    borderRadius: 1
                                }}
                            >
                                <FormControl sx={{ flex: 2 }}>
                                    <Select
                                        size="small"
                                        value={cr.categoryId}
                                        onChange={(e) => handleCategoryRewardsChange(index, 'categoryId', e.target.value)}
                                    >
                                        {categories.map(cat => (
                                            <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    size="small"
                                    type="number"
                                    sx={{ flex: 1 }}
                                    value={cr.pointsCost}
                                    onChange={(e) => handleCategoryRewardsChange(index, 'pointsCost', e.target.value)}
                                    label="Points nécessaires"
                                    inputProps={{
                                        min: 0,
                                        step: "1"
                                    }}
                                />
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => removeCategoryReward(index)}
                                >
                                    <FaTrash />
                                </IconButton>
                            </Box>
                        ))}
                        <Chip
                            icon={<FaPlus />}
                            label="Ajouter une récompense"
                            onClick={addCategoryReward}
                            color="primary"
                            variant="outlined"
                            sx={{ mt: 1 }}
                        />

                    </>
                )}

                {benefit.type === 'freeItem' && (
                    <>
                        <SectionTitle>Articles gratuits</SectionTitle>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Sélection des articles</InputLabel>
                            <Select
                                multiple
                                value={benefit.freeItems}
                                onChange={(e) => handleBaseChange({ target: { name: 'freeItems', value: e.target.value } })}
                                input={<OutlinedInput label="Sélection des articles" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            const item = menuItems.find(i => i.id === value);
                                            return (
                                                <Chip
                                                    key={value}
                                                    label={item?.name}
                                                    size="small"
                                                    color="primary"
                                                />
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {menuItems.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <Checkbox checked={benefit.freeItems.indexOf(item.id) > -1} />
                                        <ListItemText primary={item.name} secondary={`${item.price}€`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Montant minimum de commande"
                            name="minOrderAmount"
                            type="number"
                            value={benefit.minOrderAmount}
                            onChange={handleBaseChange}
                            margin="normal"
                            InputProps={{ startAdornment: '€' }}
                        />
                    </>
                )}

                {benefit.type === 'percentageDiscount' && (
                    <>
                        <SectionTitle>Configuration de la réduction</SectionTitle>
                        <TextField
                            fullWidth
                            label="Pourcentage de réduction"
                            name="discountPercentage"
                            type="number"
                            value={benefit.discountPercentage}
                            onChange={handleBaseChange}
                            margin="normal"
                            InputProps={{ endAdornment: '%' }}
                        />
                        <TextField
                            fullWidth
                            label="Montant minimum de commande"
                            name="minOrderAmount"
                            type="number"
                            value={benefit.minOrderAmount}
                            onChange={handleBaseChange}
                            margin="normal"
                            InputProps={{ startAdornment: '€' }}
                        />
                    </>
                )}

                {benefit.type === 'fixedDiscount' && (
                    <>
                        <SectionTitle>Configuration de la réduction</SectionTitle>
                        <TextField
                            fullWidth
                            label="Montant de la réduction"
                            name="discountAmount"
                            type="number"
                            value={benefit.discountAmount}
                            onChange={handleBaseChange}
                            margin="normal"
                            InputProps={{ startAdornment: '€' }}
                        />
                        <TextField
                            fullWidth
                            label="Montant minimum de commande"
                            name="minOrderAmount"
                            type="number"
                            value={benefit.minOrderAmount}
                            onChange={handleBaseChange}
                            margin="normal"
                            InputProps={{ startAdornment: '€' }}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};


export default BenefitForm;