import React, { useState, useEffect } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { foodTruckService } from '../../services/foodTruckService';
import {
  Box,
  Typography,
  Button,
  Drawer,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  FaEdit,
  FaClock,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import TruckHoursEditPanel from './TruckHoursEditPanel';
import { DAYS_OF_WEEK, formatTime } from '../../utils/helpers';

function TruckHoursEditor() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [foodTruck, setFoodTruck] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    const initializeServices = () => {
      try {
        const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
        setServices({ foodTruckSrv });
        return { foodTruckSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadFoodTruck = async () => {
      try {
        setLoading(true);
        const { foodTruckSrv } = initializeServices();
        const data = await foodTruckSrv.getFoodTruck();
        setFoodTruck(data);
      } catch (error) {
        console.error("Error loading food truck:", error);
        setError("Erreur lors du chargement des données du food truck");
        toast.error("Erreur lors du chargement des données du food truck");
      } finally {
        setLoading(false);
      }
    };
    loadFoodTruck();
  }, [foodTruckId, contextLoading]);

  const handleUpdate = async (updatedData) => {
    if (!services?.foodTruckSrv) return;

    try {
      setLoading(true);
      await services.foodTruckSrv.updateFoodTruck(updatedData);
      setFoodTruck(updatedData);
      setIsEditing(false);
      toast.success("Food truck mis à jour avec succès !");
    } catch (error) {
      console.error("Error updating food truck:", error);
      toast.error("Erreur lors de la mise à jour du food truck");
    } finally {
      setLoading(false);
    }
  };

  const openInGoogleMaps = (coordinates) => {
    if (!coordinates) return;
    const { latitude, longitude } = coordinates;
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank');
  };

  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!foodTruck) return null;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaClock className="mr-3 text-emerald-500" />
          Horaires et emplacements
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaEdit />}
          onClick={() => setIsEditing(true)}
        >
          Modifier
        </Button>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ ml: 2 }}>
          {foodTruck.weeklySchedule?.map((day, dayIndex) => (
            <Box key={day.day} sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 1 }}>
                {DAYS_OF_WEEK.find(day => day.id === dayIndex + 1)?.name}
              </Typography>
              {day.locations.length > 0 ? (
                <Box sx={{ pl: 2 }}>
                  {day.locations.map((location, locationIndex) => (
                    <Box
                      key={location.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 1,
                        p: 2,
                        borderRadius: 1,
                        bgcolor: 'background.paper',
                        border: '1px solid',
                        borderColor: 'divider',
                        '&:last-child': { mb: 0 },
                        transition: 'all 0.2s',
                        '&:hover': {
                          bgcolor: 'action.hover',
                          transform: 'translateX(4px)'
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                        <FaClock color="primary" size={16} />
                        <Typography sx={{ fontWeight: 500, color: 'text.primary' }}>
                          {formatTime(location.startTime)} - {formatTime(location.endTime)}
                        </Typography>
                      </Box>
                      <Typography sx={{ flex: 2, color: 'text.secondary' }}>
                        {location.address}
                      </Typography>
                      {location.coordinates && (
                        <Tooltip title="Voir sur Google Maps">
                          <IconButton 
                            onClick={() => openInGoogleMaps(location.coordinates)}
                            color="primary"
                            size="small"
                          >
                            <FaMapMarkerAlt />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography variant="body2" sx={{ color: 'text.secondary', pl: 2, fontStyle: 'italic' }}>
                  Fermé
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={isEditing}
        onClose={() => setIsEditing(false)}
        PaperProps={{ sx: { width: 500 } }}
      >
        <TruckHoursEditPanel
          foodTruck={foodTruck}
          onClose={() => setIsEditing(false)}
          onUpdate={handleUpdate}
        />
      </Drawer>
    </Box>
  );
}

export default TruckHoursEditor;