import React, { useState, useEffect, useMemo } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { notificationService } from '../../services/notificationsService';
import { foodTruckService } from '../../services/foodTruckService';
import {
    Box,
    Typography,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Drawer,
    InputAdornment,
    Button,
    Alert,
    Divider,
    MenuItem,
    InputLabel,
    FormControl,
    Select
} from '@mui/material';
import { FaSearch, FaBell, FaPlus, FaTimes } from 'react-icons/fa';
import { formatDate } from '../../utils/helpers';
import Loader from '../Common/Loader';

const NotificationSidePanel = ({ open, onClose, onSubmit, notification, setNotification, loading, loyaltyLevels }) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: '500px' }
            }}
        >
            <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h6" fontWeight="bold">
                        Nouvelle notification
                    </Typography>
                    <IconButton onClick={onClose}>
                        <FaTimes />
                    </IconButton>
                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Form */}
                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        fullWidth
                        label="Titre"
                        variant="outlined"
                        value={notification.title}
                        onChange={(e) => setNotification(prev => ({ ...prev, title: e.target.value }))}
                        sx={{ mb: 3 }}
                    />

                    <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={notification.message}
                        onChange={(e) => setNotification(prev => ({ ...prev, message: e.target.value }))}
                        sx={{ mb: 3 }}
                    />

                    <FormControl fullWidth>
                        <InputLabel>Niveau de fidélité cible</InputLabel>
                        <Select
                            value={notification.loyaltyLevelId || "all"}
                            onChange={(e) => {
                                const value = e.target.value === "all" ? null : e.target.value;
                                setNotification(prev => ({ ...prev, loyaltyLevelId: value }));
                            }}
                            label="Niveau de fidélité cible"
                        >
                            <MenuItem value="all">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                        sx={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: '50%',
                                            border: '1px solid #ccc'
                                        }}
                                    />
                                    Tous les utilisateurs
                                </Box>
                            </MenuItem>
                            {loyaltyLevels?.map((level) => (
                                <MenuItem key={level.id} value={level.id}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Box
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                borderRadius: '50%',
                                                backgroundColor: level.color || '#808080',
                                            }}
                                        />
                                        {level.name}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Footer with actions */}
                <Box sx={{ mt: 3, pt: 3, borderTop: 1, borderColor: 'divider' }}>
                    <Typography variant="caption" color="textSecondary" display="block" sx={{ mb: 2 }}>
                        La notification sera envoyée immédiatement à tous les utilisateurs actifs.
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button onClick={onClose} color="inherit">
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={!notification.title || !notification.message || loading}
                        >
                            {loading ? 'Envoi...' : 'Envoyer la notification'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

const Notifications = () => {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('timestamp');
    const [order, setOrder] = useState('desc');
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [services, setServices] = useState(null);
    const [loyaltyLevels, setLoyaltyLevels] = useState([]);
    const [newNotification, setNewNotification] = useState({
        title: '',
        message: '',
        loyaltyLevelId: ''
    });
    // Pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useEffect(() => {
        if (!foodTruckId || contextLoading) return;

        let notifUnsubscribe;

        const initializeServices = () => {
            try {
                const notifSrv = notificationService.getInstance(foodTruckId);
                const ftSrv = foodTruckService.getInstance(foodTruckId);
                setServices({ notifSrv, ftSrv });
                return { notifSrv, ftSrv };
            } catch (error) {
                console.error("Error initializing services:", error);
                throw error;
            }
        };

        const loadData = async () => {
            try {
                setLoading(true);
                const { notifSrv, ftSrv } = initializeServices();

                // Charger les niveaux de fidélité
                const loyaltyProgram = await ftSrv.getLoyaltyProgram();
                if (loyaltyProgram?.levels) {
                    setLoyaltyLevels(loyaltyProgram.levels.map(level => ({
                        id: level.id,
                        name: level.name,
                    })));
                }

                notifUnsubscribe = notifSrv.listenNotifications(
                    (notificationsData) => {
                        setNotifications(notificationsData);
                        setFilteredNotifications(notificationsData);
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching notifications:", error);
                        setError("Erreur lors du chargement des notifications");
                        setLoading(false);
                    }
                );

            } catch (error) {
                console.error("Error loading notifications:", error);
                setError("Erreur lors du chargement des données");
                setLoading(false);
            }
        };

        loadData();

        return () => {
            if (notifUnsubscribe) notifUnsubscribe();
        };
    }, [foodTruckId, contextLoading]);

    const getLoyaltyLevelLabel = (loyaltyLevelId) => {
        if (!loyaltyLevelId) return 'Tous';
        const level = loyaltyLevels.find(l => l.id === loyaltyLevelId);
        return level ? level.name : '---';
    };


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0); // Reset to first page when sorting
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        const filtered = notifications.filter(notification =>
            notification.title?.toLowerCase().includes(value.toLowerCase()) ||
            notification.message?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredNotifications(filtered);
        setPage(0); // Reset to first page when searching
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleCreateNotification = async () => {
        if (!newNotification.title || !newNotification.message) {
            return;
        }

        setSubmitting(true);
        try {
            await services.notifSrv.sendNotification({
                foodTruckId: foodTruckId,
                title: newNotification.title,
                message: newNotification.message,
            }, newNotification.loyaltyLevelId);
            setIsSidePanelOpen(false);
            setNewNotification({ title: '', message: '' });
        } catch (error) {
            console.error("Error creating notification:", error);
            setError("Erreur lors de l'envoi de la notification");
        } finally {
            setSubmitting(false);
        }
    };

    const columns = [
        { id: 'title', label: 'Titre' },
        { id: 'message', label: 'Message' },
        { id: 'loyaltyLevel', label: 'Cible' },
        { id: 'sentCount', label: 'Envoyés' },
        { id: 'successCount', label: 'Succès' },
        { id: 'timestamp', label: 'Date' }, 
    ];


    const sortedNotifications = useMemo(() => {
        return [...filteredNotifications].sort((a, b) => {
            const isAsc = order === 'asc';

            if (orderBy === 'timestamp') {
                const dateA = a[orderBy]?.toDate?.() || new Date(0);
                const dateB = b[orderBy]?.toDate?.() || new Date(0);
                return isAsc ? dateA - dateB : dateB - dateA;
            }

            if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
            return 0;
        });
    }, [filteredNotifications, order, orderBy]);

    // Get current page data
    const paginatedNotifications = useMemo(() => {
        const startIndex = page * rowsPerPage;
        return sortedNotifications.slice(startIndex, startIndex + rowsPerPage);
    }, [sortedNotifications, page, rowsPerPage]);

    const getSuccessRate = (notification) => {
        if (!notification) return '---';
        const { sentCount, successCount } = notification;
        if (sentCount === 0) return '0%';
        return `${Math.round((successCount / sentCount) * 100)}%`;
    };

    if (loading) return <Loader />;
    if (error) return <Typography color="error">Une erreur est survenue.</Typography>;

    return (
        <Box sx={{ p: 3 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FaBell className="mr-3 text-emerald-500" />
                    Notifications
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FaPlus />}
                    onClick={() => setIsSidePanelOpen(true)}
                >
                    Nouvelle notification
                </Button>
            </Box>

            <TextField
                fullWidth
                variant="outlined"
                placeholder="Rechercher une notification..."
                value={searchTerm}
                onChange={handleSearch}
                sx={{ mb: 3 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FaSearch />
                        </InputAdornment>
                    ),
                }}
            />

            <Paper elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell>Taux de succès</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedNotifications.map((notification) => (
                                <TableRow key={notification.id} hover>
                                    <TableCell>{notification.title}</TableCell>
                                    <TableCell>{notification.body}</TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {getLoyaltyLevelLabel(notification.loyaltyLevelId)}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{notification.sentCount || '---'}</TableCell>
                                    <TableCell>{notification.successCount || '---'}</TableCell>
                                    <TableCell>
                                        {notification.timestamp
                                            ? formatDate(notification.timestamp.toDate())
                                            : '---'}
                                    </TableCell>
                                    <TableCell>{getSuccessRate(notification)}</TableCell>
                                </TableRow>
                            ))}
                            {paginatedNotifications.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography color="textSecondary" sx={{ py: 3 }}>
                                            {searchTerm ? "Aucune notification ne correspond à votre recherche" : "Aucune notification disponible"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={sortedNotifications.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Lignes par page"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
            </Paper>


            <NotificationSidePanel
                open={isSidePanelOpen}
                onClose={() => setIsSidePanelOpen(false)}
                onSubmit={handleCreateNotification}
                notification={newNotification}
                setNotification={setNewNotification}
                loading={submitting}
                loyaltyLevels={loyaltyLevels}
            />
        </Box>
    );
};

export default Notifications;