import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Box,
    Typography
} from '@mui/material';
import {
    FaPlus,
    FaTrash,
    FaArrowUp,
    FaArrowDown
} from 'react-icons/fa';

const SupplementsTable = ({ supplements, onMove, onRemove, onAdd }) => {
    const handleMoveItem = (supplementId, direction) => {
        const currentIndex = supplements.findIndex(s => s.id === supplementId);
        if ((direction === 'up' && currentIndex === 0) || 
            (direction === 'down' && currentIndex === supplements.length - 1)) {
            return;
        }

        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        const newSupplements = [...supplements];
        
        // Échanger les éléments
        [newSupplements[currentIndex], newSupplements[newIndex]] = 
        [newSupplements[newIndex], newSupplements[currentIndex]];
        
        // Mettre à jour l'ordre
        newSupplements.forEach((supplement, index) => {
            supplement.order = index;
        });

        onMove(newSupplements);
    };

    return (
        <TableContainer component={Paper} elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Ordre</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Prix</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supplements.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography variant="body2" color="text.secondary">
                                    Aucun supplément ajouté
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        supplements.map((supplement, index) => (
                            <TableRow key={supplement.id}>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Box>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleMoveItem(supplement.id, 'up')}
                                                disabled={index === 0}
                                            >
                                                <FaArrowUp />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleMoveItem(supplement.id, 'down')}
                                                disabled={index === supplements.length - 1}
                                            >
                                                <FaArrowDown />
                                            </IconButton>
                                        </Box>
                                        <Typography variant="body2">
                                            {index + 1}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>{supplement.name}</TableCell>
                                <TableCell>{supplement.price?.toFixed(2)} €</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onRemove(supplement.id)}>
                                        <FaTrash />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                    variant="contained"
                    startIcon={<FaPlus />}
                    onClick={onAdd}
                    sx={{
                        bgcolor: 'primary.main',
                        '&:hover': {
                            bgcolor: 'primary.dark',
                        }
                    }}
                >
                    Ajouter un supplément
                </Button>
            </Box>
        </TableContainer>
    );
};

export default SupplementsTable;