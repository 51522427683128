import { FirebaseService } from '../services/FirebaseService';

export class CategoriesService extends FirebaseService {
  constructor(foodTruckId) {
    super(`foodTrucks/${foodTruckId}/categories`);
  }

  /**
   * Récupère toutes les catégories ordonnées
   * @returns {Promise<Array>} Liste des catégories
   */
  async getCategories() {
    try {
      return this.getAll({ orderBy: 'order' });
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }

  /**
   * Met à jour un ensemble de catégories
   * @param {Array} categories - Liste des catégories à mettre à jour
   * @returns {Promise<boolean>}
   */
  async updateCategories(categories) {
    try {
      const updates = categories.map(async (category) => {
        if (!category.id) {
          // Nouvelle catégorie
          const id = await this.add({
            ...category,
            createdAt: new Date()
          });
          return { ...category, id };
        } else {
          // Mise à jour d'une catégorie existante
          await this.update(category.id, {
            ...category,
            updatedAt: new Date()
          });
          return category;
        }
      });

      await Promise.all(updates);
      return true;
    } catch (error) {
      console.error("Error updating categories:", error);
      throw error;
    }
  }

  /**
   * Supprime une catégorie
   * @param {string} categoryId - ID de la catégorie à supprimer
   */
  async deleteCategory(categoryId) {
    try {
      await this.delete(categoryId);
    } catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  }
}

// Export singleton instance factory
export const categoriesService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {CategoriesService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new CategoriesService(foodTruckId);
  }
};