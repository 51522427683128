import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Box,
} from '@mui/material';

function SelectSupplementDialog({ 
    open = false, 
    onClose = () => {}, 
    onSelect = () => {}, 
    supplements = [], 
    itemSupplements = [] 
}) {
    const [selectedSupplementIds, setSelectedSupplementIds] = useState([]);

    // Sécuriser le filtre avec une vérification des tableaux
    const availableSupplements = Array.isArray(supplements) && Array.isArray(itemSupplements)
        ? supplements.filter(
            supplement => !itemSupplements.some(selected => selected.id === supplement.id)
          )
        : [];

    const handleToggle = (supplementId) => {
        setSelectedSupplementIds(prev => {
            if (prev.includes(supplementId)) {
                return prev.filter(id => id !== supplementId);
            } else {
                return [...prev, supplementId];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedSupplementIds.length === availableSupplements.length) {
            setSelectedSupplementIds([]);
        } else {
            setSelectedSupplementIds(availableSupplements.map(s => s.id));
        }
    };

    const handleSubmit = () => {
        const selectedSupplements = availableSupplements.filter(
            supplement => selectedSupplementIds.includes(supplement.id)
        );
        if (selectedSupplements.length > 0) {
            console.log("selectedSupplements", selectedSupplements);
            onSelect(selectedSupplements);
        }
        setSelectedSupplementIds([]);
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={() => {
                setSelectedSupplementIds([]); // Reset la sélection à la fermeture
                onClose();
            }} 
            maxWidth="sm" 
            fullWidth
        >
            <DialogTitle>Ajouter des suppléments</DialogTitle>
            <DialogContent>
                {availableSupplements.length > 0 ? (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedSupplementIds.length === availableSupplements.length}
                                    indeterminate={selectedSupplementIds.length > 0 && selectedSupplementIds.length < availableSupplements.length}
                                    onChange={handleSelectAll}
                                />
                            }
                            label="Tout sélectionner"
                            sx={{ mb: 1, mt: 1 }}
                        />
                        <FormGroup>
                            {availableSupplements.map((supplement) => (
                                <FormControlLabel
                                    key={supplement.id}
                                    control={
                                        <Checkbox
                                            checked={selectedSupplementIds.includes(supplement.id)}
                                            onChange={() => handleToggle(supplement.id)}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography>{supplement.name}</Typography>
                                            <Typography color="primary">
                                                {(supplement.price || 0).toFixed(2)} €
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{
                                        mx: 0,
                                        width: '100%',
                                        '& .MuiFormControlLabel-label': {
                                            width: '100%'
                                        }
                                    }}
                                />
                            ))}
                        </FormGroup>
                    </>
                ) : (
                    <Typography color="textSecondary" sx={{ mt: 2 }}>
                        Tous les suppléments disponibles ont déjà été ajoutés.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                {availableSupplements.length > 0 ? (
                    <>
                        <Button onClick={() => {
                            setSelectedSupplementIds([]);
                            onClose();
                        }}>
                            Annuler
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            variant="contained" 
                            color="primary" 
                            disabled={selectedSupplementIds.length === 0}
                        >
                            Ajouter{selectedSupplementIds.length > 0 ? ` (${selectedSupplementIds.length})` : ''}
                        </Button>
                    </>
                ) : (
                    <Button 
                        onClick={onClose} 
                        variant="contained" 
                        color="primary"
                    >
                        Fermer
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default SelectSupplementDialog;