import React, { useState, useEffect, useMemo } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { clientService } from '../../services/clientService';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Drawer,
  InputAdornment,
  Button,
  TablePagination,
  Chip,
} from '@mui/material';
import { FaSearch, FaEllipsisV } from 'react-icons/fa';
import Loader from '../Common/Loader';
import CustomerEditSidePanel from './CustomerEditSidePanel';
import { FaPlus, FaUsers } from 'react-icons/fa';
import CustomerCreatePanel from './CustomerCreatePanel';

const Customers = () => {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('lastName');
  const [order, setOrder] = useState('asc');
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editedClient, setEditedClient] = useState(null);
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    let unsubscribe;

    const initializeService = () => {
      try {
        const clientSrv = clientService.getInstance(foodTruckId);
        return clientSrv;
      } catch (error) {
        console.error("Error initializing client service:", error);
        setError(error.message);
        return null;
      }
    };

    const loadClients = async () => {
      try {
        setLoading(true);
        const service = initializeService();
        if (!service) return;

        unsubscribe = service.listenClients(
          (clientData) => {
            setClients(clientData);
            setFilteredClients(clientData);
            setError(null);
          },
          (error) => {
            console.error("Error fetching clients:", error);
            setError("Erreur lors du chargement des clients");
          }
        );
      } catch (error) {
        console.error("Error loading clients:", error);
        setError("Erreur lors du chargement des clients");
      } finally {
        setLoading(false);
      }
    };

    loadClients();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [foodTruckId, contextLoading]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (!value.trim()) {
      setFilteredClients(clients);
    } else {
      const filtered = clients.filter(client =>
        client.firstName?.toLowerCase().includes(value.toLowerCase()) ||
        client.lastName?.toLowerCase().includes(value.toLowerCase()) ||
        client.email?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredClients(filtered);
    }
    setPage(0); // Reset to first page when searching
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0); // Reset to first page when sorting
  };

  const handleClientSelect = (client) => {
    setEditedClient({ ...client });
    setIsSidePanelOpen(true);
  };

  const getLoyaltyStatus = (client) => {
    if (!client.loyalty) {
      return {
        label: 'Aucun abonnement',
        color: 'default'
      };
    }

    const expirationDate = client.loyalty.expirationDate?.toDate();
    const now = new Date();

    if (!expirationDate) {
      return {
        label: 'Statut inconnu',
        color: 'default'
      };
    }

    if (expirationDate < now) {
      return {
        label: 'Expiré',
        color: 'error'
      };
    }

    // Alerte si expiration dans moins de 30 jours
    const daysUntilExpiration = Math.ceil((expirationDate - now) / (1000 * 60 * 60 * 24));
    if (daysUntilExpiration <= 30) {
      return {
        label: `Expire dans ${daysUntilExpiration}j`,
        color: 'warning'
      };
    }

    return {
      label: client.loyalty.name,
      color: 'success'
    };
  };

  const columns = [
    { id: 'lastName', label: 'Nom' },
    { id: 'firstName', label: 'Prénom' },
    { id: 'email', label: 'Email' },
    { id: 'loyaltyPoints', label: 'Points de fidélité' },
    {
      id: 'loyaltyStatus',
      label: 'Abonnement',
      render: (client) => {
        const status = getLoyaltyStatus(client);
        return (
          <Chip
            label={status.label}
            color={status.color}
            size="small"
            sx={{ minWidth: '120px' }}
          />
        );
      }
    }

  ];

  const sortedClients = useMemo(() => {
    return [...filteredClients].sort((a, b) => {
      const isAsc = order === 'asc';
      if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
      return 0;
    });
  }, [filteredClients, order, orderBy]);

  const paginatedClients = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedClients.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedClients, page, rowsPerPage]);

  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaUsers className="mr-3 text-emerald-500" />
          Clients
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={() => setIsCreatePanelOpen(true)}
        >
          Ajouter un client
        </Button>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Rechercher un client..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaSearch />
            </InputAdornment>
          ),
        }}
      />

      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedClients.map((client) => (
                <TableRow key={client.id} hover>
                  <TableCell>{client.lastName}</TableCell>
                  <TableCell>{client.firstName}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.loyaltyPoints}</TableCell>
                  <TableCell>
                    {columns.find(col => col.id === 'loyaltyStatus').render(client)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClientSelect(client)}>
                      <FaEllipsisV />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {paginatedClients.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography color="textSecondary" sx={{ py: 3 }}>
                      {searchTerm ? "Aucun client ne correspond à votre recherche" : "Aucun client disponible"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={sortedClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
        />
      </Paper>


      <Drawer
        anchor="right"
        open={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
      >
        <CustomerEditSidePanel
          customerId={editedClient?.id}
          foodTruckId={foodTruckId}
          onClose={() => setIsSidePanelOpen(false)}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={isCreatePanelOpen}
        onClose={() => setIsCreatePanelOpen(false)}
      >
        <CustomerCreatePanel
          foodTruckId={foodTruckId}
          onClose={() => setIsCreatePanelOpen(false)}
          onSuccess={() => {
            // Les mises à jour seront automatiquement reflétées via l'écouteur
            setIsCreatePanelOpen(false);
          }}
        />
      </Drawer>
    </Box>
  );
};

export default Customers;