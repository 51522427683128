import { Box, Typography } from "@mui/material";
import { FaClock } from "react-icons/fa";

const ClosedStatus = () => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 8,
        height: '100%',
        textAlign: 'center',
        color: 'text.secondary',
        gap: 2
      }}>
        <Box sx={{
          width: 80,
          height: 80,
          borderRadius: '50%',
          bgcolor: 'error.light',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2
        }}>
          <FaClock style={{ 
            width: 40, 
            height: 40, 
            color: '#fff'
          }} />
        </Box>
        
        <Typography variant="h6" color="text.primary" gutterBottom>
          Food Truck fermé
        </Typography>
        
        <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 400 }}>
          Le Food Truck n'est pas en service ce jour-là. 
          Consultez un autre jour pour voir les horaires d'ouverture.
        </Typography>
      </Box>
    );
  };
  
  export default ClosedStatus;