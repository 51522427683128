import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { db } from './firebase';
import {
  collection,
  doc,
  onSnapshot,
  increment,
  updateDoc,
  getDoc,
  setDoc,
  Timestamp,
  Unsubscribe,
  DocumentReference
} from 'firebase/firestore';
import {
  PromoCode,
  PromoCodeError
} from '../models/interfaces';

// Réutilisation des interfaces existantes et définition des nouvelles nécessaires
interface ValidatePromoCodeParams {
  code: string;
  orderAmount: number;
  foodTruckId: string;
}

interface PromoCodeValidationResult {
  isValid: boolean;
  promoId?: string;
  discountAmount?: number;
  errorMessage?: PromoCodeError;
}

interface PromoCodeStats {
  usageCount: number;
  maxUses?: number;
  isExpired: boolean;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export class PromoCodeService {
  private readonly promoCodesPath: string;
  private readonly functions: ReturnType<typeof getFunctions>;
  private readonly validatePromoFunction: (data: ValidatePromoCodeParams) => Promise<HttpsCallableResult<PromoCodeValidationResult>>;
  private readonly foodTruckId: string;

  constructor(foodTruckId: string) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.foodTruckId = foodTruckId;
    this.promoCodesPath = `foodTrucks/${foodTruckId}/promoCodes`;
    this.functions = getFunctions();
    this.validatePromoFunction = httpsCallable<ValidatePromoCodeParams, PromoCodeValidationResult>(
      this.functions,
      'validatePromoCode'
    );
  }

  listenPromoCodes(
    onUpdate: (promoCodes: PromoCode[]) => void,
    onError: (error: Error) => void
  ): Unsubscribe {
    try {
      return onSnapshot(
        collection(db, this.promoCodesPath),
        (snapshot) => {
          const promoCodes = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() } as PromoCode));
          onUpdate(promoCodes);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up promo codes listener:', error);
      throw error;
    }
  }

  async createPromoCode(promoData: Omit<PromoCode, 'id' | 'usageCount' | 'createdAt' | 'updatedAt' | 'isDeleted'>): Promise<string> {
    try {
      const docRef: DocumentReference = doc(collection(db, this.promoCodesPath));
      const now = Timestamp.now();

      const cleanData = Object.fromEntries(
        Object.entries({
          ...promoData,
          id: docRef.id,
          usageCount: 0,
          createdAt: now,
          updatedAt: now
        }).filter(([_, value]) => value !== undefined)
      );

      await setDoc(docRef, cleanData);
      return docRef.id;
    } catch (error) {
      console.error('Error creating promo code:', error);
      throw error;
    }
  }

  async deletePromoCode(promoId: string): Promise<void> {
    try {
      const promoRef = doc(db, this.promoCodesPath, promoId);
      await updateDoc(promoRef, {
        isDeleted: true,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error('Error deleting promo code:', error);
      throw error;
    }
  }

  async validateAndApplyPromoCode(code: string, orderAmount: number): Promise<PromoCodeValidationResult> {
    try {
      const result = await this.validatePromoFunction({
        code: code.toUpperCase(),
        orderAmount,
        foodTruckId: this.foodTruckId
      });

      if (result.data.isValid && result.data.promoId) {
        const promoRef = doc(db, this.promoCodesPath, result.data.promoId);
        await updateDoc(promoRef, {
          usageCount: increment(1),
          updatedAt: Timestamp.now()
        });
      }

      return result.data;
    } catch (error) {
      console.error('Error validating promo code:', error);
      throw error;
    }
  }

  async getPromoCodeStats(promoId: string): Promise<PromoCodeStats> {
    try {
      const promoRef = doc(db, this.promoCodesPath, promoId);
      const promoDoc = await getDoc(promoRef);

      if (!promoDoc.exists()) {
        throw new Error('Promo code not found');
      }

      const data = promoDoc.data() as PromoCode;
      const now = Timestamp.now();

      return {
        usageCount: data.usageCount || 0,
        maxUses: data.maxUses,
        isExpired: data.endDate ? data.endDate.toDate() < now.toDate() : false,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt
      };
    } catch (error) {
      console.error('Error getting promo code stats:', error);
      throw error;
    }
  }

  async updatePromoCode(promoId: string, updates: Partial<Omit<PromoCode, 'id' | 'createdAt' | 'updatedAt'>>): Promise<void> {
    try {
      const promoRef = doc(db, this.promoCodesPath, promoId);
      await updateDoc(promoRef, {
        ...updates,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error('Error updating promo code:', error);
      throw error;
    }
  }
}

// Export singleton instance factory
export const promoCodeService = {
  getInstance(foodTruckId: string): PromoCodeService {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new PromoCodeService(foodTruckId);
  }
};