// Formatage de la monnaie
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount);
};


// Validation d'email
export const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

// Génération d'un ID unique
export const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

// Tronquer un texte
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

export const formatDate = (input) => {
  if (!input) return 'N/A';

  let date;
  if (typeof input === 'string') {
    // Handle string input
    date = new Date(input);
  } else if (typeof input.toDate === 'function') {
    // Handle Firestore Timestamp
    date = input.toDate();
  } else if (input instanceof Date) {
    // Handle Date object
    date = input;
  } else {
    return 'N/A';
  }

  if (isNaN(date.getTime())) {
    return 'N/A';
  }

  return new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};


export const formatTime = (timestamp) => {
  if (!timestamp) return 'N/A';

  let date;
  if (typeof timestamp === 'string') {
    // Handle string input
    date = new Date(timestamp);
  } else if (typeof timestamp.toDate === 'function') {
    // Handle Firestore Timestamp
    date = timestamp.toDate();
  } else if (timestamp instanceof Date) {
    // Handle Date object
    date = timestamp;
  } else {
    return 'N/A';
  }

  if (isNaN(date.getTime())) {
    return 'N/A';
  }

  return date.toTimeString().slice(0, 5);
};

export const formatDateForInput = (date) => {
  if (date && typeof date.toDate === 'function') {
    // C'est un Timestamp Firestore
    date = date.toDate();
  }
  if (date instanceof Date) {
    return date.toISOString().split('T')[0];
  }
  return ''; // Retourne une chaîne vide si la date n'est pas valide
};

//TODO: remove or update me
export const getItemPrice = (item) => {
  const today = new Date();
  if (item.discountPrice && item.expirationDate && new Date(item.expirationDate) >= today) {
    return item.discountPrice;
  }
  return item.price;
};

/**
 * Vérifie si deux dates représentent le même jour.
 * @param {Date} date1 - La première date à comparer
 * @param {Date} date2 - La deuxième date à comparer
 * @returns {boolean} - Vrai si les deux dates représentent le même jour, faux sinon
 */
export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * Formate une date en chaîne de caractères selon le format spécifié.
 * @param {Date} date - La date à formater
 * @param {string} format - Le format désiré (par exemple, 'yyyy-MM-dd')
 * @returns {string} - La date formatée en chaîne de caractères
 */
export function formatDateToInput(date, format = 'yyyy-MM-dd') {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return format
    .replace('yyyy', year)
    .replace('MM', month)
    .replace('dd', day);
}

/**
 * Retourne le premier jour du mois pour une date donnée.
 * @param {Date} date - La date pour laquelle obtenir le premier jour du mois
 * @returns {Date} - Le premier jour du mois
 */
export function getFirstDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 * Retourne le dernier jour du mois pour une date donnée.
 * @param {Date} date - La date pour laquelle obtenir le dernier jour du mois
 * @returns {Date} - Le dernier jour du mois
 */
export function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}


export const DAYS_OF_WEEK = [
  { id: 1, name: 'Lundi' },
  { id: 2, name: 'Mardi' },
  { id: 3, name: 'Mercredi' },
  { id: 4, name: 'Jeudi' },
  { id: 5, name: 'Vendredi' },
  { id: 6, name: 'Samedi' },
  { id: 7, name: 'Dimanche' }
];

export const formatDays = (days) => {
  if (!days?.length) return 'Tous les jours';
  return days.map(dayId => 
    DAYS_OF_WEEK.find(day => day.id === dayId)?.name
  ).filter(Boolean).join(', ');
};
