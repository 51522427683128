import React, { useMemo } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isToday, addWeeks, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Box, IconButton, Typography, Stack, Tooltip } from '@mui/material';
import { FaChevronLeft, FaChevronRight, FaUtensils, FaPizzaSlice } from 'react-icons/fa';
import { formatCurrency } from '../../utils/helpers';

const CountDisplay = ({ count, icon: Icon, label }) => (
    <Tooltip title={label}>
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{
                fontSize: '0.75rem',
                color: 'text.primary'
            }}
        >
            <Icon size={12} />
            <span>{count || 0}</span>
        </Stack>
    </Tooltip>
);

const DayCard = ({ date, isSelected, stats, onClick, loading }) => {
    const isCurrentDay = isToday(date);

    return (
        <Box
            onClick={() => onClick(date)}
            sx={{
                flex: '1 1 0%',
                cursor: 'pointer',
                py: 1,
                px: 2,
                borderRadius: 2,
                bgcolor: 'background.paper',
                transition: 'all 0.2s',
                '&:hover': {
                    boxShadow: 2
                },
                border: 2,
                borderColor: isSelected ? 'primary.main' : isCurrentDay ? 'primary.light' : 'transparent',
                boxShadow: isSelected ? 3 : 0,
                position: 'relative',
                minWidth: '140px',
                '&:after': isSelected ? {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    backgroundColor: 'primary.main',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                } : {}
            }}
        >
            <Stack spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={1} alignItems="baseline">
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 500,
                            color: isSelected ? 'primary.main' : 'text.primary'
                        }}
                    >
                        {isCurrentDay ? "Aujourd'hui" : format(date, 'EEE', { locale: fr })}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                            color: isSelected ? 'primary.main' : 'text.primary'
                        }}
                    >
                        {format(date, 'd')}
                    </Typography>
                </Stack>

                {!loading && stats && stats.totalAmount > 0 && (
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            color: 'success.main',
                            mt: 0.5
                        }}
                    >
                        {formatCurrency(stats.totalAmount)}
                    </Typography>
                )}

                {!loading && stats ? (
                    <Stack
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                        sx={{
                            justifyContent: 'center',
                            width: '100%',
                            mt: 0.5
                        }}
                    >
                        <CountDisplay
                            count={stats.orderCount}
                            icon={FaUtensils}
                            label="Commandes"
                        />
                        <CountDisplay
                            count={stats.pizzaCount}
                            icon={FaPizzaSlice}
                            label="Pizzas"
                        />
                    </Stack>
                ) : (
                    <Typography variant="caption" color="text.secondary">
                        {loading ? '...' : 'Aucune commande'}
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

const OrderWeekNavigator = ({
    selectedDate,
    selectedWeekDate,
    onDateChange,
    weekOrders,
    loading,
    onWeekChange
}) => {
    const weekDays = useMemo(() => {
        const start = startOfWeek(selectedWeekDate, { weekStartsOn: 1 });
        const end = endOfWeek(selectedWeekDate, { weekStartsOn: 1 });
        return eachDayOfInterval({ start, end });
    }, [selectedWeekDate]);

    const weekStats = useMemo(() => {
        if (!weekOrders) return {};

        return Object.entries(weekOrders).reduce((acc, [date, orders]) => {
            acc[date] = {
                orderCount: orders.length,
                pizzaCount: orders.reduce((sum, order) =>
                    sum + order.items.reduce((itemSum, item) => itemSum + item.quantity, 0), 0),
                totalAmount: orders.reduce((sum, order) => sum + (order.totalPrice || 0), 0)
            };
            return acc;
        }, {});
    }, [weekOrders]);

    const weekTotal = useMemo(() => {
        if (!weekStats) return 0;
        return Object.values(weekStats).reduce((total, dayStats) => total + (dayStats.totalAmount || 0), 0);
    }, [weekStats]);

    return (
        <Box sx={{ py: 1.5 }}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ px: 2, mb: 1.5 }}
            >
                <IconButton
                    size="small"
                    onClick={() => onWeekChange(addWeeks(selectedWeekDate, -1))}
                >
                    <FaChevronLeft />
                </IconButton>

                <Stack spacing={0.5}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Semaine du {format(weekDays[0], 'd MMMM', { locale: fr })}
                    </Typography>
                    {weekTotal > 0 && (
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'success.main',
                                fontWeight: 500
                            }}
                        >
                            Total : {formatCurrency(weekTotal)}
                        </Typography>
                    )}
                </Stack>

                <IconButton
                    size="small"
                    onClick={() => onWeekChange(addWeeks(selectedWeekDate, 1))}
                >
                    <FaChevronRight />
                </IconButton>
            </Stack>

            <Box sx={{
                // Conteneur pour ajouter un padding en bas de la zone de scroll
            }}>

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        px: 2,
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                            height: 4,
                            bgcolor: 'background.paper'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: 2,
                            bgcolor: 'primary.light'
                        },
                        pb: 1,
                    }}
                >
                    {weekDays.map(date => (
                        <DayCard
                            key={format(date, 'yyyy-MM-dd')}
                            date={date}
                            isSelected={isSameDay(date, selectedDate)}
                            stats={weekStats[format(date, 'yyyy-MM-dd')]}
                            onClick={onDateChange}
                            loading={loading}
                        />
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

export default OrderWeekNavigator;