import { db } from './firebase';
import { 
  collection, 
  getDocs, 
  doc, 
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  where 
} from 'firebase/firestore';

export class FirebaseService {
  /**
   * Crée une instance du service pour une collection spécifique
   * @param {string} collectionPath - Chemin de la collection
   */
  constructor(collectionPath) {
    this.collectionPath = collectionPath;
  }

  /**
   * Récupère tous les documents de la collection
   * @param {object} options - Options de requête
   * @returns {Promise<Array>}
   */
  async getAll(options = {}) {
    try {
      let q = collection(db, this.collectionPath);
      
      // Ajout des conditions de requête
      if (options.orderBy) {
        q = query(q, orderBy(options.orderBy));
      }
      if (options.where) {
        q = query(q, where(options.where.field, options.where.operator, options.where.value));
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error fetching documents from ${this.collectionPath}:`, error);
      throw error;
    }
  }

  /**
   * Récupère un document spécifique
   * @param {string} id - ID du document
   */
  async getOne(id) {
    try {
      const docRef = doc(db, this.collectionPath, id);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        throw new Error('Document not found');
      }

      return {
        id: docSnap.id,
        ...docSnap.data()
      };
    } catch (error) {
      console.error(`Error fetching document ${id} from ${this.collectionPath}:`, error);
      throw error;
    }
  }

  /**
   * Ajoute un nouveau document
   * @param {object} data - Données du document
   */
  async add(data) {
    try {
      const docRef = await addDoc(collection(db, this.collectionPath), data);
      return docRef.id;
    } catch (error) {
      console.error(`Error adding document to ${this.collectionPath}:`, error);
      throw error;
    }
  }

  /**
   * Met à jour un document
   * @param {string} id - ID du document
   * @param {object} data - Nouvelles données
   */
  async update(id, data) {
    try {
      const docRef = doc(db, this.collectionPath, id);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error(`Error updating document ${id} in ${this.collectionPath}:`, error);
      throw error;
    }
  }

  /**
   * Supprime un document
   * @param {string} id - ID du document
   */
  async delete(id) {
    try {
      const docRef = doc(db, this.collectionPath, id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error(`Error deleting document ${id} from ${this.collectionPath}:`, error);
      throw error;
    }
  }
}