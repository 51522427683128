import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaChevronDown,
  FaChevronUp,
  FaTachometerAlt,
  FaShoppingCart,
  FaUtensils,
  FaTruck,
  FaStar,
  FaUsers,
  FaBell,
  FaTicketAlt,
  FaTags,
  FaClock,
  FaInfoCircle,
  FaCalendarAlt,
  FaPepperHot,
  FaPizzaSlice,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

function Sidebar() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTruckOpen, setIsTruckOpen] = useState(false);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const handleTabClick = (path) => {
    setActiveTab(path);
  };

  return (
    <aside className="bg-gray-900 text-white w-64 min-h-screen p-4">
      <nav>
        <ul>
          <SidebarItem
            to="/"
            icon={<FaTachometerAlt />}
            text="Tableau de bord"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <SidebarItem
            to="/orders"
            icon={<FaShoppingCart />}
            text="Commandes"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <SidebarItem
            to="/ordersTimeline"
            icon={<FaCalendarAlt />}
            text="Planning"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />

          {/* Menu Management Section */}
          <li className="mb-2">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`w-full text-left flex justify-between items-center py-2 px-4 rounded transition duration-200 ${activeTab.startsWith('/menu') ? 'bg-emerald-600 text-white' : 'text-gray-300 hover:bg-gray-700'
                }`}
            >
              <span className="flex items-center">
                <FaUtensils className="mr-3" />
                Gestion du menu
              </span>
              {isMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <AnimatePresence>
              {isMenuOpen && (
                <motion.ul
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 overflow-hidden"
                >
                  <SidebarSubItem
                    to="/menu/categories"
                    icon={<FaTags />}
                    text="Catégories"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                  <SidebarSubItem
                    to="/menu/products"
                    icon={<FaPizzaSlice />}
                    text="Produits"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                  <SidebarSubItem
                    to="/menu/ingredients"
                    icon={<FaPepperHot />}
                    text="Ingrédients"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                </motion.ul>
              )}
            </AnimatePresence>
          </li>

          {/* Truck Management Section */}
          <li className="mb-2">
            <button
              onClick={() => setIsTruckOpen(!isTruckOpen)}
              className={`w-full text-left flex justify-between items-center py-2 px-4 rounded transition duration-200 ${activeTab.startsWith('/truck') ? 'bg-emerald-600 text-white' : 'text-gray-300 hover:bg-gray-700'
                }`}
            >
              <span className="flex items-center">
                <FaTruck className="mr-3" />
                Gestion du camion
              </span>
              {isTruckOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <AnimatePresence>
              {isTruckOpen && (
                <motion.ul
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 overflow-hidden"
                >
                  <SidebarSubItem
                    to="/truck/info"
                    icon={<FaInfoCircle />}
                    text="Informations"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                  <SidebarSubItem
                    to="/truck/hours"
                    icon={<FaClock />}
                    text="Horaires"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                  <SidebarSubItem
                    to="/truck/slots"
                    icon={<FaCalendarAlt />}
                    text="Créneaux"
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                  />
                </motion.ul>
              )}
            </AnimatePresence>
          </li>

          <SidebarItem
            to="/customers"
            icon={<FaUsers />}
            text="Clients"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <SidebarItem
            to="/promo-codes"
            icon={<FaTicketAlt />}
            text="Codes Promo"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <SidebarItem
            to="/loyalty"
            icon={<FaStar />}
            text="Programme de Fidélité"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <SidebarItem
            to="/notifications"
            icon={<FaBell />}
            text="Notifications"
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        </ul>
      </nav>
    </aside>
  );
}

function SidebarItem({ to, icon, text, activeTab, handleTabClick }) {
  return (
    <li className="mb-2">
      <Link
        to={to}
        className={`flex items-center py-2 px-4 rounded transition duration-200 ${activeTab === to
            ? 'bg-emerald-700 text-white'
            : 'text-gray-300 hover:bg-gray-800 hover:text-emerald-500'
          }`}
        onClick={() => handleTabClick(to)}
      >
        {React.cloneElement(icon, { className: 'mr-3' })}
        {text}
      </Link>
    </li>
  );
}

function SidebarSubItem({ to, icon, text, activeTab, handleTabClick }) {
  return (
    <li className="mb-2">
      <Link
        to={to}
        className={`flex items-center py-2 pl-12 rounded transition duration-200 ${activeTab === to
            ? 'bg-emerald-700 text-white'
            : 'text-gray-300 hover:bg-gray-800 hover:text-emerald-500'
          }`}
        onClick={() => handleTabClick(to)}
      >
        {React.cloneElement(icon, { className: 'mr-3' })}
        {text}
      </Link>
    </li>
  );
}

export default Sidebar;
