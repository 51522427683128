import React, { useState, useCallback } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { clientService } from '../../services/clientService';
import { orderService } from '../../services/orderService';
import { FaMoneyBillWave, FaGift, FaStar, FaUser } from 'react-icons/fa';
import { formatCurrency, formatTime } from '../../utils/helpers';
import { Drawer, CircularProgress } from '@mui/material';
import CustomerEditSidePanel from '../Customers/CustomerEditSidePanel';

const getStatusButtonText = (status) => {
    switch (status) {
        case 'pending': return 'Commencer';
        case 'preparing': return 'Prêt';
        case 'ready': return 'Livré';
        default: return '';
    }
};

const PromotionBadge = ({ type }) => {
    const badgeStyle = "px-2 py-1 rounded-full text-xs font-medium mr-2";
    switch (type) {
        case 'freeItem':
            return (
                <span className={`${badgeStyle} bg-purple-100 text-purple-800`}>
                    <FaGift className="inline mr-1" /> Produit offert
                </span>
            );
        case 'loyaltyPoints':
            return (
                <span className={`${badgeStyle} bg-yellow-100 text-yellow-800`}>
                    <FaStar className="inline mr-1" /> Points fidélité
                </span>
            );
        default:
            return null;
    }
};


const renderSupplements = (item) => {
    if (!item.supplements || item.supplements.length === 0) return null;

    const totalSupplementsPrice = item.supplements.reduce((total, supp) =>
        total + (supp.price * (supp.quantity || 1)), 0
    );

    return (
        <div className="ml-4 mt-1 text-xs text-gray-600">
            <div className="flex items-center mb-1">
                <span className="font-medium">Suppléments:</span>
            </div>
            {item.supplements.map((supplement, idx) => (
                <div key={idx} className="flex justify-between ml-4">
                    <span>
                        {supplement.name}
                        {(supplement.quantity && supplement.quantity > 1) &&
                            <span className="text-gray-500 ml-1">
                                x{supplement.quantity}
                            </span>
                        }
                    </span>
                    <span>{formatCurrency(supplement.price * (supplement.quantity || 1))}</span>
                </div>
            ))}
            <div className="flex justify-between ml-4 mt-1 text-gray-700 font-medium">
                <span>Total suppléments</span>
                <span>{formatCurrency(totalSupplementsPrice)}</span>
            </div>
        </div>
    );
};


const OrderCard = ({ order, onStatusChange, onPaymentChange }) => {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [isCustomerDrawerOpen, setIsCustomerDrawerOpen] = useState(false);
    const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [error, setError] = useState(null);

    const initializeServices = useCallback(() => {
        if (!foodTruckId) return null;
        try {
            const orderSrv = orderService.getInstance(foodTruckId);
            const clientSrv = clientService.getInstance(foodTruckId);
            return { orderSrv, clientSrv };
        } catch (error) {
            console.error("Error initializing services:", error);
            throw error;
        }
    }, [foodTruckId]);

    const handleCustomerClick = useCallback(async () => {
        if (!order.customerId || !foodTruckId) return;

        setIsLoadingCustomer(true);
        setError(null);

        try {
            const services = initializeServices();
            if (!services) return;

            // Charger les données en parallèle
            const [clientData] = await Promise.all([
                services.clientSrv.getClientById(order.customerId),
            ]);

            setCustomerData(clientData);
            setIsCustomerDrawerOpen(true);
        } catch (error) {
            console.error("Erreur lors du chargement des données client:", error);
            setError(error.message);
        } finally {
            setIsLoadingCustomer(false);
        }
    }, [order.customerId, foodTruckId, initializeServices]);


    const handleDrawerClose = useCallback(() => {
        setIsCustomerDrawerOpen(false);
        setCustomerData(null);
        setError(null);
    }, []);

    // Désactiver les interactions client si le foodTruckId n'est pas disponible
    const isClientInteractionDisabled = !foodTruckId || contextLoading;

    return (
        <>
            <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300">
                {/* En-tête de la commande */}
                <div className="flex justify-between items-center mb-2">
                    <span className="font-bold text-lg">Commande #{order.id.slice(-4)}</span>
                    <span className={`px-2 py-1 rounded text-xs ${order.status === 'pending' ? 'bg-yellow-200 text-yellow-800' :
                        order.status === 'preparing' ? 'bg-blue-200 text-blue-800' :
                            order.status === 'ready' ? 'bg-green-200 text-green-800' :
                                'bg-gray-200 text-gray-800'
                        }`}>
                        {order.status}
                    </span>
                </div>

                {/* Informations client */}
                {order.customerName && (
                    <div className="text-sm text-gray-600 mb-2 flex items-center">
                        <button
                            onClick={handleCustomerClick}
                            className={`flex items-center ${isClientInteractionDisabled
                                    ? 'opacity-50 cursor-not-allowed'
                                    : 'hover:text-blue-600 focus:text-blue-600'
                                } transition-colors duration-200`}
                            disabled={isClientInteractionDisabled || isLoadingCustomer}
                        >
                            <FaUser className="mr-1" />
                            <span className="hover:underline">
                                Client: {order.customerName}
                            </span>
                            {isLoadingCustomer && (
                                <CircularProgress size={12} className="ml-2" />
                            )}
                        </button>
                        {error && (
                            <span className="ml-2 text-red-500 text-xs">
                                Erreur de chargement
                            </span>
                        )}
                        {order.totalLoyaltyPoints > 0 && (
                            <span className="ml-2 bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs">
                                <FaStar className="inline mr-1" /> {order.totalLoyaltyPoints} points
                            </span>
                        )}
                    </div>
                )}

                {/* Horaires */}
                <div className="text-sm mb-2">
                    <div>Pickup: {formatTime(order.pickupTime)}</div>
                </div>

                {/* Liste des articles */}
                <ul className="mb-2  ">
                    {order.items.map((item, index) => (
                        <li key={index} className="text-sm py-2 border-b last:border-b-0">
                            <div className="flex justify-between items-start">
                                <div className="flex-1">
                                    <div className="flex justify-between">
                                        <span>{item.name} x {item.quantity}</span>
                                        <span>{formatCurrency(item.totalPrice)}</span>
                                    </div>
                                    {/* Affichage des suppléments */}
                                    {renderSupplements(item)}

                                    {item.promotions && item.promotions.length > 0 && (
                                        <div className="mt-1">
                                            {item.promotions.map((promo, idx) => (
                                                <div key={idx} className="flex items-center text-xs">
                                                    <PromotionBadge type={promo.type} />
                                                    <span className="text-gray-600">
                                                        {promo.description} (-{formatCurrency(promo.value)})
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                {/* Détails des promotions */}
                {order.promotionDetails && (
                    <div className="mb-4 p-2 bg-purple-50 rounded-lg">
                        <div className="text-sm font-medium text-purple-800 mt-1 flex justify-between">
                            <span>Total des réductions</span>
                            <span>-{formatCurrency(order.promotionDetails.totalDiscount)}</span>
                        </div>
                    </div>
                )}

                {/* Total */}
                <div className="font-bold text-right mb-2">
                    Total: {formatCurrency(order.totalPrice)}
                </div>

                {/* Section paiement */}
                <div className="mt-4 flex items-center justify-between">
                    <div className="flex items-center">
                        <FaMoneyBillWave className={order.isPaid ? "text-green-500 mr-2" : "text-red-500 mr-2"} />
                        <span className="text-sm font-medium">
                            {order.isPaid ? 'Payé' : 'Non payé'}
                        </span>
                    </div>
                    {!order.isPaid && (
                        <button
                            onClick={() => onPaymentChange(order, !order.isPaid)}
                            className="px-4 py-2 rounded-full text-sm font-medium bg-red-500 text-white hover:bg-red-600 transition-colors duration-300"
                        >
                            Payer
                        </button>
                    )}
                </div>

                {/* Détails du paiement */}
                {order.paymentDetails && (
                    <div className="mt-2 text-sm">
                        {Object.entries(order.paymentDetails)
                            .filter(([type, amount]) => amount !== 0)
                            .map(([type, amount]) => (
                                <span key={type} className="mr-2">
                                    {type}: {formatCurrency(amount)}
                                </span>
                            ))}
                    </div>
                )}

                {/* Bouton de changement de statut */}
                {order.status !== 'delivered' && (
                    <button
                        onClick={() => onStatusChange(order.id, order.status)}
                        className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm transition-colors duration-300"
                    >
                        {getStatusButtonText(order.status)}
                    </button>
                )}
            </div>

            {/* Drawer des détails client */}
            <Drawer
                anchor="right"
                open={isCustomerDrawerOpen}
                onClose={handleDrawerClose}
            >
                {customerData ? (
                    <CustomerEditSidePanel
                        customerId={customerData?.id}
                        foodTruckId={foodTruckId}
                        onClose={handleDrawerClose}
                    />
                ) : (
                    <div className="p-4">
                        {error ? (
                            <div className="text-red-500">
                                {error}
                            </div>
                        ) : (
                            <CircularProgress />
                        )}
                    </div>
                )}
            </Drawer>
        </>
    );
};

export default OrderCard;