import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format, addMinutes, eachDayOfInterval, startOfWeek, endOfWeek } from 'date-fns';
import { CircularProgress, Typography, Box } from "@mui/material";
import {
  FaCalendarAlt,
  FaClock,
  FaUsers,
  FaChartPie,
  FaChevronRight,
  FaExclamationCircle,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { foodTruckService } from '../../services/foodTruckService';
import { orderService } from '../../services/orderService';
import { useFirebase } from '../Auth/FirebaseProvider';
import Loader from '../Common/Loader';
import ClosedStatus from './ClosedStatus';
import TimelineWeekNavigator from './TimelineWeekNavigator';
import { formatCurrency } from '../../utils/helpers';


const roundPercentage = (value) => Math.round(value);

// Fonction utilitaire pour calculer le taux d'occupation d'un créneau
const calculateSlotOccupation = (orders, slot, settings) => {

  const slotOrders = orders.filter(order => {
    const orderTime = format(order.pickupTime.toDate(), 'HH:mm');
    return orderTime === format(slot.timestamp, 'HH:mm');
  });

  const totalOrders = slotOrders.length;
  const totalPoints = slotOrders.reduce((sum, order) => sum + (order.complexityPoints || 0), 0);
  return {
    orderOccupation: roundPercentage(Math.min(100, (totalOrders / settings.maxOrdersPerSlot) * 100)),
    pointsOccupation: roundPercentage(Math.min(100, (totalPoints / settings.maxPointsPerSlot) * 100)),
    orders: slotOrders,
    totalOrders,
    totalPoints
  };
};

// Fonction pour calculer les statistiques d'un jour
const calculateDayStats = (orders = [], daySchedule, settings) => {
  if (!daySchedule?.locations || daySchedule.locations.length === 0 || !settings) {
    return {
      ordersCount: 0,
      slotsCount: 0,
      occupationRate: 0,
      totalPoints: 0,
      maxCapacity: 0,
      locations: []
    };
  }

  // Calculer les créneaux pour chaque emplacement
  const locationsStats = daySchedule.locations.map(location => {
    const slots = [];
    let currentTime = location.startTime.toDate();
    const endTime = location.endTime.toDate();

    // Générer tous les créneaux pour cet emplacement
    while (currentTime < endTime) {
      slots.push({
        timestamp: currentTime,
        time: format(currentTime, 'HH:mm')
      });
      currentTime = addMinutes(currentTime, settings.duration);
    }

    // Calculer les statistiques pour chaque créneau
    const slotsWithStats = slots.map(slot => ({
      ...slot,
      ...calculateSlotOccupation(orders, slot, settings)
    }));

    // Calculer les statistiques de l'emplacement
    const totalOrders = slotsWithStats.reduce((sum, slot) => sum + slot.totalOrders, 0);
    const totalPoints = slotsWithStats.reduce((sum, slot) => sum + slot.totalPoints, 0);
    const maxCapacity = slots.length * settings.maxOrdersPerSlot;
    const maxPoints = slots.length * settings.maxPointsPerSlot;

    return {
      locationId: location.id,
      address: location.address,
      slots: slotsWithStats,
      totalOrders,
      totalPoints,
      maxCapacity,
      maxPoints,
      occupationRate: roundPercentage(maxCapacity > 0 ? (totalOrders / maxCapacity) * 100 : 0),
      pointsRate: roundPercentage(maxPoints > 0 ? (totalPoints / maxPoints) * 100 : 0)
    };
  });

  // Calculer les statistiques globales du jour
  const totalOrders = orders.length;
  const totalSlots = locationsStats.reduce((sum, loc) => sum + loc.slots.length, 0);
  const maxCapacity = locationsStats.reduce((sum, loc) => sum + loc.maxCapacity, 0);
  const totalPoints = locationsStats.reduce((sum, loc) => sum + loc.totalPoints, 0);

  return {
    ordersCount: totalOrders,
    slotsCount: totalSlots,
    occupationRate: maxCapacity > 0 ? (totalOrders / maxCapacity) * 100 : 0,
    totalPoints,
    maxCapacity,
    locations: locationsStats
  };
};

const getStatusIcon = (status) => {
  switch (status) {
    case 'pending': return <FaClock className="text-yellow-500" />;
    case 'preparing': return <FaChartPie className="text-blue-500" />;
    case 'ready': return <FaExclamationCircle className="text-green-500" />;
    case 'delivered': return <FaUsers className="text-gray-500" />;
    default: return null;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'pending': return 'bg-yellow-50 border-yellow-200 hover:bg-yellow-100';
    case 'preparing': return 'bg-blue-50 border-blue-200 hover:bg-blue-100';
    case 'ready': return 'bg-green-50 border-green-200 hover:bg-green-100';
    case 'delivered': return 'bg-gray-50 border-gray-200 hover:bg-gray-100';
    default: return 'bg-gray-50 border-gray-200';
  }
};

/**
 * Vérifie si un créneau est actuellement en cours
 * @param {string} slotTime - Heure du créneau (format "HH:mm")
 * @param {Date} slotDate - Date du créneau
 * @param {number} duration - Durée du créneau en minutes
 * @returns {boolean}
 */
const isTimeSlotCurrent = (slotTime, slotDate, duration) => {
  const now = new Date();
  const [hours, minutes] = slotTime.split(':').map(Number);

  const slotStart = new Date(slotDate);
  slotStart.setHours(hours, minutes, 0, 0);

  const slotEnd = new Date(slotStart);
  slotEnd.setMinutes(slotStart.getMinutes() + duration);

  return now >= slotStart && now < slotEnd;
};

const TimeSlot = ({
  time,
  date,
  orders = [],
  settings,
  isNextDay,
  isHistorical // Nouvelle prop
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const totalPoints = orders.reduce((sum, order) => sum + (order.complexityPoints || 0), 0);

  // Pour l'historique, on considère que les limites étaient celles du moment
  const effectiveSettings = isHistorical ? {
    maxOrdersPerSlot: Math.max(orders.length, settings.maxOrdersPerSlot),
    maxPointsPerSlot: Math.max(totalPoints, settings.maxPointsPerSlot),
    duration: settings.duration
  } : settings;

  // Calcul des occupations avec les settings effectifs
  const orderOccupation = roundPercentage(Math.min(100, (orders.length / effectiveSettings.maxOrdersPerSlot) * 100));
  const pointsOccupation = roundPercentage(Math.min(100, (totalPoints / effectiveSettings.maxPointsPerSlot) * 100));

  const isOverbooked = orders.length > settings.maxOrdersPerSlot || totalPoints > settings.maxPointsPerSlot;

  // Calculer la date effective du créneau en tenant compte de J+1
  const effectiveDate = new Date(date);
  if (isNextDay) {
    effectiveDate.setDate(effectiveDate.getDate() + 1);
  }

  // Vérifier si le créneau est actuellement en cours
  const isNow = isTimeSlotCurrent(time, effectiveDate, settings.duration);

  const getOccupationTextColor = (percentage) => {
    if (percentage > 100) return 'text-red-700';
    if (percentage > 70) return 'text-yellow-700';
    return 'text-emerald-700';
  };

  return (
    <div className={`
      border-l-4 bg-white transition-colors
      ${isHistorical ? 'bg-gray-50' : ''} 
      ${isOverbooked ? 'border-l-red-500' :
        orderOccupation > 70 ? 'border-l-yellow-500' :
          'border-l-emerald-500'}
    `}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <FaClock className={`h-5 w-5 ${isNow ? 'text-primary' : 'text-gray-400'}`} />
              <span className={`font-medium ${isNow ? 'text-primary' : 'text-gray-600'}`}>
                {time} {isNextDay && <span className="text-xs text-emerald-700 ml-1">(J+1)</span>}
              </span>
            </div>
            {isNow && (
              <span className="px-2 py-0.5 bg-primary/10 text-primary text-xs rounded-full font-medium">
                En cours
              </span>
            )}
            {isOverbooked && (
              <span className="px-2 py-0.5 bg-red-100 text-red-600 text-xs rounded-full font-medium flex items-center gap-1">
                <FaExclamationCircle className="h-3 w-3" />
                Surchargé
              </span>
            )}
          </div>

          {/* Droite: Stats et bouton */}
          <div className="flex items-center gap-4">
            {/* Stats */}
            <div className="flex gap-3 items-center">
              {/* Commandes */}
              <div className="flex items-center gap-2">
                <FaUsers className="h-4 w-4 text-gray-400" />
                <span className={`text-sm font-medium ${getOccupationTextColor(orderOccupation)}`}>
                  {orders.length}/{settings.maxOrdersPerSlot}
                </span>
              </div>

              {/* Points */}
              <div className="flex items-center gap-2">
                <FaChartPie className="h-4 w-4 text-gray-400" />
                <span className={`text-sm font-medium ${getOccupationTextColor(pointsOccupation)}`}>
                  {totalPoints}/{settings.maxPointsPerSlot}
                </span>
              </div>
            </div>

            {/* Bouton expand */}
            <FaChevronRight
              className={`h-5 w-5 text-gray-400 transition-transform ${isExpanded ? 'rotate-90' : ''}`}
            />
          </div>
        </div>



        {/* Aperçu des commandes (visible si non expandé) */}
        {!isExpanded && orders.length > 0 && (
          <div className="mt-2 flex gap-1 flex-wrap">
            {orders.slice(0, 3).map(order => (
              <span
                key={order.id}
                className={`
                  text-xs px-2 py-1 rounded-full 
                  ${getStatusColor(order.status).replace('hover:bg-', 'bg-')}
                `}
              >
                #{order.id.slice(-4)}
                {order.complexityPoints && ` • ${order.complexityPoints}pts`}
              </span>
            ))}
            {orders.length > 3 && (
              <span className="text-xs px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                +{orders.length - 3}
              </span>
            )}
          </div>
        )}
      </button>

      {/* Liste détaillée des commandes */}
      {isExpanded && (
        <div className="px-4 pb-3 space-y-2">
          {orders.map(order => (
            <div
              key={order.id}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={`
                p-3 rounded-lg transition-all cursor-pointer
                hover:shadow-md relative
                ${getStatusColor(order.status)}
              `}
            >
              {/* Status indicator */}
              <div className="absolute top-3 right-3">
                {getStatusIcon(order.status)}
              </div>

              {/* Order header */}
              <div className="flex items-center gap-2 mb-2">
                <span className="font-medium">#{order.id.slice(-4)}</span>
                {order.complexityPoints && (
                  <span className="text-xs px-2 py-0.5 bg-gray-100 rounded-full">
                    {order.complexityPoints} pts
                  </span>
                )}
              </div>

              {/* Order items */}
              <div className="text-sm text-gray-600 mb-2">
                {order.items.map(item => `${item.quantity}× ${item.name}`).join(', ')}
              </div>

              {/* Order footer */}
              <div className="text-sm font-medium text-gray-900">
                {formatCurrency(order.totalPrice)}
              </div>
            </div>
          ))}

          {orders.length === 0 && (
            <div className="text-center py-6 text-gray-400">
              <FaClock className="h-12 w-12 mx-auto mb-2 text-gray-300" />
              <span className="text-sm">Aucune commande sur ce créneau</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const TimelineLocation = ({
  location,
  timeSlots,
  orders,
  settings,
  visibleTimeRange,
  selectedDate,
  isInPast // Nouvelle prop pour indiquer si on est dans le passé
}) => {
  // Déterminer si le créneau est à cheval sur deux jours
  const isOvernightLocation = useMemo(() => {
    const startTime = location.startTime.toDate();
    const endTime = location.endTime.toDate();
    return format(startTime, 'HH:mm') > format(endTime, 'HH:mm');
  }, [location]);

  const displayedTimeSlots = useMemo(() => {
    if (isInPast) {
      // Pour le passé, on crée les créneaux uniquement à partir des commandes
      const slotsFromOrders = orders.reduce((acc, order) => {
        const orderTime = format(order.pickupTime.toDate(), 'HH:mm');

        if (!acc.find(slot => slot.time === orderTime)) {
          acc.push({
            time: orderTime,
            timestamp: order.pickupTime.toDate(),
            endTime: format(addMinutes(order.pickupTime.toDate(), order.duration || settings.duration), 'HH:mm'),
            historical: true
          });
        }

        return acc;
      }, []).sort((a, b) => {
        return a.timestamp.getTime() - b.timestamp.getTime();
      });

      return slotsFromOrders;
    }

    // Pour le futur, traiter les créneaux avec overnight et filtrage
    let slots = timeSlots;

    // Gérer les créneaux overnight
    if (isOvernightLocation) {
      slots = slots.map(slot => ({
        ...slot,
        day: parseInt(slot.time.split(':')[0]) >= parseInt(format(location.startTime.toDate(), 'HH'))
          ? 'today'
          : 'tomorrow'
      }));
    }

    // Filtrer les créneaux selon la plage horaire visible
    slots = slots.filter(slot => {
      const slotTime = slot.time;

      if (!isOvernightLocation) {
        return slotTime >= visibleTimeRange.start && slotTime <= visibleTimeRange.end;
      }

      // Pour les créneaux overnight
      if (parseInt(slotTime.split(':')[0]) >= parseInt(visibleTimeRange.start.split(':')[0])) {
        return true;
      } else if (parseInt(slotTime.split(':')[0]) <= parseInt(visibleTimeRange.end.split(':')[0])) {
        return true;
      }

      return false;
    });

    return slots;
  }, [
    isInPast,
    orders,
    timeSlots,
    settings.duration,
    isOvernightLocation,
    location,
    visibleTimeRange
  ]);


  return (
    <Box sx={{
      width: '100%',
      borderBottom: 1,
      borderColor: 'divider',
      '&:last-child': { borderBottom: 0 },
      mb: 4 // Ajout d'une marge en bas pour séparer les emplacements
    }}>
      {/* Titre de l'emplacement */}
      <Box sx={{
        px: 2,
        py: 1.5,
        bgcolor: 'grey.50',
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}>
        <FaMapMarkerAlt className="h-5 w-5 text-primary" />
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {location.address}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {format(location.startTime.toDate(), 'HH:mm')} - {format(location.endTime.toDate(), 'HH:mm')}
          </Typography>
        </Box>
      </Box>
      {isInPast && (
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            p: 1,
            bgcolor: 'grey.100',
            color: 'text.secondary'
          }}
        >
          Historique des commandes
        </Typography>
      )}

      {isInPast && orders.length === 0 ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
          px: 2,
          color: 'text.secondary',
          bgcolor: 'background.paper'
        }}>
          <FaClock className="h-8 w-8 mb-2 text-gray-300" />
          <Typography variant="body2" color="text.secondary">
            Aucune commande n'a été enregistrée à cet emplacement
          </Typography>
        </Box>
      ) : (
        <>
          {isOvernightLocation && !isInPast && (
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                p: 1,
                bgcolor: 'warning.light',
                color: 'warning.contrastText'
              }}
            >
              Ce créneau s'étend sur deux jours
            </Typography>
          )}

          {displayedTimeSlots.map(slot => (
            <TimeSlot
              key={`${location.id}-${slot.time}`}
              time={slot.time}
              date={selectedDate}
              endTime={slot.endTime}
              orders={orders.filter(order =>
                format(order.pickupTime.toDate(), 'HH:mm') === slot.time
              )}
              settings={settings}
              isNextDay={slot.day === 'tomorrow'}
              isHistorical={slot.historical}
            />
          ))}
        </>
      )}
    </Box>
  );
};


const OrderTimeline = () => {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [dailySchedule, setDailySchedule] = useState(null);
  const [settings, setSettings] = useState({
    maxOrdersPerSlot: 4,
    maxPointsPerSlot: 20,
    duration: 30
  });
  // États pour la gestion des dates
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeekDate, setSelectedWeekDate] = useState(new Date());

  // États de chargement et d'erreur
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [navigationLoading, setNavigationLoading] = useState(false);

  // États pour la gestion de l'affichage
  const [visibleTimeRange, setVisibleTimeRange] = useState(null);

  // États pour les données
  const [weekOrders, setWeekOrders] = useState({});
  const [weekStats, setWeekStats] = useState({});
  const [foodTruck, setFoodTruck] = useState(null);
  // Gestion de l'historique
  const isInPast = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const compareDate = new Date(selectedDate);
    compareDate.setHours(0, 0, 0, 0);
    return compareDate < today;
  }, [selectedDate]);


  // Initialisation du planning quotidien
  const initializeDailySchedule = useCallback((date) => {
    if (!foodTruck) return;

    const currentWeekDay = date.getDay() || 7;
    const todaySchedule = foodTruck.weeklySchedule?.find(
      schedule => schedule.weekDay === currentWeekDay
    );

    setDailySchedule(todaySchedule || null);

    if (todaySchedule?.locations?.length > 0) {
      const firstLocation = todaySchedule.locations[0];
      const startTimeStr = format(firstLocation.startTime.toDate(), 'HH:mm');
      const endTimeStr = format(firstLocation.endTime.toDate(), 'HH:mm');
      setVisibleTimeRange({ start: startTimeStr, end: endTimeStr });
    }
  }, [foodTruck]);


  useEffect(() => {
    if (!foodTruck || !settings) return;

    // Écouter les commandes de la semaine
    const weekStart = startOfWeek(selectedWeekDate, { weekStartsOn: 1 });

    // Initialiser le planning du jour sélectionné
    initializeDailySchedule(selectedWeekDate);

    // Calculer les statistiques pour chaque jour
    const stats = {};
    eachDayOfInterval({
      start: weekStart,
      end: endOfWeek(selectedWeekDate, { weekStartsOn: 1 })
    }).forEach(date => {
      const dayKey = format(date, 'yyyy-MM-dd');
      const daySchedule = foodTruck.weeklySchedule.find(
        schedule => schedule.weekDay === (date.getDay() || 7)
      );

      stats[dayKey] = calculateDayStats(
        weekOrders[dayKey] || [],
        daySchedule,
        settings
      );
    });

    setWeekStats(stats);
    setLoading(false);
    setNavigationLoading(false);
  }, [foodTruck, weekOrders, selectedWeekDate, settings, initializeDailySchedule]);

  // Effet pour l'initialisation des services et le chargement des données
  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    let unsubscribe;
    setLoading(true);

    const initializeServices = async () => {
      try {
        const orderSrv = orderService.getInstance(foodTruckId);
        const foodTruckSrv = foodTruckService.getInstance(foodTruckId);

        const foodTruckData = await foodTruckSrv.getFoodTruck();
        setFoodTruck(foodTruckData);
        setSettings(prev => ({
          ...prev,
          ...(foodTruckData.timeSlotSettings || {})
        }));

        return { orderSrv, foodTruckData };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadData = async () => {
      try {
        const { orderSrv } = await initializeServices();

        // Écouter les commandes de la semaine
        const weekStart = startOfWeek(selectedWeekDate, { weekStartsOn: 1 });

        unsubscribe = orderSrv.listenToWeekOrders({
          onUpdate: (ordersByDay) => {
            setWeekOrders(ordersByDay);
          },
          onError: (error) => {
            console.error('Error loading orders:', error);
            setError(error.message);
            setLoading(false);
            setNavigationLoading(false);
          },
          date: weekStart
        });

      } catch (error) {
        setError("Erreur lors de l'initialisation des services");
        setLoading(false);
        setNavigationLoading(false);
      }
    };

    loadData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [foodTruckId, contextLoading, selectedWeekDate]);

  // Effet pour la mise à jour du planning quotidien lors du changement de date
  useEffect(() => {
    if (!loading && foodTruck) {
      initializeDailySchedule(selectedDate);
    }
  }, [selectedDate, initializeDailySchedule, loading, foodTruck]);


  // Créer les créneaux horaires pour chaque emplacement
  const locationTimeSlots = useMemo(() => {
    if (!dailySchedule?.locations) return [];

    const slots = dailySchedule.locations.map(locationSlot => {
      const slots = [];
      let currentTime = locationSlot.startTime.toDate();
      const endTime = locationSlot.endTime.toDate();

      while (currentTime < endTime) {
        slots.push({
          time: format(currentTime, 'HH:mm'),
          endTime: format(addMinutes(currentTime, settings.duration), 'HH:mm'),
          timestamp: currentTime
        });
        currentTime = addMinutes(currentTime, settings.duration);
      }

      return {
        location: locationSlot,
        slots,
        startTimestamp: locationSlot.startTime.toDate().getTime()
      };
    });

    // Tri par heure de début
    return slots.sort((a, b) => {
      const timeA = format(new Date(a.startTimestamp), 'HH:mm');
      const timeB = format(new Date(b.startTimestamp), 'HH:mm');
      return timeA.localeCompare(timeB);
    });
  }, [dailySchedule, settings.duration]);


  const currentTime = format(new Date(), 'HH:mm');

  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaCalendarAlt className="mr-3 text-emerald-500" />
          Planning des commandes
        </Typography>

      </Box>

      {/* Navigation temporelle */}
      <Box sx={{ mb: 3 }}>
        <TimelineWeekNavigator
          selectedDate={selectedDate}
          selectedWeekDate={selectedWeekDate}
          onDateChange={(newDate) => {
            setSelectedDate(newDate);
          }}
          weekStats={weekStats}
          loading={loading || navigationLoading}
          onWeekChange={(newDate) => {
            setNavigationLoading(true);
            setSelectedWeekDate(newDate);
          }}
        />
      </Box>

      {/* Contenu principal scrollable */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        position: 'relative'
      }}>
        {/* Loader overlay */}
        {(loading || navigationLoading) && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10
          }}>
            <CircularProgress />
          </Box>
        )}

        {!dailySchedule || !dailySchedule.locations?.length ? (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            height: '100%'
          }}>
            <ClosedStatus />
          </Box>
        ) : (
          <Box >
            {locationTimeSlots.map(({ location, slots }) => (
              <TimelineLocation
                key={location.id}
                location={location}
                timeSlots={slots}
                orders={weekOrders[format(selectedDate, 'yyyy-MM-dd')] || []}
                settings={settings}
                currentTime={currentTime}
                visibleTimeRange={visibleTimeRange}
                selectedDate={selectedDate}
                isInPast={isInPast}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}


export default OrderTimeline;