import React, { useCallback, useState, useEffect } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { statsService } from '../../services/dashboardService';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { formatCurrency } from '../../utils/helpers';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const messages = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Événement',
  noEventsInRange: 'Aucune commande sur cette période.',
};

const OrderCalendar = () => {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [dailyStats, setDailyStats] = useState({});
  const [service, setService] = useState(null);
  const [currentDate] = useState(new Date());

  useEffect(() => {
    if (!foodTruckId || contextLoading) {
      setService(null);
      return;
    }

    try {
      const statsServiceInstance = statsService.getInstance(foodTruckId);
      setService(statsServiceInstance);
    } catch (error) {
      console.error("Error initializing stats service:", error);
      setService(null);
    }
  }, [foodTruckId, contextLoading]);  // Charger les statistiques pour le mois en cours
  const loadMonthStats = useCallback(async (date) => {
    if (!service) return;

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    try {
      const unsubscribe = service.listenDailyOrderStats(
        startOfMonth,
        endOfMonth,
        (stats, error) => {
          if (error) {
            return;
          }
          setDailyStats(stats);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error("Error loading month stats:", error);
    }
  }, [service]);

  useEffect(() => {
    let unsubscribe;

    const initialize = async () => {
      unsubscribe = await loadMonthStats(currentDate);
    };

    initialize();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [loadMonthStats, currentDate]);


  const events = Object.entries(dailyStats).map(([date, stats]) => ({
    title: `${stats.count} commande${stats.count > 1 ? 's' : ''} - ${formatCurrency(stats.revenue)}`,
    start: new Date(date),
    end: new Date(date),
    allDay: true,
    resource: stats
  }));

  const eventStyleGetter = (event) => {
    const backgroundColor = '#0D3D58';
    return {
      style: {
        backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
        fontSize: '0.8em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '2px 4px'
      }
    };
  };

  const CustomEvent = ({ event }) => (
    <div title={event.title} className="h-full w-full text-center">
      <div className="font-bold">{event.resource.count} commande{event.resource.count > 1 ? 's' : ''}</div>
      <div>{formatCurrency(event.resource.revenue)}</div>
    </div>
  );

  const CustomToolbar = toolbar => {
    return (
      <div className="flex justify-between mb-4">
      </div>
    );
  };

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-4">Calendrier des commandes</h2>
      <div className="h-[600px]">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          views={['month']}
          defaultView="month"
          eventPropGetter={eventStyleGetter}
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar
          }}
          messages={messages}
        />
      </div>
    </div>
  );
};

export default OrderCalendar;