import React, { useState, useEffect, useMemo } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { menuService } from '../../services/menuService';
import { supplementsService } from '../../services/supplementService';
import { categoriesService } from '../../services/categoriesService';
import { orderService } from '../../services/orderService';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    CircularProgress,
    Tabs,
    Tab,
    InputAdornment,
    Drawer,
} from '@mui/material';
import { FaTimes, FaPlus, FaMinus, FaSave, FaSearch, FaCog } from 'react-icons/fa';
import { formatCurrency } from '../../utils/helpers';
import ClientSelectionSection from './ClientSelectionSection';
import SlotSelector from './SlotSelector';
import SectionTitle from '../Common/Title';
import SupplementsSidePanel from './SupplementsSidePanel';
import Loader from '../Common/Loader';

function AddOrderSidePanel({ onClose, onAddOrder }) {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [menuItems, setMenuItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoadingSlots, setIsLoadingSlots] = useState(false);
    const [isAddingOrder, setIsAddingOrder] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');
    const [supplements, setSupplements] = useState(null);
    const [selectedItemForSupplements, setSelectedItemForSupplements] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [services, setServices] = useState(null);

    const [newOrder, setNewOrder] = useState({
        items: [],
        timeslot: '',
        status: 'pending',
        finalPrice: 0,
        customerName: ''
    });

    useEffect(() => {
        if (!foodTruckId || contextLoading) return;

        let menuUnsubscribe;
        let supplementUnsubscribe;

        const loadServices = () => {
            try {
                const menuSrv = menuService.getInstance(foodTruckId);
                const catService = categoriesService.getInstance(foodTruckId);
                const suppService = supplementsService.getInstance(foodTruckId);
                const orderSrv = orderService.getInstance(foodTruckId);
                setServices({ menuSrv, catService, suppService, orderSrv });
                return { menuSrv, catService, suppService, orderSrv };
            } catch (error) {
                console.error("Error initializing services:", error);
                throw error;
            }
        };

        const fetchInitialData = async () => {
            try {
                setLoading(true);
                const { menuSrv, catService, suppService } = loadServices();

                // Configurer l'écouteur de menu
                menuUnsubscribe = menuSrv.listenMenu(
                    (menuData) => {
                        setMenuItems(menuData);
                    },
                    (error) => {
                        console.error("Error listening to menu:", error);
                        setError("Erreur lors du chargement du menu");
                    }
                );

                // Configurer l'écouteur de suppléments
                supplementUnsubscribe = suppService.listenSupplements(
                    (supplementsData) => {
                        setSupplements(supplementsData);
                    },
                    (error) => {
                        console.error("Error listening to supplements:", error);
                        setError("Erreur lors du chargement des suppléments");
                    }
                );

                // Charger les catégories
                const categoriesData = await catService.getCategories();
                setCategories(categoriesData);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
                setError("Erreur lors du chargement des données. Veuillez réessayer.");
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();

        return () => {
            if (menuUnsubscribe) {
                menuUnsubscribe();
            }
            if (supplementUnsubscribe) {
                supplementUnsubscribe();
            }
        };
    }, [foodTruckId, contextLoading]);


    useEffect(() => {
        const fetchAvailableSlots = async () => {
            if (newOrder.items.length > 0) {
                setIsLoadingSlots(true);
                setAvailableSlots([]);
                setNewOrder(prev => ({ ...prev, timeslot: '' }));
                try {
                    const slots = await services.orderSrv.findNextAvailableSlots(newOrder.items);
                    setAvailableSlots(slots);
                } catch (error) {
                    console.error("Erreur lors de la récupération des créneaux:", error);
                } finally {
                    setIsLoadingSlots(false);
                }
            } else {
                setAvailableSlots([]);
                setNewOrder(prev => ({ ...prev, timeslot: '' }));
            }
        };

        fetchAvailableSlots();
    }, [newOrder.items, services?.orderSrv]);

    const calculateTotalPrice = (items) => {
        return items.reduce((total, item) => {
            const itemPrice = item.price * item.quantity;
            const supplementsPrice = (item.supplements || []).reduce((suppTotal, supp) => {
                const supplement = supplements?.find(s => s.id === supp.id);
                if (supplement && supp.quantity > 0) { // Vérifier que la quantité est > 0
                    const price = supplement.discountPrice ?? supplement.price;
                    return suppTotal + (price * supp.quantity); // Utiliser supp.quantity
                }
                return suppTotal;
            }, 0);
            return total + itemPrice + (supplementsPrice * item.quantity);
        }, 0);
    };

    const generateUniqueItemId = (item) => {
        // Créer un ID unique basé sur l'ID de l'item et ses suppléments
        const supplementsKey = item.supplements
            ? item.supplements
                .sort((a, b) => a.id.localeCompare(b.id))
                .map(s => `${s.id}-${s.quantity}`)
                .join('|')
            : '';
        return `${item.id}${supplementsKey ? `-${supplementsKey}` : ''}`;
    };

    const handleAddItem = (item) => {
        setNewOrder(prev => {
            // Chercher un item existant avec exactement les mêmes suppléments
            const existingItem = prev.items.find(i => generateUniqueItemId(i) === generateUniqueItemId(item));
            let updatedItems;

            if (existingItem) {
                // Si l'item existe avec les mêmes suppléments, augmenter sa quantité
                updatedItems = prev.items.map(i =>
                    generateUniqueItemId(i) === generateUniqueItemId(item)
                        ? { ...i, quantity: i.quantity + 1 }
                        : i
                );
            } else {
                // Sinon, ajouter un nouvel item
                updatedItems = [...prev.items, {
                    ...item,
                    uniqueId: generateUniqueItemId(item), // Ajouter un uniqueId pour le suivi
                    quantity: 1
                }];
            }

            return {
                ...prev,
                items: updatedItems,
                finalPrice: calculateTotalPrice(updatedItems),
                timeslot: ''
            };
        });
    };

    const handleRemoveItem = (uniqueItemId) => {
        setNewOrder(prev => {
            const updatedItems = prev.items.map(item => {
                if (item.uniqueId === uniqueItemId) {
                    return item.quantity > 1
                        ? { ...item, quantity: item.quantity - 1 }
                        : null;
                }
                return item;
            }).filter(Boolean);

            return {
                ...prev,
                items: updatedItems,
                finalPrice: calculateTotalPrice(updatedItems),
                timeslot: ''
            };
        });
    };

    const handleSupplementChange = (item, newSupplements) => {
        setNewOrder(prev => {
            // On trouve l'item à mettre à jour
            const itemToUpdate = item;
            if (!itemToUpdate) return prev;
    
            // Mettre à jour l'item avec les nouveaux suppléments
            const updatedItem = {
                ...itemToUpdate,
                supplements: newSupplements.map(supp => ({
                    id: supp.id,
                    quantity: supp.quantity
                })).filter(s => s.quantity > 0), // Ne garder que les suppléments avec une quantité > 0
            };
    
            // Mettre à jour l'uniqueId avec les nouveaux suppléments
            updatedItem.uniqueId = generateUniqueItemId(updatedItem);
    
            // Vérifier si un item identique existe déjà (même produit + mêmes suppléments)
            const existingItemWithSameSupplements = prev.items.find(i => 
                i.uniqueId === updatedItem.uniqueId && i.id === updatedItem.id && i !== itemToUpdate
            );
    
            let updatedItems;
            if (existingItemWithSameSupplements) {
                // Si un item identique existe, on fusionne les quantités
                updatedItems = prev.items
                    .filter(item => item !== itemToUpdate && item !== existingItemWithSameSupplements)
                    .concat({
                        ...existingItemWithSameSupplements,
                        quantity: existingItemWithSameSupplements.quantity + itemToUpdate.quantity
                    });
            } else {
                // Sinon, on remplace simplement l'ancien item par le nouveau
                updatedItems = prev.items.map(item => 
                    item === itemToUpdate ? updatedItem : item
                );
            }
    
            return {
                ...prev,
                items: updatedItems,
                finalPrice: calculateTotalPrice(updatedItems)
            };
        });
    };
    
    const handleTimeslotSelection = (timeslot) => {
        setNewOrder(prev => ({ ...prev, timeslot }));
    };

    const handleCustomerNameChange = (e) => {
        setNewOrder(prev => ({ ...prev, customerName: e.target.value }));
    };

    const handleSubmitOrder = async (e) => {
        e.preventDefault();
        if (!isFormValid) return;

        setIsAddingOrder(true);
        try {
            const orderToSubmit = {
                ...newOrder,
                customerName: selectedClient?.firstName || newOrder.customerName,
                customerId: selectedClient?.id,
            };

            await onAddOrder(orderToSubmit);
            onClose();
        } catch (error) {
            console.error("Erreur lors de l'ajout de la commande:", error);
            alert("Une erreur est survenue lors de l'ajout de la commande. Veuillez réessayer.");
        } finally {
            setIsAddingOrder(false);
        }
    };

    const isFormValid = newOrder.items.length > 0 && newOrder.timeslot && (newOrder.customerName || selectedClient?.firstName);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleCategoryChange = (event, newValue) => {
        setSelectedCategory(newValue);
        setSearchTerm('');
    };

    const filteredAndSortedMenuItems = useMemo(() => {
        const currentCategory = categories[selectedCategory];
        return menuItems
            .filter(item => item.categoryId === currentCategory?.id)
            .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => {
                const isAsc = order === 'asc';
                if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
                if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
                return 0;
            });
    }, [menuItems, selectedCategory, categories, searchTerm, order, orderBy]);

    const renderOrderSummary = () => (
        <TableContainer component={Paper} elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Article</TableCell>
                        <TableCell align="right">Quantité</TableCell>
                        <TableCell align="center">Suppléments</TableCell>
                        <TableCell align="right">Prix</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newOrder.items.map((item) => {
                        const itemSupplements = item.supplements || [];
                        const supplementsTotal = itemSupplements.reduce((total, supp) => {
                            const supplementData = supplements?.find(s => s.id === supp.id);
                            if (supplementData) {
                                const price = supplementData.discountPrice ?? supplementData.price ?? 0;
                                return total + (price * (supp.quantity || 0));
                            }
                            return total;
                        }, 0);

                        return (
                            <TableRow key={item.uniqueId}>
                                <TableCell>
                                    <Typography>{item.name}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleRemoveItem(item.uniqueId)}
                                            color="secondary"
                                        >
                                            <FaMinus />
                                        </IconButton>
                                        {item.quantity}
                                        <IconButton
                                            size="small"
                                            onClick={() => handleAddItem(item)}
                                            color="primary"
                                        >
                                            <FaPlus />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell align="left">
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        {itemSupplements.length > 0 ? (
                                            <>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    {itemSupplements
                                                        .filter(supp => supp.quantity > 0)
                                                        .map((supp, index) => {
                                                            const supplementData = supplements?.find(s => s.id === supp.id);
                                                            if (!supplementData || supp.quantity === 0) return null;

                                                            const price = supplementData.discountPrice ?? supplementData.price ?? 0;
                                                            const quantity = supp.quantity ?? 0;
                                                            const totalPrice = price * quantity;

                                                            return (
                                                                <Box
                                                                    key={`order-summary-${item.uniqueId}-supp-${supp.id}`}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        py: 0.5,
                                                                        ...(index > 0 && {
                                                                            borderTop: '1px solid',
                                                                            borderColor: 'divider',
                                                                            borderTopStyle: 'dashed'
                                                                        })
                                                                    }}
                                                                >
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                        <Typography variant="body2">
                                                                            {supplementData.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                color: 'text.secondary',
                                                                                bgcolor: 'action.hover',
                                                                                px: 1,
                                                                                py: 0.25,
                                                                                borderRadius: 1,
                                                                                minWidth: '2rem',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            ×{supp.quantity}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            fontWeight: 'medium'
                                                                        }}
                                                                    >
                                                                        +{formatCurrency(totalPrice)}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        })}
                                                </Box>
                                                {supplementsTotal > 0 && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            pt: 0.5,
                                                            borderTop: 1,
                                                            borderColor: 'divider',
                                                            borderStyle: 'dashed'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'text.primary',
                                                                fontWeight: 'medium'
                                                            }}
                                                        >
                                                            +{formatCurrency(supplementsTotal)}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setSelectedItemForSupplements(item)}
                                                    color="primary"
                                                    sx={{ mr: -1 }}
                                                >
                                                    <FaPlus size={14} />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell align="right">
                                    {formatCurrency((item.price + supplementsTotal) * item.quantity)}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        size="small"
                                        onClick={() => setSelectedItemForSupplements(item)}
                                        color="primary"
                                    >
                                        <FaCog />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell colSpan={4} align="right">
                            <Typography variant="subtitle1" fontWeight="bold">Total</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="subtitle1" fontWeight="bold">
                                {formatCurrency(newOrder.finalPrice)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderMenuItems = () => (
        <TableContainer component={Paper} elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={() => handleRequestSort('name')}
                            >
                                Nom
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'price'}
                                direction={orderBy === 'price' ? order : 'asc'}
                                onClick={() => handleRequestSort('price')}
                            >
                                Prix
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">Quantité</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredAndSortedMenuItems.map((item) => {
                        const totalQuantity = newOrder.items
                            .filter(i => i.id === item.id)
                            .reduce((sum, i) => sum + i.quantity, 0);

                        return (
                            <TableRow
                                key={item.id}
                                hover
                                onClick={() => handleAddItem(item)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell>{item.name}</TableCell>
                                <TableCell align="right">{formatCurrency(item.price)}</TableCell>
                                <TableCell align="right">
                                    <Typography component="span" sx={{ mx: 1 }}>
                                        {totalQuantity}
                                    </Typography>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddItem(item);
                                        }}
                                        size="small"
                                        color="primary"
                                    >
                                        <FaPlus />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    if (contextLoading || loading) return <Loader />;
    if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            position: 'relative',
            bgcolor: 'background.default',
        }}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 3,
                pb: 10
            }}>
                <Box component="form" onSubmit={handleSubmitOrder}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" fontWeight="bold" color="primary.main">Nouvelle Commande</Typography>
                        <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                            <FaTimes />
                        </IconButton>
                    </Box>

                    <ClientSelectionSection
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        newOrder={newOrder}
                        handleCustomerNameChange={handleCustomerNameChange}
                    />


                    <SectionTitle>Articles</SectionTitle>
                    <Tabs
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            mb: 3,
                            '& .MuiTab-root': {
                                minWidth: 'auto',
                                px: 3,
                                py: 1,
                                mx: 0.5,
                                borderRadius: '16px',
                                transition: 'all 0.2s',
                                '&.Mui-selected': {
                                    color: 'primary.contrastText',
                                    bgcolor: 'primary.main',
                                },
                            },
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                    >
                        {categories.map((category, index) => (
                            <Tab key={category.id} label={category.name} />
                        ))}
                    </Tabs>

                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Rechercher un article..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ mb: 2 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {renderMenuItems()}

                    <SectionTitle>Résumé de la commande</SectionTitle>
                    {renderOrderSummary()}

                    <SectionTitle>Créneau horaire</SectionTitle>
                    {isLoadingSlots ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <SlotSelector
                            suggestedSlots={availableSlots}
                            selectedSlot={newOrder.timeslot}
                            onSelectSlot={handleTimeslotSelection}
                        />
                    )}
                </Box>
            </Box>

            <Box sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                zIndex: 1
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isAddingOrder}
                    startIcon={<FaTimes />}
                    sx={{
                        minWidth: '120px',
                        color: 'text.secondary',
                        borderColor: 'text.secondary',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    Annuler
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isAddingOrder && (
                        <CircularProgress size={24} sx={{ mr: 2 }} />
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitOrder}
                        disabled={!isFormValid || isAddingOrder}
                        startIcon={<FaSave />}
                        sx={{
                            minWidth: '120px',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            }
                        }}
                    >
                        {isAddingOrder ? 'Ajout...' : 'Ajouter la commande'}
                    </Button>
                </Box>
            </Box>

            <Drawer
                anchor="right"
                open={Boolean(selectedItemForSupplements)}
                onClose={() => setSelectedItemForSupplements(null)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        maxWidth: '600px',
                    },
                }}
            >
                {selectedItemForSupplements && (
                    <SupplementsSidePanel
                        item={selectedItemForSupplements}
                        onClose={() => setSelectedItemForSupplements(null)}
                        onSave={(itemId, newSupplements) => {
                            handleSupplementChange(selectedItemForSupplements, newSupplements);
                            setSelectedItemForSupplements(null);
                        }}
                        availableSupplements={supplements}
                    />
                )}
            </Drawer>

        </Box>


    );
}

export default AddOrderSidePanel;
