import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    CircularProgress,
    Alert
} from '@mui/material';
import { FaTimes, FaUserPlus } from 'react-icons/fa';
import { getFunctions, httpsCallable } from 'firebase/functions';

const CustomerCreatePanel = ({
    foodTruckId,
    onClose,
    onSuccess
}) => {
    const [newClient, setNewClient] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const validateForm = () => {
        if (!newClient.email || !newClient.firstName || !newClient.lastName) {
            setError("Tous les champs sont requis");
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newClient.email)) {
            setError("Format d'email invalide");
            return false;
        }
        return true;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewClient(prev => ({ ...prev, [name]: value }));
        setError(null);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setIsSubmitting(true);
        setError(null);

        try {
            const functions = getFunctions();
            const createCustomerAccount = httpsCallable(functions, 'createCustomerAccount');

            const result = await createCustomerAccount({
                email: newClient.email,
                firstName: newClient.firstName,
                lastName: newClient.lastName,
                foodTruckId
            });

            setSuccess(true);
            if (onSuccess) {
                onSuccess(result.data);
            }
            
            // Réinitialiser le formulaire après 2 secondes
            setTimeout(() => {
                setNewClient({
                    firstName: '',
                    lastName: '',
                    email: '',
                });
                onClose();
            }, 2000);

        } catch (err) {
            console.error('Error creating customer:', err);
            setError(err.message || "Une erreur est survenue lors de la création du compte");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box sx={{
            width: 400,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            p: 3,
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    Nouveau client
                </Typography>
                <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                    <FaTimes />
                </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 10 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Client créé avec succès ! Un email a été envoyé pour définir le mot de passe.
                    </Alert>
                )}

                <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                    Informations personnelles
                </Typography>
                
                <TextField
                    label="Prénom"
                    name="firstName"
                    value={newClient.firstName}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Nom"
                    name="lastName"
                    value={newClient.lastName}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={newClient.email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    required
                    helperText="Un email sera envoyé pour définir le mot de passe"
                />
            </Box>

            <Box sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
                zIndex: 1,
                borderTop: '1px solid',
                borderColor: 'divider'
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : <FaUserPlus />}
                >
                    {isSubmitting ? 'Création...' : 'Créer le client'}
                </Button>
            </Box>
        </Box>
    );
};

export default CustomerCreatePanel;