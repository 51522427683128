import React, { useState, useEffect, useMemo } from 'react';
import { formatDate } from '../../utils/helpers';
import { promoCodeService } from '../../services/promoCodeService';
import { Box, Button, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from '@mui/material';
import PromoCodeSidePanel from './PromoCodeSidePanel';
import { FaPlus, FaSearch, FaTicketAlt } from 'react-icons/fa';
import Loader from '../Common/Loader';
import { useFirebase } from '../Auth/FirebaseProvider';
import { foodTruckService } from '../../services/foodTruckService';
import { Timestamp } from 'firebase/firestore';
import { LoyaltyLevel, LoyaltyProgramData, PromoCode } from '../../models/interfaces';

// Types pour les statuts et leurs couleurs
type PromoStatus = 'active' | 'expired' | 'scheduled';

const STATUS_COLORS: Record<PromoStatus, string> = {
  active: 'text-green-600 bg-green-100',
  expired: 'text-red-600 bg-red-100',
  scheduled: 'text-blue-600 bg-blue-100'
};

// Interface pour les services
interface Services {
  promoSrv: ReturnType<typeof promoCodeService.getInstance>;
  truckSrv: ReturnType<typeof foodTruckService.getInstance>;
}

// Interface pour l'état d'un code promo en cours d'édition
interface EditingPromoCode {
  code: string;
  discountType: 'percentage' | 'fixed';
  discountValue: string | number;
  startDate: Timestamp | null;
  endDate: Timestamp | null;
  minOrderAmount: number | null;
  maxUses: number | null;
  description: string;
  accessType?: 'public' | 'loyalty';
  allowedLoyaltyLevels?: string[];
}

const PromoCodeManagement: React.FC = () => {
  const { foodTruckId } = useFirebase();
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [filteredCodes, setFilteredCodes] = useState<PromoCode[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [services, setServices] = useState<Services | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [orderBy, setOrderBy] = useState<keyof PromoCode>('startDate');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);
  const [loyaltyLevels, setLoyaltyLevels] = useState<LoyaltyLevel[]>([]);
  const [promoCode, setPromoCode] = useState<EditingPromoCode>({
    code: '',
    discountType: 'percentage',
    discountValue: '',
    startDate: null,
    endDate: null,
    minOrderAmount: null,
    maxUses: null,
    description: '',
  });

  useEffect(() => {
    if (!foodTruckId) return;

    let unsubscribe: (() => void) | undefined;

    const initializeServices = (): Services => {
      try {
        const promoSrv = promoCodeService.getInstance(foodTruckId);
        const truckSrv = foodTruckService.getInstance(foodTruckId);
        setServices({ promoSrv, truckSrv });
        return { promoSrv, truckSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadData = async () => {
      try {
        setLoading(true);
        const { promoSrv, truckSrv } = initializeServices();

        const loyaltyProgram = await truckSrv.getLoyaltyProgram() as LoyaltyProgramData;
        if (loyaltyProgram?.levels) {
          setLoyaltyLevels(loyaltyProgram.levels);
        }

        unsubscribe = promoSrv.listenPromoCodes(
          (promoCodesData) => {
            setPromoCodes(promoCodesData);
            setFilteredCodes(promoCodesData);
            setLoading(false);
          },
          (error: Error) => {
            console.error("Error fetching promo codes:", error);
            setError("Erreur lors du chargement des codes promo");
            setLoading(false);
          }
        );

      } catch (error) {
        console.error("Error loading data:", error);
        setError("Erreur lors du chargement des données");
        setLoading(false);
      }
    };

    loadData();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [foodTruckId]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filtered = promoCodes.filter(code =>
      code.code?.toLowerCase().includes(value.toLowerCase()) ||
      code.description?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCodes(filtered);
    setPage(0);
  };

  const handleRequestSort = (property: keyof PromoCode) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreatePromoCode = async () => {
    if (!services || !promoCode.code || !promoCode.discountValue || !promoCode.startDate) {
      return;
    }

    setSubmitting(true);
    try {
      const newPromoCode: Omit<PromoCode, 'id' | 'createdAt' | 'updatedAt' | 'usageCount'> = {
        ...promoCode,
        discountValue: Number(promoCode.discountValue),
        startDate: promoCode.startDate,
        endDate: promoCode.endDate || undefined,
        minOrderAmount: promoCode.minOrderAmount || undefined,
        maxUses: promoCode.maxUses || undefined,
        accessType: promoCode.accessType || 'public',
      };

      await services.promoSrv.createPromoCode(newPromoCode);
      setIsSidePanelOpen(false);
      setPromoCode({
        code: '',
        discountType: 'percentage',
        discountValue: '',
        startDate: null,
        endDate: null,
        minOrderAmount: null,
        maxUses: null,
        description: '',
      });
    } catch (error) {
      setError(`Erreur lors de la création du code promo: ${(error as Error).message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (codeId: string) => {
    if (!services || !window.confirm('Êtes-vous sûr de vouloir supprimer ce code promo ?')) {
      return;
    }

    try {
      await services.promoSrv.deletePromoCode(codeId);
    } catch (error) {
      setError(`Erreur lors de la suppression du code promo: ${(error as Error).message}`);
    }
  };

  const getPromoStatus = (promoCode: PromoCode): PromoStatus => {
    const now = new Date();
    const startDate = promoCode?.startDate?.toDate() || new Date();
    const endDate = promoCode?.endDate?.toDate();

    if (now < startDate) return 'scheduled';
    if (endDate && now > endDate) return 'expired';
    if (promoCode.maxUses && (promoCode.usageCount ?? 0) >= promoCode.maxUses) return 'expired';
    return 'active';
  };

  const sortedCodes = useMemo(() => {
    return [...filteredCodes].sort((a, b) => {
      const isAsc = order === 'asc';
      switch (orderBy) {
        case 'startDate':
        case 'endDate':
          const timeA = a[orderBy]?.toDate().getTime() || 0;
          const timeB = b[orderBy]?.toDate().getTime() || 0;
          return isAsc ? timeA - timeB : timeB - timeA;
        case 'discountValue':
          // Gestion spécifique pour les nombres
          const valueA = a[orderBy] || 0;
          const valueB = b[orderBy] || 0;
          return isAsc ? valueA - valueB : valueB - valueA;
        default:
          // Conversion explicite en string pour les autres cas
          const strA = String(a[orderBy] || '');
          const strB = String(b[orderBy] || '');
          return isAsc
            ? strA.localeCompare(strB)
            : strB.localeCompare(strA);
      }
    });
  }, [filteredCodes, order, orderBy]);

  const paginatedCodes = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedCodes.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedCodes, page, rowsPerPage]);

  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;


  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaTicketAlt className="mr-3 text-emerald-500" />
          Codes Promo
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={() => setIsSidePanelOpen(true)}
        >
          Nouveau code promo
        </Button>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Rechercher un code promo..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaSearch />
            </InputAdornment>
          ),
        }}
      />

      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'code'}
                    direction={orderBy === 'code' ? order : 'asc'}
                    onClick={() => handleRequestSort('code')}
                  >
                    Code
                  </TableSortLabel>
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'discountValue'}
                    direction={orderBy === 'discountValue' ? order : 'asc'}
                    onClick={() => handleRequestSort('discountValue')}
                  >
                    Réduction
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'startDate'}
                    direction={orderBy === 'startDate' ? order : 'asc'}
                    onClick={() => handleRequestSort('startDate')}
                  >
                    Période
                  </TableSortLabel>
                </TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Utilisations</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCodes.map((code) => {
                const status = getPromoStatus(code);
                return (
                  <TableRow key={code.id} hover>
                    <TableCell className="font-medium">{code.code}</TableCell>
                    <TableCell>{code.description}</TableCell>
                    <TableCell>
                      {code.discountType === 'percentage'
                        ? `${code.discountValue}%`
                        : `${code.discountValue.toFixed(2)}€`
                      }
                      {code.minOrderAmount && (
                        <Typography variant="caption" color="textSecondary" display="block">
                          Min: {code.minOrderAmount.toFixed(2)}€
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>Du {formatDate(code.startDate.toDate())}</div>
                      {code.endDate && (
                        <div>Au {formatDate(code.endDate.toDate())}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${STATUS_COLORS[status]}`}>
                        {status === 'active' && 'Actif'}
                        {status === 'expired' && 'Expiré'}
                        {status === 'scheduled' && 'Planifié'}
                      </span>
                    </TableCell>
                    <TableCell>
                      {code.usageCount || 0}
                      {code.maxUses && ` / ${code.maxUses}`}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="error"
                        onClick={() => handleDelete(code.id)}
                        size="small"
                      >
                        Supprimer
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {paginatedCodes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography color="textSecondary" sx={{ py: 3 }}>
                      {searchTerm ? "Aucun code promo ne correspond à votre recherche" : "Aucun code promo disponible"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={sortedCodes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
        />
      </Paper>

      <PromoCodeSidePanel
        open={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
        onSubmit={handleCreatePromoCode}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        loyaltyLevels={loyaltyLevels}
        loading={submitting}
      />
    </Box>
  );
};

export default PromoCodeManagement;