import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Unauthorized from './components/Auth/Unauthorized';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard/Dashboard';
import MenuEditor from './components/Menu/MenuEditor';
import Header from './components/Layouts/Header';
import Sidebar from './components/Layouts/Sidebar';
import './index.css';
import TruckInfoEditor from './components/Info/TruckInfoEditor';
import Customers from './components/Customers/Customers';
import Orders from './components/Orders/Orders';
import LoyaltyProgramEditor from './components/Loyalty/LoyaltyProgramEditor';
import Notifications from './components/Notifications/Notifications';
import MenuItemCategoryEditor from './components/Menu/MenuItemCategoryEditor';
import { FirebaseProvider } from './components/Auth/FirebaseProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import OrderTimeline from './components/Timeline/OrderTimeline';
import TruckHoursEditor from './components/Info/TruckHoursEditor';
import TruckSlotsEditor from './components/Info/TruckSlotsEditor';
import IngredientEditor from './components/Ingredient/IngredientEditor';
import PromoCodeManagement from './components/PromoCode/PromoCodeManagement';
import Loader from './components/Common/Loader';

const PrivateLayout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-y-auto p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

const PublicLayout = ({ children }) => {
  return (
    <div className="h-screen">
      {children}
    </div>
  );
};

const AdminRoute = ({ children }) => {
  const { currentUser, isAdmin, loading } = useAuth();

  if (loading) return <Loader/>;

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!isAdmin) {
    return <Navigate to="/unauthorized" />;
  }

  return <PrivateLayout>{children}</PrivateLayout>;
};

const PublicRoute = ({ children }) => {
  const { currentUser, isAdmin, loading } = useAuth();

  if (loading) return <Loader/>;

  if (currentUser && isAdmin) {
    return <Navigate to="/" />;
  }

  return <PublicLayout>{children}</PublicLayout>;
};

function AppContent() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/unauthorized" element={<PublicRoute><Unauthorized /></PublicRoute>} />
        <Route path="/" element={<AdminRoute><Dashboard /></AdminRoute>} />
        <Route path="/orders" element={<AdminRoute><Orders /></AdminRoute>} />
        <Route path="/ordersTimeline" element={<AdminRoute><OrderTimeline /></AdminRoute>} />
        <Route path="/menu/categories" element={<AdminRoute><MenuItemCategoryEditor /></AdminRoute>} />
        <Route path="/menu/products" element={<AdminRoute><MenuEditor /></AdminRoute>} />
        <Route path="/menu/ingredients" element={<AdminRoute><IngredientEditor /></AdminRoute>} />
        <Route path="/truck/info" element={<AdminRoute><TruckInfoEditor /></AdminRoute>} />
        <Route path="/truck/hours" element={<AdminRoute><TruckHoursEditor /></AdminRoute>} />
        <Route path="/truck/slots" element={<AdminRoute><TruckSlotsEditor /></AdminRoute>} />
        <Route path="/loyalty" element={<AdminRoute><LoyaltyProgramEditor /></AdminRoute>} />
        <Route path="/customers" element={<AdminRoute><Customers /></AdminRoute>} />
        <Route path="/promo-codes" element={<AdminRoute><PromoCodeManagement /></AdminRoute>} />
        <Route path="/notifications" element={<AdminRoute><Notifications /></AdminRoute>} />
      </Routes>
    </Router>
  );
}

function MyApp() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">

      <AuthProvider>
        <FirebaseProvider>
          <AppContent />
        </FirebaseProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default MyApp;