import { createContext, useContext } from 'react';
import { useAuth } from '../../contexts/AuthContext';

// Création du contexte
const FirebaseContext = createContext();

// Hook personnalisé pour utiliser le contexte
export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}

// Provider du contexte
export function FirebaseProvider({ children }) {
  const { currentUser, adminTruckId, isAdmin, loading: authLoading } = useAuth();

  // Collection builder - crée des références aux collections avec le bon foodTruckId
  const getCollection = (basePath) => {
    if (!adminTruckId) {
      throw new Error('Food Truck ID non disponible. Vérifiez les droits d\'accès.');
    }
    // eslint-disable-next-line no-template-curly-in-string
    return basePath.replace('${foodTruckId}', adminTruckId);
  };

  const value = {
    foodTruckId: adminTruckId,
    loading: authLoading,
    getCollection,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}

// Hook utilitaire pour obtenir directement une référence de collection
export function useCollection(basePath) {
  const { getCollection } = useFirebase();
  try {
    return getCollection(basePath);
  } catch (error) {
    console.error('Erreur lors de l\'accès à la collection:', error);
    throw error;
  }
}

// Hook utilitaire pour vérifier si l'utilisateur a accès à un food truck spécifique
export function useHasTruckAccess(requiredTruckId) {
  const { foodTruckId } = useFirebase();
  return foodTruckId === requiredTruckId;
}