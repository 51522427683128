import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    InputAdornment,
    CircularProgress,
    Collapse,
} from '@mui/material';
import {
    FaTimes,
    FaSave
} from 'react-icons/fa';
import AllergenSelect from '../Menu/Allergens';

export const INGREDIENT_TYPES = {
    BASE: 'base',
    STANDARD: 'standard'
};

function IngredientForm({ ingredient, onSave, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        type: INGREDIENT_TYPES.STANDARD,
        canBeExtra: false,   
        price: '',          
        discountPrice: '',  
        minQuantity: '0',
        maxQuantity: '1',
        isOutOfStock: false,
        allergens: [],
    });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (ingredient) {
            setFormData({
                name: ingredient.name || '',
                type: ingredient.type || INGREDIENT_TYPES.STANDARD,
                canBeExtra: Boolean(ingredient.price),  // Si un prix existe, c'est un supplément
                price: ingredient.price?.toString() || '',
                discountPrice: ingredient.discountPrice?.toString() || '',
                minQuantity: ingredient.minQuantity?.toString() || '0',
                maxQuantity: ingredient.maxQuantity?.toString() || '1',
                allergens: ingredient.allergens || [],
                isOutOfStock: ingredient.isOutOfStock || false,
            });
        }
    }, [ingredient]);

    const handleChange = (e) => {
        const { name, value, type: inputType, checked } = e.target;
        
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: inputType === 'checkbox' ? checked : value,
            };

            // Réinitialiser les données de supplément si ce n'est plus un supplément
            if (name === 'canBeExtra' && !checked) {
                newData.price = '';
                newData.discountPrice = '';
                newData.minQuantity = '0';
                newData.maxQuantity = '1';
            }

            // Réinitialiser les données de supplément si devient type BASE
            if (name === 'type' && value === INGREDIENT_TYPES.BASE) {
                newData.canBeExtra = false;
                newData.price = '';
                newData.discountPrice = '';
                newData.minQuantity = '0';
                newData.maxQuantity = '1';
            }

            return newData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            await onSave({
                ...formData,
                price: formData.canBeExtra ? parseFloat(formData.price) : null,
                discountPrice: formData.canBeExtra && formData.discountPrice ? parseFloat(formData.discountPrice) : null,
                minQuantity: formData.canBeExtra ? parseInt(formData.minQuantity) : null,
                maxQuantity: formData.canBeExtra ? parseInt(formData.maxQuantity) : null,
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleAllergenChange = (newAllergens) => {
        setFormData(prevData => ({
            ...prevData,
            allergens: newAllergens,
        }));
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5">
                    {ingredient ? "Modifier l'ingrédient" : 'Nouvel ingrédient'}
                </Typography>
                <IconButton onClick={onClose} size="small" disabled={isSaving}>
                    <FaTimes />
                </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1, overflow: 'auto', pr: 1 }}>
                <TextField
                    fullWidth
                    label="Nom"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                    required
                    disabled={isSaving}
                />

                <FormControl fullWidth margin="normal" disabled={isSaving}>
                    <InputLabel>Type d'ingrédient</InputLabel>
                    <Select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        label="Type d'ingrédient"
                    >
                        <MenuItem value={INGREDIENT_TYPES.STANDARD}>
                            Ingrédient standard
                        </MenuItem>
                        <MenuItem value={INGREDIENT_TYPES.BASE}>
                            Ingrédient de base (interchangeable)
                        </MenuItem>
                    </Select>
                    <FormHelperText>
                        {formData.type === INGREDIENT_TYPES.BASE ? 
                            "Peut être substitué par d'autres ingrédients de base" : 
                            "Peut être retiré du produit"}
                    </FormHelperText>
                </FormControl>

                {formData.type === INGREDIENT_TYPES.STANDARD && (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.canBeExtra}
                                    onChange={handleChange}
                                    name="canBeExtra"
                                    disabled={isSaving}
                                />
                            }
                            label="Peut être ajouté en supplément"
                            sx={{ mt: 2 }}
                        />

                        <Collapse in={formData.canBeExtra}>
                            <Box sx={{ mt: 2 }}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Prix"
                                        name="price"
                                        type="number"
                                        value={formData.price}
                                        onChange={handleChange}
                                        required={formData.canBeExtra}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                        disabled={isSaving}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Prix barré"
                                        name="discountPrice"
                                        type="number"
                                        value={formData.discountPrice}
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                        disabled={isSaving}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Quantité minimum"
                                        name="minQuantity"
                                        type="number"
                                        value={formData.minQuantity}
                                        onChange={handleChange}
                                        inputProps={{ min: 0 }}
                                        required={formData.canBeExtra}
                                        disabled={isSaving}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Quantité maximum"
                                        name="maxQuantity"
                                        type="number"
                                        value={formData.maxQuantity}
                                        onChange={handleChange}
                                        inputProps={{ min: parseInt(formData.minQuantity) || 0 }}
                                        required={formData.canBeExtra}
                                        disabled={isSaving}
                                    />
                                </Box>
                            </Box>
                        </Collapse>
                    </>
                )}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.isOutOfStock}
                            onChange={handleChange}
                            name="isOutOfStock"
                            disabled={isSaving}
                        />
                    }
                    label="Indisponible"
                    sx={{ mt: 2 }}
                />

                <Box sx={{ mt: 2 }}>
                    <AllergenSelect
                        value={formData.allergens}
                        onChange={handleAllergenChange}
                        disabled={isSaving}
                    />
                </Box>
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                pt: 3,
                mt: 'auto',
                borderTop: '1px solid',
                borderColor: 'divider'
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSaving}
                    startIcon={isSaving ? <CircularProgress size={20} /> : <FaSave />}
                >
                    {isSaving ? 'Enregistrement...' : 'Enregistrer'}
                </Button>
            </Box>
        </Box>
    );
}

export default IngredientForm;