import React, { useState, useEffect } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { foodTruckService } from '../../services/foodTruckService';
import {
  Box,
  Typography,
  Button,
  Chip,
  Drawer,
  Alert,
} from '@mui/material';
import {
  FaTruck,
  FaEdit,
  FaPhone,
  FaGlobe,
  FaInstagram,
  FaFacebook,
  FaTwitter,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import TruckInfoEditPanel from './TruckInfoEditPanel';
import SectionTitle from '../Common/Title';

function FoodTruckEditor() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [foodTruck, setFoodTruck] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    const initializeServices = () => {
      try {
        const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
        setServices({ foodTruckSrv });
        return { foodTruckSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadFoodTruck = async () => {
      try {
        setLoading(true);
        const { foodTruckSrv } = initializeServices();
        const data = await foodTruckSrv.getFoodTruck();

        setFoodTruck(data);
      } catch (error) {
        console.error("Error loading food truck:", error);
        setError("Erreur lors du chargement des données du food truck");
        toast.error("Erreur lors du chargement des données du food truck");
      } finally {
        setLoading(false);
      }
    };

    loadFoodTruck();
  }, [foodTruckId, contextLoading]);


  const formatDescription = (text) => {
    if (!text) return '';
    // Gère les retours à la ligne
    const withLineBreaks = text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {i > 0 && <br />}
        {/* Gère le texte en gras entre ** */}
        {line.split(/(\*\*.*?\*\*)/).map((part, j) => {
          if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={j}>{part.slice(2, -2)}</strong>;
          }
          return part;
        })}
      </React.Fragment>
    ));
    return withLineBreaks;
  };


  const getSocialIcon = (platform) => {
    switch (platform) {
      case 'instagram': return <FaInstagram />;
      case 'facebook': return <FaFacebook />;
      case 'twitter': return <FaTwitter />;
      case 'web': return <FaGlobe />;
      default: return null;
    }
  };

  const handleUpdate = async (updatedData) => {
    if (!services?.foodTruckSrv) return;

    try {
      setLoading(true);
      await services.foodTruckSrv.updateFoodTruck(updatedData);
      setFoodTruck(updatedData);
      setIsEditing(false);
      toast.success("Food truck mis à jour avec succès !");
    } catch (error) {
      console.error("Error updating food truck:", error);
      toast.error("Erreur lors de la mise à jour du food truck");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!foodTruck) return null;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaTruck className="mr-3 text-emerald-500" />
          Informations
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaEdit />}
          onClick={() => setIsEditing(true)}
        >
          Modifier
        </Button>
      </Box>

      {/* Content */}
      <Box sx={{ mt: 4 }}>
        {/* Contact et réseaux sociaux */}
        <SectionTitle>Contact</SectionTitle>
        <Box sx={{
          ml: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
            <Chip
              icon={<FaPhone />}
              label={foodTruck.phoneNumber}
              variant="outlined"
              color="primary"
              sx={{ height: 32 }}
            />
            {foodTruck.socialMedia?.map((social, index) => (
              <Chip
                key={index}
                icon={getSocialIcon(social.platform)}
                label={social.platform.charAt(0).toUpperCase() + social.platform.slice(1)}
                component="a"
                href={social.url}
                target="_blank"
                clickable
                variant="outlined"
                color="primary"
                sx={{ height: 32 }}
              />
            ))}
          </Box>
        </Box>

        {/* Description */}
        <SectionTitle>Description</SectionTitle>
        <Alert severity="info" sx={{ mb: 2 }}>
          La description sera directement affichée dans l'application mobile.
        </Alert>
        <Box sx={{
          ml: 2,
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}>
          <Typography variant="body1" component="div" sx={{
            lineHeight: 1.7,
            '& strong': {
              color: 'primary.main',
              fontWeight: 600
            }
          }}>
            {formatDescription(foodTruck.description)}
          </Typography>
        </Box>
      </Box>

      {/* Edit Panel */}
      <Drawer
        anchor="right"
        open={isEditing}
        onClose={() => setIsEditing(false)}
        PaperProps={{
          sx: { width: 500 }
        }}
      >
        <TruckInfoEditPanel
          foodTruck={foodTruck}
          onClose={() => setIsEditing(false)}
          onUpdate={handleUpdate}
        />
      </Drawer>
    </Box>
  );
}

export default FoodTruckEditor;