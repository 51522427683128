import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Alert,
    Tooltip,
} from '@mui/material';
import { FaPlus, FaMinus, FaTimes, FaExclamationTriangle, FaSave } from 'react-icons/fa';
import { formatCurrency } from '../../utils/helpers';
const SupplementsSidePanel = ({ 
    item, 
    onClose, 
    onSave, 
    availableSupplements 
}) => {
    const [selectedSupplements, setSelectedSupplements] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Trier et préparer tous les suppléments disponibles
    const sortedSupplements = useMemo(() => {
        // On veut afficher tous les suppléments disponibles
        return availableSupplements.sort((a, b) => {
            // Si on a des suppléments sélectionnés, on les met en premier
            const aIsSelected = item?.supplements?.some(s => s.id === a.id);
            const bIsSelected = item?.supplements?.some(s => s.id === b.id);
            if (aIsSelected && !bIsSelected) return -1;
            if (!aIsSelected && bIsSelected) return 1;
            // Sinon on trie par ordre alphabétique
            return a.name.localeCompare(b.name);
        });
    }, [item?.supplements, availableSupplements]);

    useEffect(() => {
        // Initialiser les suppléments avec les quantités existantes
        const initialSupplements = availableSupplements.map(supp => {
            const existingSupplement = item?.supplements?.find(s => s.id === supp.id);
            return {
                ...supp,
                quantity: existingSupplement?.quantity || 0,
            };
        });
        setSelectedSupplements(initialSupplements);
    }, [item, availableSupplements]);

    const totalSupplementsPrice = useMemo(() => {
        return selectedSupplements.reduce((total, supp) => {
            const price = supp.discountPrice ?? supp.price ?? 0;
            return total + (price * (supp.quantity || 0));
        }, 0);
    }, [selectedSupplements]);

    const validateQuantities = (supplements) => {
        const newErrors = [];
        supplements.forEach(supp => {
            if (supp.minQuantity && supp.quantity < supp.minQuantity) {
                newErrors.push(
                    `${supp.name} requiert au minimum ${supp.minQuantity} unité(s)`
                );
            }
            if (supp.maxQuantity && supp.quantity > supp.maxQuantity) {
                newErrors.push(
                    `${supp.name} est limité à ${supp.maxQuantity} unité(s)`
                );
            }
        });
        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleQuantityChange = (supplementId, delta) => {
        setSelectedSupplements(prev => {
            const existing = prev.find(s => s.id === supplementId);
            const supplement = availableSupplements.find(s => s.id === supplementId);
            
            if (!existing && delta > 0) {
                if (supplement.isOutOfStock) return prev;
                
                const newSupplements = [
                    ...prev,
                    {
                        ...supplement,
                        quantity: 1,
                        order: prev.length
                    }
                ];
                validateQuantities(newSupplements);
                return newSupplements;
            }

            if (existing) {
                const newQuantity = Math.max(0, existing.quantity + delta);
                if (supplement.maxQuantity && newQuantity > supplement.maxQuantity) {
                    return prev;
                }
                
                if (newQuantity === 0) {
                    const newSupplements = prev.filter(s => s.id !== supplementId)
                        .map((s, index) => ({ ...s, order: index }));
                    validateQuantities(newSupplements);
                    return newSupplements;
                }

                const newSupplements = prev.map(s =>
                    s.id === supplementId ? { ...s, quantity: newQuantity } : s
                );
                validateQuantities(newSupplements);
                return newSupplements;
            }

            return prev;
        });
    };

    const handleSave = async () => {
        if (validateQuantities(selectedSupplements)) {
            setIsSubmitting(true);
            try {
                const supplementsToSave = selectedSupplements.map((supp, index) => ({
                    id: supp.id,
                    quantity: supp.quantity,
                    order: index
                }));
                await onSave(item.id, supplementsToSave);
                onClose();
            } catch (error) {
                console.error('Erreur lors de la sauvegarde des suppléments:', error);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            bgcolor: 'background.default',
        }}>
            {/* Header */}
            <Box sx={{
                p: 3,
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box>
                    <Typography variant="h5" component="h2" fontWeight="bold" color="primary.main">
                        Suppléments
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        {item?.name}
                    </Typography>
                </Box>
                <IconButton onClick={onClose} edge="end" aria-label="close">
                    <FaTimes />
                </IconButton>
            </Box>

            {/* Main Content */}
            <Box sx={{ 
                flexGrow: 1, 
                overflowY: 'auto',
                p: 3
            }}>
                {errors.length > 0 && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {errors.map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </Alert>
                )}

                <TableContainer component={Paper} elevation={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Supplément</TableCell>
                                <TableCell align="right">Prix unitaire</TableCell>
                                <TableCell align="right">Quantité</TableCell>
                                <TableCell align="right">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSupplements.map(supplement => {
                                const selected = selectedSupplements.find(s => s.id === supplement.id);
                                const currentPrice = supplement.discountPrice ?? supplement.price;
                                const totalPrice = (selected?.quantity || 0) * currentPrice;

                                return (
                                    <TableRow 
                                        key={supplement.id}
                                        sx={supplement.isOutOfStock ? { 
                                            backgroundColor: 'action.disabledBackground' 
                                        } : {}}
                                    >
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {supplement.name}
                                                {supplement.isOutOfStock && (
                                                    <Tooltip title="Indisponible">
                                                        <FaExclamationTriangle 
                                                            style={{ marginLeft: 8, color: 'warning.main' }} 
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            {supplement.discountPrice ? (
                                                <Box>
                                                    <Typography 
                                                        component="span" 
                                                        sx={{ 
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                            mr: 1,
                                                            fontSize: '0.875rem'
                                                        }}
                                                    >
                                                        {formatCurrency(supplement.price)}
                                                    </Typography>
                                                    <Typography 
                                                        component="span" 
                                                        color="error"
                                                        fontWeight="medium"
                                                    >
                                                        {formatCurrency(supplement.discountPrice)}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                formatCurrency(supplement.price)
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleQuantityChange(supplement.id, -1)}
                                                    disabled={
                                                        !selected || 
                                                        (selected.quantity <= (supplement.minQuantity || 0))
                                                    }
                                                >
                                                    <FaMinus />
                                                </IconButton>
                                                <Typography 
                                                    component="span" 
                                                    sx={{ 
                                                        mx: 2,
                                                        minWidth: '30px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {selected?.quantity || 0}
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleQuantityChange(supplement.id, 1)}
                                                    disabled={
                                                        supplement.isOutOfStock || 
                                                        (selected?.quantity >= (supplement.maxQuantity || Infinity))
                                                    }
                                                >
                                                    <FaPlus />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatCurrency(totalPrice)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell colSpan={3} align="right">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Total des suppléments
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {formatCurrency(totalSupplementsPrice)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Footer */}
            <Box sx={{
                p: 2,
                borderTop: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                bgcolor: 'background.paper'
            }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="inherit"
                    startIcon={<FaTimes />}
                    disabled={isSubmitting}
                >
                    Annuler
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    startIcon={<FaSave />}
                    disabled={errors.length > 0 || isSubmitting}
                >
                    {isSubmitting ? 'Enregistrement...' : 'Valider'}
                </Button>
            </Box>
        </Box>
    );
};

export default SupplementsSidePanel;