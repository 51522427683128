import React, { useState, useEffect } from 'react';
import { useFirebase } from '../Auth/FirebaseProvider';
import { foodTruckService } from '../../services/foodTruckService';
import { categoriesService } from '../../services/categoriesService';
import {
  Box,
  Typography,
  Paper,
  Grid2,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Drawer
} from '@mui/material';
import {
  FaClock,
  FaUsers,
  FaChartPie,
  FaEdit,
  FaCalendarAlt,
} from 'react-icons/fa';
import SectionTitle from '../Common/Title';
import TruckSlotsEditPanel from './TruckSlotsEditPanel';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';

const ComplexitySummary = ({ categories }) => {
  const hasComplexityPoints = categories.some(cat => cat.complexityPoints > 0);

  if (!hasComplexityPoints) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        Aucune catégorie n'a de points de complexité définis.
      </Alert>
    );
  }


  return (
    <Box sx={{ mt: 2 }}>
    </Box>
  );
};

const StatCard = ({ icon: Icon, title, value, unit }) => (
  <Paper sx={{ p: 3, height: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
      <Icon size={20} color="#1976d2" />
      <Typography color="text.secondary" variant="body2">
        {title}
      </Typography>
    </Box>
    <Typography variant="h4" component="div" fontWeight="medium">
      {value}
      <Typography component="span" variant="body1" color="text.secondary" sx={{ ml: 1 }}>
        {unit}
      </Typography>
    </Typography>
  </Paper>
);

// Composant pour la jauge d'impact
const ImpactGauge = ({ points, maxPoints }) => {
  if (points === 0) {
    return (
      <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
        Aucun impact
      </Typography>
    );
  }

  const percentage = (points / maxPoints) * 100;
  const isOverflow = percentage > 100;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            height: '6px',
            bgcolor: 'grey.200',
            borderRadius: '3px',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              width: `${Math.min(percentage, 100)}%`,
              height: '100%',
              bgcolor: isOverflow ? 'error.main' : 'primary.main',
              transition: 'width 0.3s ease-in-out'
            }}
          />
        </Box>
      </Box>
      <Chip
        label={`${percentage.toFixed(0)}%`}
        size="small"
        color={isOverflow ? "error" : "default"}
        variant={isOverflow ? "filled" : "outlined"}
      />
    </Box>
  );
};

function TruckSlotsPanel() {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [foodTruck, setFoodTruck] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    const initializeServices = () => {
      const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
      const categorySrv = categoriesService.getInstance(foodTruckId);
      setServices({ foodTruckSrv, categorySrv });
      return { foodTruckSrv, categorySrv };
    };

    const loadData = async () => {
      try {
        setLoading(true);
        const { foodTruckSrv, categorySrv } = initializeServices();

        const [truckData, categoriesData] = await Promise.all([
          foodTruckSrv.getFoodTruck(),
          categorySrv.getCategories()
        ]);

        setFoodTruck(truckData);
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error loading data:", error);
        setError("Erreur lors du chargement des données");
        toast.error("Erreur lors du chargement des données");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [foodTruckId, contextLoading]);

  const handleUpdate = async (updatedData) => {
    if (!services?.foodTruckSrv) return;

    try {
      await services.foodTruckSrv.updateFoodTruck(updatedData);
      setFoodTruck(updatedData);
      toast.success("Configuration des créneaux mise à jour");
      setIsEditPanelOpen(false);
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };


  if (loading) return <Loader />;
  if (error) return <Typography color="error">{error}</Typography>;

  const timeSlotSettings = foodTruck?.timeSlotSettings || {
    duration: 15,
    maxOrdersPerSlot: 4,
    maxPointsPerSlot: 20
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaCalendarAlt className="mr-3 text-emerald-500" />
          Créneaux de commande
        </Typography>
        <Button
          variant="contained"
          startIcon={<FaEdit />}
          onClick={() => setIsEditPanelOpen(true)}
        >
          Modifier les paramètres
        </Button>
      </Box>

      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        <Grid2 item xs={12} md={4}>
          <StatCard
            icon={FaClock}
            title="Durée d'un créneau"
            value={timeSlotSettings.duration}
            unit="minutes"
          />
        </Grid2>
        <Grid2 item xs={12} md={4}>
          <StatCard
            icon={FaUsers}
            title="Commandes simultanées"
            value={timeSlotSettings.maxOrdersPerSlot}
            unit="max"
          />
        </Grid2>
        <Grid2 item xs={12} md={4}>
          <StatCard
            icon={FaChartPie}
            title="Points de complexité"
            value={timeSlotSettings.maxPointsPerSlot}
            unit="max"
          />
        </Grid2>
      </Grid2>

      <SectionTitle>Complexité par catégorie</SectionTitle>
      <Alert severity="info" sx={{ mb: 2 }}>
        La configuration des points de complexité par catégorie se fait dans la gestion des catégories.
      </Alert>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Catégorie</TableCell>
              <TableCell align="center" width={150}>Points</TableCell>
              <TableCell>Impact sur le créneau</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>
                  <Typography fontWeight="medium">
                    {category.name}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={`${category.complexityPoints || 0} pts`}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <ImpactGauge
                    points={category.complexityPoints || 0}
                    maxPoints={timeSlotSettings.maxPointsPerSlot}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ComplexitySummary
        categories={categories}
      />

      <Drawer
        anchor="right"
        open={isEditPanelOpen}
        onClose={() => setIsEditPanelOpen(false)}
        PaperProps={{
          sx: { width: 500 }
        }}
      >
        <TruckSlotsEditPanel
          foodTruck={foodTruck}
          onClose={() => setIsEditPanelOpen(false)}
          onUpdate={handleUpdate}
        />
      </Drawer>

    </Box>
  );
}

export default TruckSlotsPanel;