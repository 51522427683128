export const getItemPrice = (item) => {
    if (!item.prices || item.prices.length === 0) return 'N/A';
    const defaultPrice = item.prices.find(p => p.type === 'default') || item.prices[0];
    return defaultPrice.discountPriceTTC ?? defaultPrice.priceTTC;
};

export const getDisplayPrice = (item) => {
    if (!item.prices || item.prices.length === 0) return 'N/A';
    const defaultPrice = item.prices.find(p => p.type === 'default') || item.prices[0];
    if (defaultPrice.discountPriceTTC !== null) {
        return (
            <span>
                <span style={{ textDecoration: 'line-through', color: 'gray', marginRight: '5px' }}>
                    {defaultPrice.priceTTC.toFixed(2)} €
                </span>
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                    {defaultPrice.discountPriceTTC.toFixed(2)} €
                </span>
            </span>
        );
    }
    return `${defaultPrice.priceTTC.toFixed(2)} €`;
};
