import { Button, IconButton, InputAdornment, Paper, TableBody, TableCell, TableContainer, Box, Table, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { FaPlus, FaTrash } from "react-icons/fa";

function SizeTable({ sizes, onChange, onAdd, onDelete }) {
    const defaultClassicSize = {
        id: 'classic',
        name: 'Classique',
        extraPrice: 0,
        isFixed: true
    };

    const allSizes = [
        defaultClassicSize,
        ...sizes.filter(size => size.id !== 'classic')
    ];

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nom de la taille</TableCell>
                        <TableCell>Supplément de prix TTC</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allSizes.map((size, index) => (
                        <TableRow
                            key={size.id}
                            sx={size.isFixed ? { backgroundColor: 'action.hover' } : {}}
                        >
                            <TableCell>
                                {size.isFixed ? (
                                    <Typography>{size.name}</Typography>
                                ) : (
                                    <TextField
                                        value={size.name}
                                        onChange={(e) => onChange(index - 1, 'name', e.target.value)}
                                        placeholder="ex: Grande, Maxi"
                                        fullWidth
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {size.isFixed ? (
                                    <Typography>Inclus</Typography>
                                ) : (
                                    <TextField
                                        type="number"
                                        value={size.extraPrice}
                                        onChange={(e) => onChange(index - 1, 'extraPrice', parseFloat(e.target.value) || 0)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        fullWidth
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {!size.isFixed && (
                                    <IconButton
                                        onClick={() => onDelete(index - 1)}
                                    >
                                        <FaTrash />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, pb: 1 }}>
                <Typography variant="body2" color="text.secondary">
                    Le supplément de prix s'ajoute au prix de la taille classique
                </Typography>
                <Button
                    startIcon={<FaPlus />}
                    onClick={() => onAdd({
                        id: `size-${Date.now()}`,
                        name: '',
                        extraPrice: 0
                    })}
                >
                    Ajouter une taille
                </Button>
            </Box>
        </TableContainer>
    );
}
export default SizeTable;