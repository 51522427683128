import React, { useState, useEffect } from 'react';
import { allergensService } from '../../services/allergenService';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    Typography,
    Box,
    Chip,
    CircularProgress,
    TextField,
    ListSubheader,
    InputAdornment,
    styled
} from '@mui/material';
import { FaSearch, FaChevronDown, FaTimes } from 'react-icons/fa';
import { useFirebase } from '../Auth/FirebaseProvider';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
        },
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.contrastText,
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiCheckbox-root': {
        padding: theme.spacing(0, 1, 0, 0),
    },
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: theme.spacing(1),
}));


function AllergenSelect({ value, onChange }) {
    const { foodTruckId, loading } = useFirebase();
    const [allergens, setAllergens] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayedAllergens, setDisplayedAllergens] = useState([]);
    const [visibleItemCount, setVisibleItemCount] = useState(20);

    useEffect(() => {
        async function fetchAllergens() {
            if (!foodTruckId) return;

            try {
                const allAllergens = await allergensService.getAllergens(foodTruckId);
                setAllergens(allAllergens);
            } catch (error) {
                setError(error);
                console.error('Erreur lors de la récupération des allergènes:', error);
            }
        }

        fetchAllergens();
    }, [foodTruckId]);

    useEffect(() => {
        const filtered = allergens.filter(allergen =>
            allergen.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setDisplayedAllergens(filtered.slice(0, visibleItemCount));
    }, [allergens, searchTerm, visibleItemCount]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setVisibleItemCount(20);
    };

    const handleScroll = (event) => {
        const { target } = event;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setVisibleItemCount(prev => prev + 20);
        }
    };

    const handleDelete = (allergenToDelete) => () => {
        const newValue = value.filter((allergen) => allergen !== allergenToDelete);
        onChange(newValue);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Erreur de chargement des allergènes</Typography>;

    return (
        <StyledFormControl fullWidth margin="normal">
            <InputLabel id="allergen-select-label">Allergènes</InputLabel>
            <Select
                labelId="allergen-select-label"
                multiple
                label="Allergènes"
                name="allergens"
                value={value || []}
                onChange={handleChange}
                onOpen={() => setVisibleItemCount(20)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                            const allergen = allergens.find(a => a.id === value);
                            return (
                                <StyledChip
                                    key={value}
                                    label={allergen ? allergen.name : value}
                                    onDelete={handleDelete(value)}
                                    deleteIcon={
                                        <Box
                                            onClick={handleDelete(value)}
                                            onMouseDown={(event) => event.stopPropagation()}
                                        >
                                            <FaTimes />
                                        </Box>
                                    }
                                    onClick={(event) => event.stopPropagation()}
                                />
                            );
                        })}
                    </Box>
                )}
                IconComponent={(props) => <FaChevronDown {...props} style={{ marginRight: 10 }} />}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                        },
                    },
                    onScroll: handleScroll,
                }}
            >
                <StyledListSubheader>
                    <TextField
                        size="small"
                        autoFocus
                        placeholder="Rechercher un allergène"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearch}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </StyledListSubheader>
                {displayedAllergens.map((allergen) => (
                    <StyledMenuItem key={allergen.id} value={allergen.id}>
                        <Checkbox checked={(value || []).indexOf(allergen.id) > -1} />
                        <Typography>{allergen.name}</Typography>
                    </StyledMenuItem>
                ))}
                {displayedAllergens.length < allergens.filter(a => a.name.toLowerCase().includes(searchTerm.toLowerCase())).length && (
                    <MenuItem disabled>
                        <Typography>Faites défiler pour plus d'allergènes...</Typography>
                    </MenuItem>
                )}
            </Select>
        </StyledFormControl>
    );
}

export default AllergenSelect;