import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    TextField,
    Autocomplete,
    CircularProgress,
    Typography,
    Box,
    Avatar,
    Chip
} from '@mui/material';
import { useFirebase } from '../Auth/FirebaseProvider';
import { clientService } from '../../services/clientService';
import { FaSearch, FaUserPlus, FaTimes } from 'react-icons/fa';
import { debounce } from 'lodash';
import Loader from '../Common/Loader';

const ClientSearchOrGuestName = ({ onSelectClient }) => {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientServiceInstance, setClientServiceInstance] = useState(null);

    // Initialisation du service client
    useEffect(() => {
        if (!foodTruckId || contextLoading) return;

        try {
            const service = clientService.getInstance(foodTruckId);
            setClientServiceInstance(service);
            setError(null);
        } catch (error) {
            console.error("Error initializing client service:", error);
            setError("Erreur d'initialisation du service");
            setClientServiceInstance(null);
        }
    }, [foodTruckId, contextLoading]);

    // Fonction de recherche de base (non debouncée)
    const searchClients = useCallback(async (searchValue, service, setOptionsCallback, setLoadingCallback, setErrorCallback) => {
        if (!service || !searchValue || searchValue.length < 2) {
            setOptionsCallback([]);
            setLoadingCallback(false);
            return;
        }

        try {
            const results = await service.searchClients(searchValue);
            setOptionsCallback(results || []);
        } catch (error) {
            console.error("Erreur lors de la recherche de clients:", error);
            setErrorCallback("Erreur lors de la recherche");
            setOptionsCallback([]);
        } finally {
            setLoadingCallback(false);
        }
    }, []); // Cette fonction est stable car elle ne dépend d'aucun état

    // Création de la fonction debouncée qui utilise la fonction de recherche
    const debouncedSearch = useMemo(
        () => debounce(
            (searchValue, service) => searchClients(searchValue, service, setOptions, setLoading, setError),
            300
        ),
        [searchClients] // Dépend uniquement de la fonction de recherche stable
    );

    // Effet pour déclencher la recherche quand l'input change
    useEffect(() => {
        if (!inputValue) {
            setOptions([]);
            return;
        }

        setLoading(true);
        setError(null);
        debouncedSearch(inputValue, clientServiceInstance);

        return () => {
            debouncedSearch.cancel();
        };
    }, [inputValue, clientServiceInstance, debouncedSearch]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleSelectClient = (event, value) => {
        if (value && typeof value === 'object') {
            setSelectedClient(value);
            onSelectClient(value);
        }
    };

    const handleClearSelection = () => {
        setSelectedClient(null);
        onSelectClient(null);
        setInputValue('');
        setError(null);
        setOptions([]);
    };

    const getOptionLabel = (option) => {
        if (typeof option === 'string') return option;
        return option.name || option.email || '';
    };

    if (contextLoading) return <Loader />;
    if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{ mb: 4 }}>
            {!selectedClient ? (
                <Autocomplete
                    options={options}
                    getOptionLabel={getOptionLabel}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onChange={handleSelectClient}
                    loading={loading}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Rechercher un client"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <Box sx={{ color: 'action.active', mr: 1, display: 'flex', alignItems: 'center' }}>
                                        <FaSearch />
                                    </Box>
                                ),
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>
                                    {(option.name || option.email || '').charAt(0).toUpperCase()}
                                </Avatar>
                                <Box>
                                    <Typography variant="body1">{option.name || option.email}</Typography>
                                    {option.email && option.name && (
                                        <Typography variant="body2" color="text.secondary">
                                            {option.email}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </li>
                    )}
                    noOptionsText={
                        loading ? (
                            <Typography>Recherche en cours...</Typography>
                        ) : inputValue.length < 2 ? (
                            <Typography>Entrez au moins 2 caractères...</Typography>
                        ) : (
                            <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                                <FaUserPlus style={{ marginRight: '8px' }} />
                                <Typography>Aucun client trouvé. Cliquez pour en créer un nouveau.</Typography>
                            </Box>
                        )
                    }
                />
            ) : (
                <Chip
                    avatar={<Avatar>{(selectedClient.name || selectedClient.email || '').charAt(0).toUpperCase()}</Avatar>}
                    label={`${selectedClient.name || ''} (${selectedClient.email})`}
                    onDelete={handleClearSelection}
                    color="primary"
                    variant="outlined"
                    deleteIcon={<FaTimes />}
                />
            )}
        </Box>
    );
};

export default ClientSearchOrGuestName;