import { db } from './firebase';
import { 
  collection, 
  getDocs, 
  doc, 
  setDoc, 
  writeBatch, 
  onSnapshot, 
  deleteDoc 
} from 'firebase/firestore';

export class MenuService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.menuItemsPath = `foodTrucks/${foodTruckId}/menuItems`;
  }

  /**
   * Écoute les changements du menu en temps réel
   * @param {Function} onUpdate - Callback appelé lors des mises à jour
   * @param {Function} onError - Callback appelé en cas d'erreur
   * @returns {Function} Fonction pour arrêter l'écoute
   */
  listenMenu(onUpdate, onError) {
    try {
      return onSnapshot(
        collection(db, this.menuItemsPath),
        (snapshot) => {
          const menuData = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(item => !item.isDeleted);
          onUpdate(menuData);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up menu listener:', error);
      throw error;
    }
  }

  /**
   * Récupère tous les éléments du menu
   * @returns {Promise<Array>} Liste des éléments du menu
   */
  async getMenu() {
    try {
      const menuSnapshot = await getDocs(collection(db, this.menuItemsPath));
      return menuSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(item => !item.isDeleted);
    } catch (error) {
      console.error('Error fetching menu:', error);
      throw error;
    }
  }

  /**
   * Met à jour plusieurs éléments du menu en une seule opération
   * @param {Array} menuItems - Liste des éléments à mettre à jour
   */
  async updateMenu(menuItems) {
    const batch = writeBatch(db);

    try {
      menuItems.forEach((item) => {
        const ref = doc(db, this.menuItemsPath, item.id);
        batch.set(ref, this.sanitizeMenuItem(item));
      });
      
      await batch.commit();
    } catch (error) {
      console.error('Error updating menu items:', error);
      throw error;
    }
  }

  /**
   * Ajoute un nouvel élément au menu
   * @param {Object} item - Données de l'élément à ajouter
   * @returns {Promise<string>} ID du nouvel élément
   */
  async addMenuItem(item) {
    try {
      const newDocRef = doc(collection(db, this.menuItemsPath));
      const itemData = this.sanitizeMenuItem({
        ...item,
        id: newDocRef.id
      });
      
      await setDoc(newDocRef, itemData);
      return newDocRef.id;
    } catch (error) {
      console.error('Error adding menu item:', error);
      throw error;
    }
  }

  /**
   * Supprime un élément du menu
   * @param {string} itemId - ID de l'élément à supprimer
   */
  async deleteMenuItem(itemId) {
    try {
      const docRef = doc(db, this.menuItemsPath, itemId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting menu item:', error);
      throw error;
    }
  }

  /**
   * Nettoie et valide les données d'un élément du menu avant sauvegarde
   * @private
   * @param {Object} menuItem - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
  sanitizeMenuItem(menuItem) {
    // Enlever les champs undefined ou null
    return Object.entries(menuItem).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }
}

// Export singleton instance factory
export const menuService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {MenuService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new MenuService(foodTruckId);
  }
};