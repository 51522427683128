import { 
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
    Radio,
    Typography,
    Box,
    Divider,
    Chip 
} from "@mui/material";
import { useState, useEffect } from "react";
import { INGREDIENT_TYPES } from "../Ingredient/IngredientForm";

// Dialog pour sélectionner l'ingrédient de base
export function SelectBaseDialog({ 
    open, 
    onClose, 
    ingredients = [], 
    currentBaseId = null,
    onSelect 
}) {
    const [selected, setSelected] = useState(currentBaseId);

    useEffect(() => {
        setSelected(currentBaseId);
    }, [currentBaseId, open]);

    const baseIngredients = ingredients.filter(
        ingredient => ingredient.type === INGREDIENT_TYPES.BASE
    );

    const handleConfirm = () => {
        const selectedIngredient = ingredients.find(i => i.id === selected);
        onSelect(selectedIngredient);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Choisir la base
                    <Chip 
                        size="small" 
                        color="primary" 
                        label="Obligatoire" 
                        sx={{ ml: 1 }}
                    />
                </Box>
            </DialogTitle>
            <DialogContent>
                {baseIngredients.length === 0 ? (
                    <Typography color="text.secondary" sx={{ p: 2 }}>
                        Aucune base disponible
                    </Typography>
                ) : (
                    <List>
                        {baseIngredients.map((ingredient) => (
                            <ListItem 
                                key={ingredient.id}
                                button
                                onClick={() => setSelected(ingredient.id)}
                                sx={{
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Radio
                                        checked={selected === ingredient.id}
                                        onChange={() => setSelected(ingredient.id)}
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={ingredient.name}
                                    secondary={ingredient.isOutOfStock ? "Indisponible" : null}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                    disabled={!selected}
                >
                    Choisir cette base
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// Dialog pour sélectionner les ingrédients standards
export function SelectIngredientsDialog({ 
    open, 
    onClose, 
    ingredients = [],
    selectedIngredients = [],
    onSelect 
}) {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected([]);
    }, [open]);

    const handleSelect = (ingredient) => {
        const isSelected = selected.find(s => s.id === ingredient.id);
        if (isSelected) {
            setSelected(selected.filter(s => s.id !== ingredient.id));
        } else {
            setSelected([...selected, ingredient]);
        }
    };

    const handleConfirm = () => {
        onSelect(selected);
        setSelected([]);
        onClose();
    };

    const availableIngredients = ingredients
        .filter(ingredient => 
            ingredient.type === INGREDIENT_TYPES.STANDARD &&
            !selectedIngredients.find(s => s.id === ingredient.id)
        );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Ajouter des ingrédients
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Sélectionnez un ou plusieurs ingrédients à ajouter
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {availableIngredients.length === 0 ? (
                    <Typography color="text.secondary" sx={{ p: 2 }}>
                        Tous les ingrédients ont déjà été ajoutés
                    </Typography>
                ) : (
                    <List>
                        {availableIngredients.map((ingredient) => (
                            <ListItem 
                                key={ingredient.id}
                                button
                                onClick={() => handleSelect(ingredient)}
                                sx={{
                                    borderRadius: 1,
                                    mb: 0.5,
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selected.some(s => s.id === ingredient.id)}
                                        onChange={() => handleSelect(ingredient)}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={ingredient.name}
                                    secondary={
                                        <Box component="span" sx={{ color: ingredient.isOutOfStock ? 'error.main' : 'success.main' }}>
                                            {ingredient.isOutOfStock ? "Indisponible" : "Disponible"}
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2 }}>
                <Box sx={{ flex: 1 }}>
                    {selected.length > 0 && (
                        <Typography variant="body2" color="text.secondary">
                            {selected.length} ingrédient{selected.length > 1 ? 's' : ''} sélectionné{selected.length > 1 ? 's' : ''}
                        </Typography>
                    )}
                </Box>
                <Button onClick={onClose}>Annuler</Button>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                    disabled={selected.length === 0}
                >
                    Ajouter les ingrédients
                </Button>
            </DialogActions>
        </Dialog>
    );
}