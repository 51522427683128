import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Drawer,
    Snackbar,
    Alert,
    Chip,
} from '@mui/material';
import {
    FaPlus,
    FaEdit,
    FaTrash,
    FaTags,
    FaArrowUp,
    FaArrowDown,
} from 'react-icons/fa';
import { categoriesService } from '../../services/categoriesService';
import Loader from '../Common/Loader';
import MenuItemCategoryEditPanel from './MenuItemCategoryEditPanel';
import { useFirebase } from '../Auth/FirebaseProvider';

function MenuItemCategoryEditor() {
    const { foodTruckId, loading: contextLoading } = useFirebase();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [categoryService, setCategoryService] = useState(null);

    useEffect(() => {
        if (!foodTruckId || contextLoading) return;
        const service = categoriesService.getInstance(foodTruckId);
        setCategoryService(service);
        loadCategories(service);
    }, [foodTruckId, contextLoading]);

    const loadCategories = async (service) => {
        try {
            setLoading(true);
            const data = await service.getCategories();
            const sortedCategories = data.sort((a, b) => (a.order || 0) - (b.order || 0));
            setCategories(sortedCategories);
        } catch (error) {
            console.error("error", error);
            setError("Erreur lors du chargement des catégories");
            setSnackbar({ open: true, message: "Erreur lors du chargement des catégories", severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleMoveCategory = async (categoryId, direction) => {
        if (!categoryService) return;

        const currentIndex = categories.findIndex(cat => cat.id === categoryId);
        if ((direction === 'up' && currentIndex === 0) ||
            (direction === 'down' && currentIndex === categories.length - 1)) {
            return;
        }

        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        const newCategories = [...categories];
        const currentOrder = newCategories[currentIndex].order || currentIndex;
        const targetOrder = newCategories[newIndex].order || newIndex;

        newCategories[currentIndex] = { ...newCategories[currentIndex], order: targetOrder };
        newCategories[newIndex] = { ...newCategories[newIndex], order: currentOrder };

        newCategories.sort((a, b) => (a.order || 0) - (b.order || 0));

        try {
            await categoryService.updateCategories(newCategories);
            setCategories(newCategories);
        } catch (error) {
            console.error("Error reordering categories:", error);
            setSnackbar({
                open: true,
                message: "Erreur lors du réordonnancement",
                severity: 'error'
            });
        }
    };

    const handleAddCategory = () => {
        if (!categoryService) return;
        const maxOrder = Math.max(...categories.map(cat => cat.order || 0), -1);
        setSelectedCategory({
            name: '',
            sizes: [],
            order: maxOrder + 1,
            isHidden: false
        });
        setIsEditing(true);
    };

    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setIsEditing(true);
    };

    const handleDeleteCategory = async (categoryId) => {
        if (!categoryService || !window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?')) {
            return;
        }

        try {
            await categoryService.deleteCategory(categoryId);
            await loadCategories(categoryService);
            setSnackbar({
                open: true,
                message: "Catégorie supprimée avec succès",
                severity: 'success'
            });
        } catch (error) {
            console.error("Error deleting category:", error);
            setSnackbar({
                open: true,
                message: "Erreur lors de la suppression de la catégorie",
                severity: 'error'
            });
        }
    };

    const handleSaveCategory = async (categoryData) => {
        if (!categoryService) return;

        try {
            await categoryService.updateCategories([{
                ...categoryData,
                id: categoryData.id || undefined
            }]);
            await loadCategories(categoryService);
            setIsEditing(false);
            setSnackbar({
                open: true,
                message: categoryData.id ? "Catégorie mise à jour" : "Catégorie ajoutée",
                severity: 'success'
            });
        } catch (error) {
            console.error("Error saving category:", error);
            setSnackbar({
                open: true,
                message: "Erreur lors de la sauvegarde de la catégorie",
                severity: 'error'
            });
        }
    };

    if (contextLoading || loading) return <Loader />;
    if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FaTags className="mr-3 text-emerald-500" />
                    Gestion des catégories
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FaPlus />}
                    onClick={handleAddCategory}
                >
                    Ajouter une catégorie
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ordre</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Tailles disponibles</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category, index) => (
                            <TableRow key={category.id}>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Box>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleMoveCategory(category.id, 'up')}
                                                disabled={index === 0}
                                            >
                                                <FaArrowUp />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleMoveCategory(category.id, 'down')}
                                                disabled={index === categories.length - 1}
                                            >
                                                <FaArrowDown />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={category.name}
                                        variant="outlined"
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={`${category.complexityPoints || 0} pts`}
                                        color={category.complexityPoints > 0 ? "primary" : "default"}
                                        variant={category.complexityPoints > 0 ? "filled" : "outlined"}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        <Chip
                                            label="Classique"
                                            size="small"
                                            sx={{ backgroundColor: 'action.hover', fontWeight: 'bold' }}
                                        />
                                        {category.sizes?.map((size) => (
                                            <Chip
                                                key={size.id}
                                                label={`${size.name} (+${size.extraPrice.toFixed(2)}€)`}
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                            />
                                        ))}
                                    </Box>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => handleEditCategory(category)}
                                        color="primary"
                                    >
                                        <FaEdit />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteCategory(category.id)}
                                        color="error"
                                    >
                                        <FaTrash />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Drawer
                anchor="right"
                open={isEditing}
                onClose={() => setIsEditing(false)}
            >
                <MenuItemCategoryEditPanel
                    category={selectedCategory}
                    onSave={handleSaveCategory}
                    onClose={() => setIsEditing(false)}
                />
            </Drawer>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default MenuItemCategoryEditor;