import { db } from './firebase';
import { 
  collection, 
  doc, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  onSnapshot, 
  query, 
  orderBy,
  getDocs,
  setDoc
} from 'firebase/firestore';

export class IngredientsService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.collectionPath = `foodTrucks/${foodTruckId}/ingredients`;
  }

  /**
   * Ajoute un nouveau supplément
   * @param {Object} ingredientData - Les données du supplément
   * @returns {Promise<string>} L'ID du nouveau supplément
   */
  async addIngredient(ingredientData) {
    try {
      const docRef = await addDoc(collection(db, this.collectionPath), ingredientData);
      const ingredientWithId = {
        id: docRef.id,
        ...ingredientData
      };
      await setDoc(docRef, ingredientWithId);
      return docRef.id;
    } catch (error) {
      console.error("Error adding ingredient:", error);
      throw error;
    }
  }

  /**
   * Met à jour un supplément existant
   * @param {Object} ingredientData - Les données du supplément avec son ID
   */
  async updateIngredient(ingredientData) {
    const { id, ...updateData } = ingredientData;
    try {
      await updateDoc(doc(db, this.collectionPath, id), updateData);
    } catch (error) {
      console.error("Error updating ingredient:", error);
      throw error;
    }
  }

  /**
   * Supprime un supplément
   * @param {string} ingredientId - L'ID du supplément à supprimer
   */
  async deleteIngredient(ingredientId) {
    try {
      await deleteDoc(doc(db, this.collectionPath, ingredientId));
    } catch (error) {
      console.error("Error deleting ingredient:", error);
      throw error;
    }
  }

  /**
   * Récupère tous les suppléments
   * @returns {Promise<Array>} Liste des suppléments
   */
  async getIngredients() {
    try {
      const ingredientsQuery = query(
        collection(db, this.collectionPath), 
        orderBy('name')
      );
      const querySnapshot = await getDocs(ingredientsQuery);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error("Error fetching ingredients:", error);
      throw error;
    }
  }

  /**
   * Écoute les changements des suppléments en temps réel
   * @param {Function} onUpdate - Callback appelé lors des mises à jour
   * @param {Function} onError - Callback appelé en cas d'erreur
   * @returns {Function} Fonction pour arrêter l'écoute
   */
  listenIngredients(onUpdate, onError) {
    const q = query(collection(db, this.collectionPath), orderBy('name'));
    return onSnapshot(q, 
      (querySnapshot) => {
        const ingredients = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        onUpdate(ingredients);
      },
      (error) => {
        console.error("Error listening to ingredients:", error);
        onError(error);
      }
    );
  }
}

// Export singleton instance factory
export const ingredientsService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {IngredientsService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new IngredientsService(foodTruckId);
  }
};