import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { Timestamp } from 'firebase/firestore';

const PromoCodeSidePanel = ({ open, onClose, onSubmit, promoCode, setPromoCode, loyaltyLevels, loading }) => {


    useEffect(() => {
        if (open && !promoCode.startDate) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            setPromoCode(prev => ({
                ...prev,
                startDate: Timestamp.fromDate(today)
            }));
        }
    }, [open, promoCode.startDate, setPromoCode]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: '500px' }
            }}
        >
            <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h6" fontWeight="bold">
                        Nouveau code promo
                    </Typography>
                    <IconButton onClick={onClose}>
                        <FaTimes />
                    </IconButton>
                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Form */}
                <Box component="form" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <TextField
                        fullWidth
                        label="Code"
                        variant="outlined"
                        value={promoCode.code}
                        onChange={(e) => setPromoCode(prev => ({ ...prev, code: e.target.value.toUpperCase() }))}
                    />

                    {/* Sélecteur de type d'accès */}
                    <FormControl fullWidth>
                        <InputLabel>Accès au code promo</InputLabel>
                        <Select
                            value={promoCode.accessType || 'public'}
                            onChange={(e) => setPromoCode(prev => ({
                                ...prev,
                                accessType: e.target.value,
                                allowedLoyaltyLevels: e.target.value === 'public' ? [] : prev.allowedLoyaltyLevels
                            }))}
                            label="Accès au code promo"
                        >
                            <MenuItem value="public">Tout le monde</MenuItem>
                            <MenuItem value="loyalty">Abonnés fidélité spécifiques</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Sélecteur de niveaux de fidélité si accès restreint */}
                    {promoCode.accessType === 'loyalty' && loyaltyLevels.length > 0 && (
                        <FormControl fullWidth>
                            <InputLabel>Niveaux de fidélité autorisés</InputLabel>
                            <Select
                                multiple
                                value={promoCode.allowedLoyaltyLevels || []}
                                onChange={(e) => setPromoCode(prev => ({
                                    ...prev,
                                    allowedLoyaltyLevels: e.target.value
                                }))}
                                label="Niveaux de fidélité autorisés"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((levelId) => {
                                            const level = loyaltyLevels.find(l => l.id === levelId);
                                            return (
                                                <Chip
                                                    key={levelId}
                                                    label={level?.name || levelId}
                                                    size="small"
                                                />
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {loyaltyLevels.map((level) => (
                                    <MenuItem key={level.id} value={level.id}>
                                        {level.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl fullWidth>
                        <InputLabel>Type de réduction</InputLabel>
                        <Select
                            value={promoCode.discountType}
                            onChange={(e) => setPromoCode(prev => ({ ...prev, discountType: e.target.value }))}
                            label="Type de réduction"
                        >
                            <MenuItem value="percentage">Pourcentage</MenuItem>
                            <MenuItem value="fixed">Montant fixe</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label={promoCode.discountType === 'percentage' ? 'Pourcentage de réduction' : 'Montant de réduction'}
                        variant="outlined"
                        type="number"
                        value={promoCode.discountValue}
                        onChange={(e) => setPromoCode(prev => ({
                            ...prev,
                            discountValue: Math.min(
                                promoCode.discountType === 'percentage' ? 100 : 1000,
                                Math.max(0, parseFloat(e.target.value) || 0)
                            )
                        }))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {promoCode.discountType === 'percentage' ? '%' : '€'}
                            </InputAdornment>,
                            inputProps: {
                                min: 0,
                                max: promoCode.discountType === 'percentage' ? 100 : 1000,
                                step: promoCode.discountType === 'percentage' ? 1 : 0.01
                            }
                        }}
                    />

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Date de début"
                            value={promoCode.startDate ? new Date(promoCode.startDate.seconds * 1000).toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                                const date = e.target.value ? new Date(e.target.value) : null;
                                if (date) {
                                    date.setHours(0, 0, 0, 0); // Mettre à minuit
                                }
                                setPromoCode(prev => ({
                                    ...prev,
                                    startDate: date ? Timestamp.fromDate(date) : null
                                }));
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            fullWidth
                            type="date"
                            label="Date de fin"
                            value={promoCode.endDate ? new Date(promoCode.endDate.seconds * 1000).toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                                const date = e.target.value ? new Date(e.target.value) : null;
                                if (date) {
                                    date.setHours(0, 0, 0, 0); // Mettre à minuit
                                }
                                setPromoCode(prev => ({
                                    ...prev,
                                    endDate: date ? Timestamp.fromDate(date) : null
                                }));
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>

                    <TextField
                        fullWidth
                        label="Montant minimum de commande"
                        variant="outlined"
                        type="number"
                        value={promoCode.minOrderAmount || ''}
                        onChange={(e) => setPromoCode(prev => ({
                            ...prev,
                            minOrderAmount: e.target.value ? parseFloat(e.target.value) : null
                        }))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            inputProps: { min: 0, step: 0.01 }
                        }}
                    />

                    <TextField
                        fullWidth
                        label="Nombre maximum d'utilisations"
                        variant="outlined"
                        type="number"
                        value={promoCode.maxUses || ''}
                        onChange={(e) => setPromoCode(prev => ({
                            ...prev,
                            maxUses: e.target.value ? parseInt(e.target.value) : null
                        }))}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />

                    <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={promoCode.description}
                        onChange={(e) => setPromoCode(prev => ({ ...prev, description: e.target.value }))}
                    />
                </Box>

                {/* Footer */}
                <Box sx={{ mt: 3, pt: 3, borderTop: 1, borderColor: 'divider' }}>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button onClick={onClose} color="inherit">
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={!promoCode.code || !promoCode.discountValue || !promoCode.startDate || loading}
                        >
                            {loading ? 'Création...' : 'Créer le code promo'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default PromoCodeSidePanel;