import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from './firebase';
import { collection, onSnapshot } from 'firebase/firestore';

export class NotificationService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.notificationsPath = `foodTrucks/${foodTruckId}/notifications`;
    this.functions = getFunctions();
    this.sendNotificationFunction = httpsCallable(this.functions, 'sendNotification');
  }

  /**
   * Écoute les changements des notifications en temps réel
   * @param {Function} onUpdate - Callback appelé lors des mises à jour
   * @param {Function} onError - Callback appelé en cas d'erreur
   * @returns {Function} Fonction pour arrêter l'écoute
   */
  listenNotifications(onUpdate, onError) {
    try {
      return onSnapshot(
        collection(db, this.notificationsPath),
        (snapshot) => {
          const notifications = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(item => !item.isDeleted);
          onUpdate(notifications);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up notifications listener:', error);
      throw error;
    }
  }

  /**
   * Envoie une nouvelle notification via Cloud Function
   * @param {Object} notification - Données de la notification
   * @param {string} [loyaltyLevelId] - ID du niveau de fidélité (optionnel)
   * @returns {Promise<{id: string}>} Résultat contenant l'ID de la nouvelle notification
   */
  async sendNotification(notification, loyaltyLevelId = null) {
    try {
      const notificationData = {
        ...notification,
        ...(loyaltyLevelId && { loyaltyLevelId })
      };

      const result = await this.sendNotificationFunction(notificationData);
      return result.data;
    } catch (error) {
      console.error('Error sending notification:', error);
      throw error;
    }
  }
}

// Export singleton instance factory
export const notificationService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {NotificationService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new NotificationService(foodTruckId);
  }
};