import { differenceInMinutes, endOfWeek, format, startOfWeek } from 'date-fns';
import { db, functions } from './firebase';
import { 
  collection, 
  onSnapshot, 
  doc, 
  updateDoc, 
  query, 
  where, 
  Timestamp, 
  orderBy, 
  limit, 
  startAfter, 
  getDocs,
  getDoc
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

export class OrderService {
  constructor(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    this.ordersPath = `foodTrucks/${foodTruckId}/orders`;
    this.foodTruckId = foodTruckId;
  }

/**
 * Met à jour les données en temps réel pour une date spécifique
 * @param {Object} options - Options de configuration
 * @param {Function} options.onUpdate - Callback appelé lors des mises à jour
 * @param {Function} options.onError - Callback appelé en cas d'erreur
 * @param {Date} [options.date=new Date()] - Date pour laquelle récupérer les commandes
 * @param {boolean} [options.=false] - Inclure les commandes supprimées
 * @returns {Function} Fonction pour arrêter l'écoute
 */
listenToOrders({
  onUpdate,
  onError,
  date = new Date(),
  timeRange = null // Nouveau paramètre pour gérer les plages horaires spécifiques
} = {}) {
  try {
    // Initialiser les plages horaires
    let startTime, endTime;
    
    if (timeRange) {
      // Si une plage horaire spécifique est fournie
      startTime = new Date(date);
      const [startHours, startMinutes] = timeRange.start.split(':').map(Number);
      startTime.setHours(startHours, startMinutes, 0, 0);
      
      endTime = new Date(date);
      const [endHours, endMinutes] = timeRange.end.split(':').map(Number);
      // Si l'heure de fin est avant l'heure de début, on est sur le jour suivant
      if (endHours < startHours || (endHours === startHours && endMinutes <= startMinutes)) {
        endTime.setDate(endTime.getDate() + 1);
      }
      endTime.setHours(endHours, endMinutes, 59, 999);
    } else {
      // Sinon, prendre la journée entière
      startTime = new Date(date);
      startTime.setHours(0, 0, 0, 0);
      endTime = new Date(date);
      endTime.setHours(23, 59, 59, 999);
    }

    // Construction des requêtes pour gérer les deux jours si nécessaire
    const baseQuery = query(
      collection(db, this.ordersPath),
      where('pickupTime', '>=', Timestamp.fromDate(startTime)),
      where('pickupTime', '<=', Timestamp.fromDate(endTime)),
      orderBy('pickupTime', 'desc')
    );

    return onSnapshot(
      baseQuery,
      (snapshot) => {
        const orders = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(order => !order.isDeleted && !order.isCanceled)
          .map(order => this.formatOrderData(order));

        onUpdate(orders);
      },
      onError || ((error) => {
        console.error('Error in orders listener:', error);
      })
    );
  } catch (error) {
    console.error('Error setting up orders listener:', error);
    throw error;
  }
}

/**
 * Met à jour les données en temps réel pour une semaine
 * @param {Object} options - Options de configuration
 * @param {Function} options.onUpdate - Callback appelé lors des mises à jour
 * @param {Function} options.onError - Callback appelé en cas d'erreur
 * @param {Date} [options.date=new Date()] - Date de référence pour la semaine
 * @returns {Function} Fonction pour arrêter l'écoute
 */
listenToWeekOrders({
  onUpdate,
  onError,
  date = new Date()
} = {}) {
  try {
    // Calculer début et fin de semaine
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });
    weekStart.setHours(0, 0, 0, 0);
    
    const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
    weekEnd.setHours(23, 59, 59, 999);

    // Construction de la requête
    const ordersQuery = query(
      collection(db, this.ordersPath),
      where('pickupTime', '>=', Timestamp.fromDate(weekStart)),
      where('pickupTime', '<=', Timestamp.fromDate(weekEnd)),
      orderBy('pickupTime', 'desc')
    );

    // Mise en place du listener
    return onSnapshot(
      ordersQuery,
      (snapshot) => {
        const orders = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(order => !order.isDeleted && !order.isCanceled)
          .map(this.formatOrderData);

        // Grouper les commandes par jour
        const ordersByDay = orders.reduce((acc, order) => {
          const dayKey = format(order.pickupTime.toDate(), 'yyyy-MM-dd');
          if (!acc[dayKey]) {
            acc[dayKey] = [];
          }
          acc[dayKey].push(order);
          return acc;
        }, {});

        onUpdate(ordersByDay);
      },
      onError || ((error) => {
        console.error('Error in orders listener:', error);
      })
    );
  } catch (error) {
    console.error('Error setting up week orders listener:', error);
    throw error;
  }
}

// Méthode pour calculer les statistiques d'une journée
calculateDayStats(orders, schedule) {
  if (!schedule || !orders?.length) {
    return null;
  }

  const totalSlots = schedule.locations.reduce((acc, loc) => {
    const startTime = loc.startTime.toDate();
    const endTime = loc.endTime.toDate();
    const duration = differenceInMinutes(endTime, startTime);
    return acc + Math.ceil(duration / (schedule.timeSlotSettings?.duration ?? 15));
  }, 0);

  const occupiedSlots = new Set(orders.map(order => 
    format(order.pickupTime.toDate(), 'HH:mm')
  )).size;

  return {
    slotsCount: totalSlots,
    ordersCount: orders.length,
    occupationRate: Math.round((occupiedSlots / totalSlots) * 100)
  };
}


  /**
   * Écoute les commandes passées en temps réel avec pagination
   * @param {Date} date - Date des commandes à récupérer
   * @param {number} currentPage - Page actuelle
   * @param {number} ordersPerPage - Nombre de commandes par page
   * @param {Function} onUpdate - Callback appelé lors des mises à jour
   * @param {Function} onError - Callback appelé en cas d'erreur
   * @returns {Promise<Function>} Fonction pour arrêter l'écoute
   */
  async listenPastOrders(date, currentPage, ordersPerPage, onUpdate, onError) {
    try {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);

      const baseQuery = query(
        collection(db, this.ordersPath),
        where('pickupTime', '>=', Timestamp.fromDate(startOfDay)),
        where('pickupTime', '<=', Timestamp.fromDate(endOfDay)),
        orderBy('pickupTime', 'desc')
      );

      let pageQuery = baseQuery;
      if (currentPage > 1) {
        const previousPageQuery = query(baseQuery, limit((currentPage - 1) * ordersPerPage));
        const previousPageSnapshot = await getDocs(previousPageQuery);
        const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
        pageQuery = query(baseQuery, startAfter(lastVisible), limit(ordersPerPage));
      } else {
        pageQuery = query(baseQuery, limit(ordersPerPage));
      }

      return onSnapshot(
        pageQuery,
        (snapshot) => {
          const orders = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(order => !order.isDeleted && !order.isCanceled)
            .map(this.formatOrderData);
          onUpdate(orders);
        },
        onError
      );
    } catch (error) {
      console.error('Error setting up past orders listener:', error);
      throw error;
    }
  }

  /**
   * Met à jour le statut d'une commande
   * @param {string} orderId - ID de la commande
   * @param {string} newStatus - Nouveau statut
   */
  async updateOrderStatus(orderId, newStatus) {
    try {
      if (!this.isValidStatusTransition(orderId, newStatus)) {
        throw new Error('Invalid status transition');
      }

      const orderRef = doc(db, this.ordersPath, orderId);
      const updateData = { status: newStatus };

      switch (newStatus) {
        case 'preparing':
          updateData.preparingTime = Timestamp.now();
          break;
        case 'ready':
          updateData.readyTime = Timestamp.now();
          break;
        case 'delivered':
          updateData.deliveredTime = Timestamp.now();
          updateData.isFinished = true;
          break;
        default: break;
      }

      await updateDoc(orderRef, this.sanitizeOrderData(updateData));
    } catch (error) {
      console.error('Error updating order status:', error);
      throw error;
    }
  }

  /**
   * Trouve les prochains créneaux disponibles
   * @param {Array} menuItems - Liste des articles commandés
   * @returns {Promise<Array>} Liste des créneaux disponibles
   */
  async findNextAvailableSlots(menuItems) {
    try {
      const findNextAvailableSlotsFunction = httpsCallable(functions, 'findNextAvailableSlots');
      const formattedMenuItems = menuItems.map(item => ({
        id: item.id,
        preparationTime: item.preparationTime || 0,
        quantity: item.quantity || 1
      }));

      const today = new Date();
      const data = {
        menuItems: formattedMenuItems,
        foodTruckId: this.foodTruckId,
        date: today.toISOString().split('T')[0]
      };

      const result = await findNextAvailableSlotsFunction(data);
      if (!result.data.success) {
        throw new Error("La fonction Cloud n'a pas retourné de succès");
      }

      return result.data.slots;
    } catch (error) {
      console.error('Error finding available slots:', error);
      this.handleCloudFunctionError(error);
    }
  }

  /**
   * Ajoute une nouvelle commande
   * @param {Object} orderData - Données de la commande
   * @returns {Promise<string>} ID de la nouvelle commande
   */
  async addOrder(orderData) {
    try {
      const addOrderFunction = httpsCallable(functions, 'addOrder');
      const slotStartTime = orderData.timeslot instanceof Date
        ? orderData.timeslot.toISOString()
        : orderData.timeslot;

      const preparedOrderData = {
        foodTruckId: this.foodTruckId,
        pickupTime: slotStartTime,
        cartItems: orderData.items.map(item => ({
          id: item.id,
          quantity: item.quantity,
          supplements: (item.supplements || []).map(supp => ({
            id: supp.id,
            name: supp.name,
            price: supp.price,
            quantity: supp.quantity,
          }))
        })),
        loyaltyLevelId: null,
        customerId: orderData.customerId,
        customerName: orderData.customerName,
        isPaid: false,
      };

      const result = await addOrderFunction(preparedOrderData);
      return result.data.orderId;
    } catch (error) {
      console.error('Error adding order:', error);
      this.handleCloudFunctionError(error);
    }
  }

    /**
   * Met à jour le paiement d'une commande
   * @param {string} orderId - ID de la commande
   * @param {Object} paymentData - Données du paiement
   */
    async updateOrderPayment(orderId, paymentData) {
      try {
        // Récupérer la commande actuelle
        const orderRef = doc(db, this.ordersPath, orderId);
        const orderDoc = await getDoc(orderRef);
        
        if (!orderDoc.exists()) {
          throw new Error('Order not found');
        }
  
        const currentOrder = orderDoc.data();
        const currentPayments = currentOrder.payments || [];
        
        // Nettoyer les nouvelles données de paiement
        const cleanPaymentData = this.sanitizePaymentData(paymentData);

        console.log("cleanPaymentData", cleanPaymentData);
        
        // Fusionner les paiements existants avec les nouveaux
        const mergedPayments = this.mergePayments(currentPayments, cleanPaymentData.payments);
        console.log("mergedPayments", cleanPaymentData);

        // Mettre à jour la commande avec les paiements fusionnés
        await updateDoc(orderRef, {
          isPaid: true,
          payments: mergedPayments,
          lastUpdated: Timestamp.now()
        });
  
      } catch (error) {
        console.error('Error updating order payment:', error);
        throw error;
      }
    }
  
    /**
     * Fusionne les paiements existants avec les nouveaux
     * @private
     * @param {Array} currentPayments - Paiements existants
     * @param {Array} newPayments - Nouveaux paiements
     * @returns {Array} Paiements fusionnés
     */
    mergePayments(currentPayments, newPayments) {
      const paymentMap = new Map();
      
      // Ajouter les paiements existants
      currentPayments.forEach(payment => {
        paymentMap.set(payment.id, payment);
      });
  
      // Ajouter ou mettre à jour avec les nouveaux paiements
      newPayments.forEach(payment => {
        paymentMap.set(payment.id, payment);
      });
  
      return Array.from(paymentMap.values());
    }
  
    /**
     * Calcule le total des paiements d'une commande
     * @param {Array} payments - Liste des paiements
     * @returns {number} Montant total payé
     */
    calculateTotalPaid(payments) {
      if (!Array.isArray(payments)) return 0;
      return payments.reduce((total, payment) => total + (Number(payment.amount) || 0), 0);
    }
  
    /**
     * Vérifie si une commande est entièrement payée
     * @param {Object} order - Commande à vérifier
     * @returns {boolean} True si la commande est entièrement payée
     */
    isFullyPaid(order) {
      if (!order || !order.totalPrice) return false;
      const totalPaid = this.calculateTotalPaid(order.payments);
      return totalPaid >= order.totalPrice;
    }

  /**
   * Récupère les commandes d'un client
   * @param {string} clientId - ID du client
   * @param {number} limitCount - Nombre maximum de commandes à récupérer
   * @returns {Promise<Array>} Liste des commandes du client
   */
  async getClientOrders(clientId, limitCount = 50) {
    try {
      const ordersQuery = query(
        collection(db, this.ordersPath),
        where('customerId', '==', clientId),
        orderBy('pickupTime', 'desc'),
        limit(limitCount)
      );

      const querySnapshot = await getDocs(ordersQuery);
      return querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .map(this.formatOrderData);
    } catch (error) {
      console.error('Error fetching client orders:', error);
      throw error;
    }
  }

  /**
   * Vérifie si une transition de statut est valide
   * @private
   * @param {string} currentStatus - Statut actuel
   * @param {string} newStatus - Nouveau statut
   * @returns {boolean} True si la transition est valide
   */
  isValidStatusTransition(currentStatus, newStatus) {
    const statusOrder = ['pending', 'preparing', 'ready', 'delivered'];
    return statusOrder.indexOf(newStatus) > statusOrder.indexOf(currentStatus);
  }

  /**
   * Gère les erreurs des fonctions Cloud
   * @private
   * @param {Error} error - Erreur à gérer
   */
  handleCloudFunctionError(error) {
    if (error.code) {
      switch (error.code) {
        case 'functions/invalid-argument':
          throw new Error("Arguments invalides fournis à la fonction");
        case 'functions/not-found':
          throw new Error("Food truck non trouvé");
        case 'functions/unavailable':
          throw new Error("Service temporairement indisponible");
        case 'functions/internal':
          throw new Error("Erreur interne du serveur");
        default:
          throw new Error(`Erreur Firebase: ${error.message}`);
      }
    }
    throw error;
  }

  /**
   * Formate les données d'une commande
   * @private
   * @param {Object} orderData - Données brutes de la commande
   * @returns {Object} Données formatées
   */
  formatOrderData(orderData) {
    return {
      id: orderData.id,
      status: orderData.status || 'pending',
      pickupTime: orderData.pickupTime,
      customerName: orderData.customerName || '',
      customerId: orderData.customerId || null,
      items: orderData.items || [],
      totalPrice: orderData.totalPrice || 0,
      isPaid: orderData.isPaid || false,
      paymentDetails: orderData.paymentDetails || null,
      payments: orderData.payments || null,
      preparingTime: orderData.preparingTime || null,
      readyTime: orderData.readyTime || null,
      deliveredTime: orderData.deliveredTime || null,
      isFinished: orderData.isFinished || false,
      isDeleted: orderData.isDeleted || false,
      complexityPoints: orderData.complexityPoints || 0,
    };
  }

  /**
   * Nettoie les données d'une commande avant sauvegarde
   * @private
   * @param {Object} orderData - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
  sanitizeOrderData(orderData) {
    return Object.entries(orderData).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  /**
   * Nettoie les données de paiement avant sauvegarde
   * @private
   * @param {Object} paymentData - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
    /**
   * Nettoie les données de paiement avant sauvegarde
   * @private
   * @param {Object} paymentData - Données à nettoyer
   * @returns {Object} Données nettoyées
   */
    sanitizePaymentData(paymentData) {
      console.log("sanitizePaymentData", paymentData);
      if (!paymentData || !paymentData.payments) {
        return { payments: [] };
      }
  
      const payments = Array.isArray(paymentData.payments) ? paymentData.payments : [];
      const cleanPayments = payments.map(payment => ({
        id: payment.id || crypto.randomUUID(),
        method: this.sanitizePaymentMethod(payment.method),
        amount: Number(payment.amount) || 0,
        timestamp: payment.timestamp || Timestamp.now(),
        //...(payment.method?.type === "card" && { transactionId: payment.transactionId }),
        /*...(payment.method?.type === "ticket" && {
          ticketNumber: payment.ticketNumber,
          maxTicketAmount: Number(payment.maxTicketAmount) || 0
        })*/
      }));
  
      return {
        payments: cleanPayments
      };
    }

      /**
   * Nettoie la méthode de paiement
   * @private
   * @param {Object} method - Méthode de paiement à nettoyer
   * @returns {Object} Méthode de paiement nettoyée
   */
  sanitizePaymentMethod(method) {
    if (!method || typeof method !== 'object') {
      return { type: 'cash' }; // Méthode par défaut
    }

    const cleanMethod = {
      type: method.type?.toLowerCase() || 'cash'
    };

    // Ajouter la description uniquement pour le type 'other'
    if (cleanMethod.type === 'other' && method.description) {
      cleanMethod.description = String(method.description).trim();
    }

    // Validation du type
    if (!['cash', 'card', 'app', 'ticket', 'other'].includes(cleanMethod.type)) {
      cleanMethod.type = 'other';
      cleanMethod.description = String(method.type).trim();
    }

    return cleanMethod;
  }

}

// Export singleton instance factory
export const orderService = {
  /**
   * Crée ou récupère une instance du service pour un food truck spécifique
   * @param {string} foodTruckId - ID du food truck
   * @returns {OrderService}
   */
  getInstance(foodTruckId) {
    if (!foodTruckId) {
      throw new Error('Food truck ID is required');
    }
    return new OrderService(foodTruckId);
  }
};