import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
} from '@mui/material';
import {
    FaTimes,
    FaSave,
    FaPlus,
    FaTrash,
    FaMapMarkerAlt,
} from 'react-icons/fa';
import { DAYS_OF_WEEK, formatTime } from '../../utils/helpers';
import { GeoPoint, Timestamp } from 'firebase/firestore';
import SectionTitle from '../Common/Title';

// Composant pour l'édition des coordonnées
const CoordinatesDialog = ({ open, onClose, coordinates, onSave }) => {
    const [latitude, setLatitude] = useState(coordinates?.latitude || '');
    const [longitude, setLongitude] = useState(coordinates?.longitude || '');
    const [error, setError] = useState('');

    const handleSave = () => {
        const lat = parseFloat(latitude);
        const lng = parseFloat(longitude);

        if (isNaN(lat) || isNaN(lng) || lat < -90 || lat > 90 || lng < -180 || lng > 180) {
            setError('Coordonnées invalides');
            return;
        }

        onSave(new GeoPoint(lat, lng));
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    Coordonnées GPS
                    <IconButton onClick={onClose} size="small">
                        <FaTimes />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, minWidth: '300px' }}>
                    <TextField
                        label="Latitude"
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                        type="number"
                        inputProps={{ step: "any", min: -90, max: 90 }}
                        error={!!error}
                        fullWidth
                    />
                    <TextField
                        label="Longitude"
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                        type="number"
                        inputProps={{ step: "any", min: -180, max: 180 }}
                        error={!!error}
                        fullWidth
                    />
                    {error && (
                        <Typography color="error" variant="caption">
                            {error}
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function TruckHoursEditPanel({ foodTruck, onClose, onUpdate }) {
    const [editedTruck, setEditedTruck] = useState({ ...foodTruck });
    const [isUpdating, setIsUpdating] = useState(false);
    const [editingCoordinates, setEditingCoordinates] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const handleChange = (field, value) => {
        setEditedTruck(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        setIsUpdating(true);
        await onUpdate(editedTruck);
        setIsUpdating(false);
    };

    const handleTimeChange = (dayIndex, locationIndex, field, value) => {
        const [hours, minutes] = value.split(':');
        let date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));

        const updatedSchedule = [...editedTruck.weeklySchedule];
        updatedSchedule[dayIndex].locations[locationIndex][field] = Timestamp.fromDate(date);
        handleChange('weeklySchedule', updatedSchedule);
    };

    const addLocation = (dayIndex) => {
        const updatedSchedule = [...editedTruck.weeklySchedule];
        const now = new Date();
        updatedSchedule[dayIndex].locations.push({
            id: Date.now().toString(),
            address: '',
            startTime: Timestamp.fromDate(now),
            endTime: Timestamp.fromDate(now),
            coordinates: null, // Ajout du champ coordinates
        });
        handleChange('weeklySchedule', updatedSchedule);
    };

    const removeLocation = (dayIndex, locationIndex) => {
        const updatedSchedule = [...editedTruck.weeklySchedule];
        updatedSchedule[dayIndex].locations = updatedSchedule[dayIndex].locations.filter((_, i) => i !== locationIndex);
        handleChange('weeklySchedule', updatedSchedule);
    };

    const handleLocationAddressChange = (dayIndex, locationIndex, value) => {
        const updatedSchedule = [...editedTruck.weeklySchedule];
        updatedSchedule[dayIndex].locations[locationIndex].address = value;
        handleChange('weeklySchedule', updatedSchedule);
    };

    const handleCoordinatesUpdate = (coordinates) => {
        if (!selectedLocation) return;
        
        const { dayIndex, locationIndex } = selectedLocation;
        const updatedSchedule = [...editedTruck.weeklySchedule];
        updatedSchedule[dayIndex].locations[locationIndex].coordinates = coordinates;
        handleChange('weeklySchedule', updatedSchedule);
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 3,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    Modifier les horaires
                </Typography>
                <IconButton onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </Box>

            {/* Content */}
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 3
            }}>
                <SectionTitle>Horaires et emplacements</SectionTitle>
                {editedTruck.weeklySchedule.map((day, dayIndex) => (
                    <Box
                        key={day.day}
                        sx={{
                            mb: 3,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider',
                            overflow: 'hidden'
                        }}
                    >
                        <Box sx={{
                            bgcolor: 'primary.main',
                            color: 'primary.contrastText',
                            px: 2,
                            py: 1,
                        }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                            {DAYS_OF_WEEK.find(day => day.id === dayIndex + 1)?.name}
                            </Typography>
                        </Box>

                        <Box sx={{ p: 2 }}>
                            {day.locations.map((location, locationIndex) => (
                                <Box
                                    key={location.id}
                                    sx={{
                                        mb: 2,
                                        p: 2,
                                        bgcolor: 'grey.50',
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        '&:last-child': { mb: 0 }
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        mb: 2
                                    }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Nom de l'emplacement"
                                            value={location.address}
                                            onChange={(e) => handleLocationAddressChange(dayIndex, locationIndex, e.target.value)}
                                            sx={{ flex: 1 }}
                                        />
                                        <Tooltip title="Coordonnées GPS">
                                            <IconButton
                                                size="small"
                                                color={location.coordinates ? "primary" : "default"}
                                                onClick={() => {
                                                    setSelectedLocation({ dayIndex, locationIndex });
                                                    setEditingCoordinates(true);
                                                }}
                                            >
                                                <FaMapMarkerAlt />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => removeLocation(dayIndex, locationIndex)}
                                            sx={{
                                                '&:hover': {
                                                    bgcolor: 'error.light',
                                                    color: 'error.contrastText'
                                                }
                                            }}
                                        >
                                            <FaTrash />
                                        </IconButton>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                        pl: 1
                                    }}>
                                        <Typography variant="body2" color="text.secondary" sx={{ minWidth: 80 }}>
                                            Horaires :
                                        </Typography>
                                        <TextField
                                            size="small"
                                            type="time"
                                            value={formatTime(location.startTime)}
                                            onChange={(e) => handleTimeChange(dayIndex, locationIndex, 'startTime', e.target.value)}
                                            sx={{ width: 130 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <Typography variant="body2" color="text.secondary">
                                            à
                                        </Typography>
                                        <TextField
                                            size="small"
                                            type="time"
                                            value={formatTime(location.endTime)}
                                            onChange={(e) => handleTimeChange(dayIndex, locationIndex, 'endTime', e.target.value)}
                                            sx={{ width: 130 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    {location.coordinates && (
                                        <Typography 
                                            variant="caption" 
                                            color="primary"
                                            sx={{ display: 'block', mt: 1, pl: 1 }}
                                        >
                                            GPS: {location.coordinates.latitude}, {location.coordinates.longitude}
                                        </Typography>
                                    )}
                                </Box>
                            ))}

                            <Button
                                startIcon={<FaPlus />}
                                onClick={() => addLocation(dayIndex)}
                                variant="outlined"
                                size="small"
                                sx={{
                                    mt: day.locations.length > 0 ? 2 : 0,
                                    width: '100%',
                                    borderStyle: 'dashed'
                                }}
                            >
                                Ajouter un emplacement
                            </Button>
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* Footer */}
            <Box sx={{
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    startIcon={<FaSave />}
                    onClick={handleSubmit}
                    disabled={isUpdating}
                >
                    Enregistrer
                </Button>
            </Box>

            {editingCoordinates && selectedLocation && (
                <CoordinatesDialog
                    open={true}
                    onClose={() => {
                        setEditingCoordinates(false);
                        setSelectedLocation(null);
                    }}
                    coordinates={editedTruck.weeklySchedule[selectedLocation.dayIndex]
                        .locations[selectedLocation.locationIndex].coordinates}
                    onSave={handleCoordinatesUpdate}
                />
            )}
        </Box>
    );
}

export default TruckHoursEditPanel;