import React, { useRef, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    TextField,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
} from '@mui/material';
import {
    FaPlus,
    FaTimes,
    FaSave,
    FaUpload,
    FaTrash,
    FaArrowDown,
    FaArrowUp,
} from 'react-icons/fa';
import { uploadImage } from '../../services/imageUploadService';
import Loader from '../Common/Loader';
import SelectSupplementDialog from './SelectSupplementDialog';
import AllergenSelect from './Allergens';
import SectionTitle from '../Common/Title';
import SupplementsTable from './SupplementsTable';
import { SelectBaseDialog, SelectIngredientsDialog } from './SelectIngredientDialog';
import { INGREDIENT_TYPES } from '../Ingredient/IngredientForm';

const saleTypes = [
    { id: 'default', label: 'Défaut' },
    { id: 'clickAndCollect', label: 'Click & Collect' },
    { id: 'table', label: 'À table' },
    { id: 'delivery', label: 'Livraison' },
    { id: 'onSite', label: 'Sur place' },
];

const vatRates = [
    { value: '5.5', label: 'FR - 5,50 %' },
    { value: '10', label: 'FR - 10,00 %' },
    { value: '20', label: 'FR - 20,00 %' },
];

function BaseIngredient({ baseIngredient, onChangeClick }) {
    if (!baseIngredient) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onChangeClick}
                    startIcon={<FaPlus />}
                >
                    Choisir la base
                </Button>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: 'background.paper',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
            }}
        >
            <Box>
                <Typography variant="subtitle1">{baseIngredient.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                    Base {baseIngredient.isOutOfStock ? '(Indisponible)' : '(Disponible)'}
                </Typography>
            </Box>
            <Button
                variant="outlined"
                onClick={onChangeClick}
                size="small"
            >
                Changer
            </Button>
        </Box>
    );
}


function IngredientsTable({ ingredients, onMove, onRemove, onAdd }) {
    const handleMoveUp = (index) => {
        if (index === 0) return;
        const newIngredients = [...ingredients];
        [newIngredients[index - 1], newIngredients[index]] = [newIngredients[index], newIngredients[index - 1]];
        onMove(newIngredients);
    };

    const handleMoveDown = (index) => {
        if (index === ingredients.length - 1) return;
        const newIngredients = [...ingredients];
        [newIngredients[index], newIngredients[index + 1]] = [newIngredients[index + 1], newIngredients[index]];
        onMove(newIngredients);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={80} align="center">#</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell width={140} align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ingredients.map((ingredient, index) => (
                            <TableRow key={ingredient.id || index}>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleMoveUp(index)}
                                            disabled={index === 0}
                                            sx={{ p: 0.5 }}
                                        >
                                            <FaArrowUp size={12} />
                                        </IconButton>
                                        <Typography variant="body2" sx={{ my: 0.5 }}>
                                            {index + 1}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleMoveDown(index)}
                                            disabled={index === ingredients.length - 1}
                                            sx={{ p: 0.5 }}
                                        >
                                            <FaArrowDown size={12} />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{ingredient.name}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => onRemove(ingredient.id)}
                                        size="small"
                                        color="error"
                                    >
                                        <FaTrash />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {ingredients.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3} align="center" sx={{ py: 4 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Aucun ingrédient ajouté
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    startIcon={<FaPlus />}
                    onClick={onAdd}
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    Ajouter un ingrédient
                </Button>
            </Box>
        </Box>
    );
}

function PriceTable({ prices, onChange, onAdd, onDelete }) {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>TVA</TableCell>
                        <TableCell>Prix TTC</TableCell>
                        <TableCell>Prix barré TTC</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prices.map((price, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Select
                                    value={price.type}
                                    onChange={(e) => onChange(index, 'type', e.target.value)}
                                    fullWidth
                                >
                                    {saleTypes.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={price.vat}
                                    onChange={(e) => onChange(index, 'vat', e.target.value)}
                                    fullWidth
                                >
                                    {vatRates.map((rate) => (
                                        <MenuItem key={rate.value} value={rate.value}>{rate.label}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="number"
                                    value={price.priceTTC}
                                    onChange={(e) => onChange(index, 'priceTTC', parseFloat(e.target.value) || 0)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="number"
                                    value={price.discountPriceTTC !== null ? price.discountPriceTTC : ''}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? null : parseFloat(e.target.value);
                                        onChange(index, 'discountPriceTTC', value);
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                {index !== 0 && (
                                    <IconButton onClick={() => onDelete(index)}>
                                        <FaTrash />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button startIcon={<FaPlus />} onClick={onAdd}>
                    Ajouter un prix
                </Button>
            </Box>
        </TableContainer>
    );
}

function MenuItemForm({ item, foodCategories, supplements, ingredients, onSave, onClose }) {
    const [formData, setFormData] = useState(() => {
        const defaultPrice = { type: 'default', vat: '5.5', priceTTC: 0, discountPriceTTC: null };
        const initialPrices = Array.isArray(item?.prices) && item.prices.length > 0
            ? item.prices.map(price => ({
                ...price,
                priceTTC: parseFloat(price.priceTTC) || 0,
                discountPriceTTC: price.discountPriceTTC !== undefined ? parseFloat(price.discountPriceTTC) || null : null
            }))
            : [defaultPrice];

        return {
            name: item?.name || '',
            categoryId: item?.categoryId || null,
            description: item?.description || '',
            isHidden: item?.isHidden || false,
            isOutOfStock: item?.isOutOfStock || false,
            preparationTime: item?.preparationTime || '',
            allergens: Array.isArray(item?.allergens) ? item.allergens : [],
            prices: initialPrices,
            image: item?.image || null,
            imageUrl: item?.imageUrl || '',
            sizes: item?.sizes || [
                { name: 'Standard', extraPrice: 0, isDefault: true }
            ],
        };
    });
    const [imagePreview, setImagePreview] = useState(item?.imageUrl || null);
    const [isUploading, setIsUploading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isBaseDialogOpen, setBaseDialogOpen] = useState(false);
    const [isIngredientsDialogOpen, setIngredientsDialogOpen] = useState(false);

    const fileInputRef = useRef(null);

    useEffect(() => {
        return () => {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handlePriceChange = (index, field, value) => {
        setFormData(prevData => ({
            ...prevData,
            prices: prevData.prices.map((price, i) =>
                i === index ? { ...price, [field]: value } : price
            ),
        }));
    };

    const handleAddPrice = () => {
        setFormData(prevData => ({
            ...prevData,
            prices: [...prevData.prices, { type: 'default', vat: '5.5', priceTTC: 0, discountPriceTTC: null }],
        }));
    };

    const handleDeletePrice = (index) => {
        setFormData(prevData => ({
            ...prevData,
            prices: prevData.prices.filter((_, i) => i !== index),
        }));
    };

    // État pour la base
    const [baseIngredient, setBaseIngredient] = useState(() => {
        if (!item?.ingredients?.length) return null;

        // Trouver l'ingrédient de base dans la liste des ingrédients
        const baseId = item.ingredients.find(ing => ing.type === INGREDIENT_TYPES.BASE)?.id;
        return ingredients.find(ing => ing.id === baseId) || null;
    });

    // État pour les ingrédients standards
    const [standardIngredients, setStandardIngredients] = useState(() => {
        return (item?.ingredients || [])
            .filter(ing => ing.type !== INGREDIENT_TYPES.BASE)
            .map((itemIngredient, index) => {
                const fullIngredient = ingredients.find(i => i.id === itemIngredient.id);
                if (!fullIngredient) return null;
                return {
                    ...fullIngredient,
                    order: itemIngredient.order || index
                };
            })
            .filter(Boolean)
            .sort((a, b) => a.order - b.order);
    });

    // Gestionnaires pour la base
    const handleBaseSelect = (selectedBase) => {
        setBaseIngredient(selectedBase);
        setBaseDialogOpen(false);
    };

    // Gestionnaires pour les ingrédients standards
    const handleIngredientsSelect = (selectedIngredients) => {
        const currentLength = standardIngredients.length;
        const newIngredients = selectedIngredients.map((ingredient, index) => ({
            ...ingredient,
            order: currentLength + index
        }));

        setStandardIngredients([
            ...standardIngredients,
            ...newIngredients
        ]);
        setIngredientsDialogOpen(false);
    };

    const handleMoveStandardIngredients = (newIngredients) => {
        setStandardIngredients(newIngredients.map((ingredient, index) => ({
            ...ingredient,
            order: index
        })));
    };

    const handleRemoveStandardIngredient = (ingredientId) => {
        setStandardIngredients(prev =>
            prev.filter(ing => ing.id !== ingredientId)
                .map((ing, index) => ({ ...ing, order: index }))
        );
    };

    // Supplements
    const [itemSupplements, setItemSupplements] = useState(() => {
        // Récupérer les suppléments avec leurs détails complets
        return (item?.supplements || [])
            .map((itemSupplement, index) => {
                // Trouver le supplément complet correspondant dans la liste des suppléments
                const fullSupplement = supplements.find(s => s.id === itemSupplement.id);
                if (!fullSupplement) return null; // Ignorer si le supplément n'existe pas

                return {
                    ...fullSupplement, // Inclure toutes les infos (name, price, etc.)
                    order: itemSupplement.order || index
                };
            })
            .filter(Boolean) // Supprimer les éléments null
            .sort((a, b) => a.order - b.order); // Trier par ordre
    });

    const [isSelectSupplementDialogOpen, setIsSelectSupplementDialogOpen] = useState(false);

    const handleOpenSelectSupplementDialog = () => {
        setIsSelectSupplementDialogOpen(true);
    };

    const handleMoveSupplements = (newSupplements) => {
        setItemSupplements(newSupplements.map((supplement, index) => ({
            ...supplement,
            order: index
        })));
    };

    const handleCloseSelectSupplementDialog = () => {
        setIsSelectSupplementDialogOpen(false);
    };

    const handleSelectSupplement = (selectedSupplements) => {
        // Ajouter les nouveaux suppléments avec leur ordre
        const currentLength = itemSupplements.length;
        const newSupplements = selectedSupplements.map((supplement, index) => ({
            ...supplement,
            order: currentLength + index
        }));

        setItemSupplements([
            ...itemSupplements,
            ...newSupplements
        ]);
        setIsSelectSupplementDialogOpen(false); // Fermer la dialog après sélection
    };

    const handleRemoveSupplement = (supplementId) => {
        const newSupplements = itemSupplements
            .filter(s => s.id !== supplementId)
            .map((supplement, index) => ({
                ...supplement,
                order: index
            }));
        setItemSupplements(newSupplements);
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);
            try {
                // Créer un URL temporaire pour la prévisualisation
                const previewURL = URL.createObjectURL(file);
                setImagePreview(previewURL);

                const path = `menu-items/${Date.now()}_${file.name}`;
                const downloadURL = await uploadImage(file, path);

                setFormData(prevData => ({
                    ...prevData,
                    imageUrl: downloadURL
                }));
            } catch (error) {
                console.error("Error uploading image: ", error);
                // Gérer l'erreur (par exemple, afficher un message à l'utilisateur)
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        if (file) {
            handleImageUpload(file);
        }
    };

    const handleAllergenChange = (newAllergens) => {
        setFormData(prev => ({
            ...prev,
            allergens: newAllergens
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);

        try {
            const { image, ...dataToSave } = formData;

            dataToSave.ingredients = [
                ...(baseIngredient ? [{
                    id: baseIngredient.id,
                    type: INGREDIENT_TYPES.BASE,
                    order: 0
                }] : []),
                ...standardIngredients.map((ingredient, index) => ({
                    id: ingredient.id,
                    type: INGREDIENT_TYPES.STANDARD,
                    order: index + 1
                }))
            ];
            // Mise à jour des suppléments
            dataToSave.supplements = itemSupplements
                .sort((a, b) => a.order - b.order)
                .map((supplement, index) => ({
                    id: supplement.id,
                    order: index
                }));

            console.log("data to save ? ", dataToSave);

            await onSave(dataToSave);
            onClose();
        } catch (error) {
            console.error("Error saving menu item: ", error);
        } finally {
            setIsSaving(false);
        }
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            position: 'relative',
            bgcolor: 'background.default',
        }}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 3,
                pb: 10
            }}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" fontWeight="bold" color="primary.main">Produit</Typography>
                        <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                            <FaTimes />
                        </IconButton>
                    </Box>

                    {item && item.id && (
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                                ID : <span style={{ userSelect: 'all', fontFamily: 'monospace' }}>{item.id}</span>
                            </Typography>
                        </Box>
                    )}

                    <SectionTitle>Informations générales</SectionTitle>
                    <TextField
                        fullWidth
                        label="Nom"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                        variant="outlined"
                    />

                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel>Catégories</InputLabel>
                        <Select
                            name="category"
                            label="Catégories"
                            value={formData.categoryId ? formData.categoryId : ''}
                            onChange={(e) => {
                                setFormData(prevData => ({ ...prevData, categoryId: e.target.value }));
                            }}
                        >
                            {foodCategories.map(category => (
                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <SectionTitle>Disponibilité</SectionTitle>
                    <FormControlLabel
                        control={<Checkbox checked={!formData.isOutOfStock} onChange={(e) => handleChange({ target: { name: 'isOutOfStock', type: 'checkbox', checked: !e.target.checked } })} />}
                        label="Disponible à la vente"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={!formData.isHidden} onChange={(e) => handleChange({ target: { name: 'isHidden', type: 'checkbox', checked: !e.target.checked } })} />}
                        label="Visible sur la boutique"
                    />

                    <SectionTitle>Description</SectionTitle>
                    <TextField
                        fullWidth
                        label="Description du produit"
                        name="description"
                        multiline
                        rows={4}
                        value={formData.description}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                    />
                    <SectionTitle>Base</SectionTitle>
                    <BaseIngredient
                        baseIngredient={baseIngredient}
                        onChangeClick={() => setBaseDialogOpen(true)}
                    />

                    <SectionTitle>Ingrédients</SectionTitle>
                    <IngredientsTable
                        ingredients={standardIngredients}
                        onMove={handleMoveStandardIngredients}
                        onRemove={handleRemoveStandardIngredient}
                        onAdd={() => setIngredientsDialogOpen(true)}
                    />
                    <SectionTitle>Image</SectionTitle>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Taille recommandée : 1024 x 1024 px, zone centrale toujours visible : 1024 x 700 px, 2 Mo maximum.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
                        <Box
                            sx={{
                                width: 150,
                                height: 150,
                                border: '1px solid',
                                borderColor: 'divider',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                                borderRadius: 2
                            }}
                        >
                            {imagePreview ? (
                                <img src={imagePreview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            ) : (
                                <img src="https://via.placeholder.com/150?text=min%20300x300" alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            )}
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                minWidth: 200,
                                height: 150,
                                border: '2px dashed',
                                borderColor: 'primary.main',
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                }
                            }}
                            onClick={() => fileInputRef.current.click()}
                            onDrop={handleDrop}
                        >
                            {isUploading ? (
                                <Loader />
                            ) : (
                                <>
                                    <FaUpload size={24} color="primary" />
                                    <Typography variant="body2" color="primary" align="center" sx={{ mt: 1 }}>
                                        Glissez un fichier ici ou <span style={{ textDecoration: 'underline' }}>sélectionnez un fichier</span>
                                    </Typography>
                                </>
                            )}
                            <input
                                ref={fileInputRef}
                                type="file"
                                hidden
                                accept=".png,.jpg,.jpeg,.gif,.svg"
                                onChange={handleImageUpload}
                            />
                        </Box>
                    </Box>

                    <SectionTitle>Allergènes</SectionTitle>
                    <AllergenSelect
                        value={formData.allergens}
                        onChange={handleAllergenChange}
                    />

                    <SectionTitle>Prix</SectionTitle>
                    <PriceTable
                        prices={formData.prices}
                        onChange={handlePriceChange}
                        onAdd={handleAddPrice}
                        onDelete={handleDeletePrice}
                    />

                    <SectionTitle>Suppléments</SectionTitle>
                    <SupplementsTable
                        supplements={itemSupplements}
                        onMove={handleMoveSupplements}
                        onRemove={handleRemoveSupplement}
                        onAdd={handleOpenSelectSupplementDialog}
                    />
                </Box>
                <SelectBaseDialog
                    open={isBaseDialogOpen}
                    onClose={() => setBaseDialogOpen(false)}
                    ingredients={ingredients}
                    currentBaseId={baseIngredient?.id}
                    onSelect={handleBaseSelect}
                />

                <SelectIngredientsDialog
                    open={isIngredientsDialogOpen}
                    onClose={() => setIngredientsDialogOpen(false)}
                    ingredients={ingredients}
                    selectedIngredients={standardIngredients}
                    onSelect={handleIngredientsSelect}
                />


                <SelectSupplementDialog
                    open={isSelectSupplementDialogOpen}
                    onClose={handleCloseSelectSupplementDialog}
                    onSelect={handleSelectSupplement}
                    supplements={supplements}
                    selectedSupplements={itemSupplements}
                />

            </Box>
            <Box sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                zIndex: 1
            }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isUploading || isSaving}
                    startIcon={<FaTimes />}
                    sx={{
                        minWidth: '120px',
                        color: 'text.secondary',
                        borderColor: 'text.secondary',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    Annuler
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {(isUploading || isSaving) && (
                        <CircularProgress size={24} sx={{ mr: 2 }} />
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isUploading || isSaving}
                        startIcon={<FaSave />}
                        sx={{
                            minWidth: '120px',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            }
                        }}
                    >
                        {isSaving ? 'Enregistrement...' : 'Enregistrer'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default MenuItemForm;

