import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    List,
    ListItem,
    Drawer,
    MenuItem,
    CircularProgress,
    Chip,
} from '@mui/material';
import { FaTimes, FaSave, FaPlus, FaTrash, FaEdit } from 'react-icons/fa';
import { formatCurrency, formatDays } from '../../utils/helpers';
import BenefitForm from './BenefitForm';
import SectionTitle from '../Common/Title';

const BenefitDisplay = ({ benefit, categories, menuItems }) => {
    const formatTime = (time) => {
        if (!time) return '';
        return time.slice(0, 5);
    };

    const renderAvailability = () => {
        return (
            <Box sx={{ mt: 1 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                    Disponible :
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                    <Chip
                        size="small"
                        variant="outlined"
                        label={formatDays(benefit.availableDays)}
                        color="primary"
                    />
                    {benefit.startTime && benefit.endTime && (
                        <Chip
                            size="small"
                            variant="outlined"
                            label={`${formatTime(benefit.startTime)} - ${formatTime(benefit.endTime)}`}
                            color="primary"
                        />
                    )}
                </Box>
            </Box>
        );
    };

    const renderBenefitContent = () => {
        switch (benefit.type) {
            case 'fidelityPoint':
                return (
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                            Points par catégorie :
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                            {(benefit.categoryPoints || []).map((cp, index) => {
                                const category = categories.find(c => c.id === cp.categoryId);
                                return (
                                    <Chip
                                        key={index}
                                        size="small"
                                        label={`${cp.points} pts - ${category?.name || 'catégorie inconnue'}`}
                                    />
                                );
                            })}
                        </Box>
                        {benefit.categoryRewards.length > 0 && (
                            <>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Récompenses :
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                    {(benefit.categoryRewards || []).map((cr, index) => {
                                        const category = categories.find(c => c.id === cr.categoryId);
                                        return (
                                            <Chip
                                                key={index}
                                                size="small"
                                                label={`${cr.pointsCost} pts - ${category?.name || 'catégorie inconnue'}`}
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </Box>
                            </>
                        )}
                    </Box>
                );

            case 'freeItem':
                return (
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                            Articles gratuits :
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                            {(benefit.freeItems || []).map((itemId, index) => {
                                const item = menuItems.find(i => i.id === itemId);
                                return (
                                    <Chip
                                        key={index}
                                        size="small"
                                        label={item?.name || 'Article inconnu'}
                                    />
                                );
                            })}
                        </Box>
                        <Chip
                            size="small"
                            variant="outlined"
                            label={`Minimum : ${formatCurrency(benefit.minOrderAmount)}`}
                            color="secondary"
                        />
                    </Box>
                );

            case 'percentageDiscount':
                return (
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                            Réduction fixe :
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            <Chip
                                size="small"
                                label={`${benefit.discountPercentage}% de réduction`}
                                color="success"
                            />
                            <Chip
                                size="small"
                                variant="outlined"
                                label={`Minimum : ${formatCurrency(benefit.minOrderAmount)}`}
                                color="secondary"
                            />
                        </Box>
                    </Box>
                );

            case 'fixedDiscount':
                return (
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                            Réduction pourcentage :
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            <Chip
                                size="small"
                                label={`Réduction de ${formatCurrency(benefit.discountAmount)}`}
                                color="success"
                            />
                            <Chip
                                size="small"
                                variant="outlined"
                                label={`Minimum : ${formatCurrency(benefit.minOrderAmount)}`}
                                color="secondary"
                            />
                        </Box>
                    </Box>
                );

            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {benefit.description}
            </Typography>
            {renderAvailability()}
            {renderBenefitContent()}
        </Box>
    );
};


const LoyaltyLevelEditPanel = ({
    level,
    categories,
    menuItems,
    isUpdating,
    onClose,
    onUpdate,
    onInputChange
}) => {
    const [drawerState, setDrawerState] = useState({
        open: false,
        mode: null, // 'add' ou 'edit'
        currentBenefit: null
    });

    const defaultBenefit = {
        id: '',
        type: 'fidelityPoint',
        runtimeType: 'fidelityPoint',
        description: '',
        availableDays: [],
        startTime: '',
        endTime: '',
        categoryPoints: [],
        categoryRewards: [],
        minOrderAmount: 0,
        freeItems: [],
        discountPercentage: 0,
        discountAmount: 0
    };

    const openDrawer = (mode, benefit = null) => {
        setDrawerState({
            open: true,
            mode,
            currentBenefit: mode === 'edit' ? { ...benefit } : { ...defaultBenefit }
        });
    };

    const closeDrawer = () => {
        setDrawerState({
            open: false,
            mode: null,
            currentBenefit: null
        });
    };

    const handleTypeChange = (e) => {
        const newType = e.target.value;
        onInputChange({
            target: {
                name: 'type',
                value: newType
            }
        });

        // Si le type passe à gratuit, on force le prix à 0
        if (newType === 'free') {
            onInputChange({
                target: {
                    name: 'price',
                    value: 0
                }
            });
        }
    };

    const handleSaveBenefit = () => {
        const isNewBenefit = drawerState.mode === 'add';
        const benefit = {
            ...drawerState.currentBenefit,
            id: isNewBenefit ? Date.now().toString() : drawerState.currentBenefit.id
        };

        const newBenefits = isNewBenefit
            ? [...level.benefits, benefit]
            : level.benefits.map(b => b.id === benefit.id ? benefit : b);

        onInputChange({
            target: {
                name: 'benefits',
                value: newBenefits
            }
        });

        closeDrawer();
    };

    const handleRemoveBenefit = (benefitId) => {
        onInputChange({
            target: {
                name: 'benefits',
                value: level.benefits.filter(b => b.id !== benefitId)
            }
        });
    };

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            p: 3,
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight="bold" color="primary.main">
                    {level.id ? 'Modifier le niveau' : 'Nouveau niveau'}
                </Typography>
                <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                    <FaTimes />
                </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 10 }}>
                <SectionTitle>Informations générales</SectionTitle>
                <TextField
                    label="Nom du niveau"
                    name="name"
                    value={level.name}
                    onChange={onInputChange}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    select
                    label="Type"
                    name="type"
                    value={level.type || 'free'}
                    onChange={handleTypeChange}
                    fullWidth
                    margin="normal"
                >
                    <MenuItem value="free">Gratuit</MenuItem>
                    <MenuItem value="premium">Premium</MenuItem>
                </TextField>

                {/* Afficher le champ prix uniquement si le type est premium */}
                {level.type === 'premium' && (
                    <TextField
                        label="Prix"
                        name="price"
                        type="number"
                        value={level.price}
                        onChange={onInputChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            startAdornment: '€'
                        }}
                    />
                )}

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Durée"
                        name="duration.value"
                        type="number"
                        value={level.duration.value}
                        onChange={(e) => onInputChange({
                            target: {
                                name: 'duration',
                                value: { ...level.duration, value: parseInt(e.target.value) }
                            }
                        })}
                        fullWidth
                        margin="normal"
                    />

                    <TextField
                        select
                        label="Unité"
                        name="duration.unit"
                        value={level.duration.unit}
                        onChange={(e) => onInputChange({
                            target: {
                                name: 'duration',
                                value: { ...level.duration, unit: e.target.value }
                            }
                        })}
                        fullWidth
                        margin="normal"
                    >
                        <MenuItem value="day">Jour(s)</MenuItem>
                        <MenuItem value="week">Semaine(s)</MenuItem>
                        <MenuItem value="month">Mois</MenuItem>
                        <MenuItem value="year">Année(s)</MenuItem>
                    </TextField>
                </Box>

                <SectionTitle>Avantages</SectionTitle>

                <List>
                    {(level.benefits || []).map((benefit) => (
                        <ListItem
                            key={benefit.id}
                            sx={{
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                                mb: 1,
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                }
                            }}
                            secondaryAction={
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => openDrawer('edit', benefit)}
                                        color="primary"
                                    >
                                        <FaEdit />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleRemoveBenefit(benefit.id)}
                                        color="error"
                                    >
                                        <FaTrash />
                                    </IconButton>
                                </Box>
                            }
                        >
                            <Box sx={{ width: '100%' }}>
                                <BenefitDisplay
                                    benefit={benefit}
                                    categories={categories}
                                    menuItems={menuItems}
                                />
                            </Box>
                        </ListItem>
                    ))}
                </List>

                <Button
                    startIcon={<FaPlus />}
                    onClick={() => openDrawer('add')}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Ajouter un avantage
                </Button>
            </Box>

            <Box sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
                zIndex: 1,
                borderTop: '1px solid',
                borderColor: 'divider'
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onUpdate}
                    disabled={isUpdating}
                    startIcon={isUpdating ? <CircularProgress size={20} /> : <FaSave />}
                    sx={{
                        minWidth: '120px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        }
                    }}
                >
                    {isUpdating ? 'Mise à jour...' : 'Mettre à jour'}
                </Button>
            </Box>

            <Drawer
                anchor="right"
                open={drawerState.open}
                onClose={closeDrawer}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 500,
                        boxSizing: 'border-box',
                        bgcolor: 'background.paper',
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 3,
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}>
                        <Typography variant="h4" fontWeight="bold" color="primary.main">
                            {drawerState.mode === 'add' ? 'Nouvel avantage' : 'Modifier l\'avantage'}
                        </Typography>
                        <IconButton onClick={closeDrawer} sx={{ color: 'text.secondary' }}>
                            <FaTimes />
                        </IconButton>
                    </Box>

                    {drawerState.currentBenefit && (
                        <BenefitForm
                            benefit={drawerState.currentBenefit}
                            setBenefit={(newBenefit) => setDrawerState(prev => ({
                                ...prev,
                                currentBenefit: typeof newBenefit === 'function'
                                    ? newBenefit(prev.currentBenefit)
                                    : newBenefit
                            }))}
                            categories={categories}
                            menuItems={menuItems}
                        />
                    )}

                    <Box sx={{
                        p: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        mt: 'auto',
                        bgcolor: 'background.paper'
                    }}>
                        <Button
                            variant="contained"
                            onClick={handleSaveBenefit}
                            disabled={!drawerState.currentBenefit?.description}
                            startIcon={<FaSave />}
                            fullWidth
                        >
                            {drawerState.mode === 'add' ? 'Ajouter' : 'Mettre à jour'}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default LoyaltyLevelEditPanel;
