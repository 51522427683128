import React, { useState, useEffect, useMemo } from 'react';
import { foodTruckService } from '../../services/foodTruckService';
import { menuService } from '../../services/menuService';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Drawer,
  Button,
  InputAdornment,
  Tooltip,
  Chip,
} from '@mui/material';
import { FaSearch, FaEllipsisV, FaPlus, FaStar } from 'react-icons/fa';
import Loader from '../Common/Loader';
import LoyaltyLevelEditPanel from './LoyaltyEditSidePanel';
import { formatCurrency, formatDays } from '../../utils/helpers';
import { useFirebase } from '../Auth/FirebaseProvider';
import { categoriesService } from '../../services/categoriesService';

const LoyaltyProgramEditor = () => {
  const { foodTruckId, loading: contextLoading } = useFirebase();
  const [loyaltyLevels, setLoyaltyLevels] = useState([]);
  const [filteredLevels, setFilteredLevels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editedLevel, setEditedLevel] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (!foodTruckId || contextLoading) return;

    let menuUnsubscribe;

    const initializeServices = () => {
      try {
        const foodTruckSrv = foodTruckService.getInstance(foodTruckId);
        const menuSrv = menuService.getInstance(foodTruckId);
        const categoriesSrv = categoriesService.getInstance(foodTruckId);
        setServices({ foodTruckSrv, menuSrv, categoriesSrv });
        return { foodTruckSrv, menuSrv, categoriesSrv };
      } catch (error) {
        console.error("Error initializing services:", error);
        throw error;
      }
    };

    const loadData = async () => {
      try {
        setLoading(true);
        const { foodTruckSrv, menuSrv, categoriesSrv} = initializeServices();

        // Configurer l'écouteur du menu
        menuUnsubscribe = menuSrv.listenMenu(
          (menuData) => {
            setMenuItems(menuData);
          },
          (error) => {
            console.error("Error listening to menu:", error);
            setError("Erreur lors du chargement du menu");
          }
        );

        // Charger les données du programme de fidélité
        const loyalty = await foodTruckSrv.getLoyaltyProgram();

        const categories = await categoriesSrv.getCategories();

        setLoyaltyLevels(loyalty?.levels || []);
        setFilteredLevels(loyalty?.levels || []);
        setCategories(categories);
      } catch (error) {
        console.error("Error loading data:", error);
        setError("Erreur lors du chargement des données");
      } finally {
        setLoading(false);
      }
    };

    loadData();

    return () => {
      if (menuUnsubscribe) menuUnsubscribe();
    };
  }, [foodTruckId, contextLoading]);


  const columns = [
    { id: 'name', label: 'Nom du niveau' },
    { id: 'price', label: 'Prix' },
    { id: 'duration', label: 'Durée' },
    { id: 'benefits', label: 'Avantages' },
  ];
  const formatTime = (time) => {
    if (!time) return '';
    return time.slice(0, 5); // Format HH:mm
  };

  const formatBenefitDescription = (benefit) => {
    let baseInfo = benefit.description

    // Ajoute les jours uniquement s'ils sont définis (restriction)
    if (benefit.availableDays?.length) {
      baseInfo += `\nDisponible : ${formatDays(benefit.availableDays)}`;
    }

    // Ajoute les horaires uniquement s'ils sont tous les deux définis (restriction)
    if (benefit.startTime && benefit.endTime) {
      baseInfo += `\nHoraires : ${formatTime(benefit.startTime)} - ${formatTime(benefit.endTime)}`;
    }

    switch (benefit.type) {
      case 'fidelityPoint':
        const pointsInfo = benefit.categoryPoints
          .map(cp => {
            const category = categories.find(c => c.id === cp.categoryId);
            return `${cp.points} points sur ${category?.name || 'catégorie inconnue'}`;
          })
          .join('\n');

        const rewardsInfo = benefit.categoryRewards
          .map(cr => {
            const category = categories.find(c => c.id === cr.categoryId);
            return `${cr.pointsCost} points pour ${category?.name || 'catégorie inconnue'}`;
          })
          .join('\n');

        return `${baseInfo}\n${pointsInfo}\n${rewardsInfo}`;

      case 'freeItem':
        const items = benefit.freeItems
          .map(itemId => menuItems.find(i => i.id === itemId)?.name || 'Article inconnu')
          .join(', ');

        return `${baseInfo}\n` +
          `Articles gratuits : ${items}\n` +
          `Montant minimum de commande : ${formatCurrency(benefit.minOrderAmount)}`;

      case 'percentageDiscount':
        return `${baseInfo}\n` +
          `${benefit.discountPercentage}% de réduction\n` +
          `Montant minimum de commande : ${formatCurrency(benefit.minOrderAmount)}`;

      case 'fixedDiscount':
        return `${baseInfo}\n` +
          `Réduction de ${formatCurrency(benefit.discountAmount)}\n` +
          `Montant minimum de commande : ${formatCurrency(benefit.minOrderAmount)}`;

      default:
        return baseInfo;
    }
  };

  const getBenefitLabel = (benefit) => {
    switch (benefit.type) {
      case 'fidelityPoint':
        return `Fidélité`;
      case 'freeItem':
        if(benefit.freeItems.length > 1) {
          return `Articles gratuits`;
        }
        const item = menuItems.find((item) => item.id === benefit.freeItems[0]);
        return `${item?.name} offert`;
      case 'percentageDiscount':
        return `${benefit.discountPercentage}%`;
      case 'fixedDiscount':
        return formatCurrency(benefit.discountAmount);
      default:
        return '';
    }
  };


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filtered = loyaltyLevels.filter(level =>
      level.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLevels(filtered);
  };

  const handleAddLevel = () => {
    const newLevel = {
      id: Date.now().toString(),
      name: '',
      price: 0,
      duration: { value: 1, unit: 'year' },
      benefits: []
    };
    setEditedLevel(newLevel);
    setIsSidePanelOpen(true);
  };

  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setEditedLevel({ ...level });
    setIsSidePanelOpen(true);
  };

  const handleLevelUpdate = async () => {
    if (!services?.foodTruckSrv) return;

    setIsUpdating(true);
    try {
      const updatedLevels = selectedLevel
        ? loyaltyLevels.map(l => l.id === editedLevel.id ? editedLevel : l)
        : [...loyaltyLevels, editedLevel];

      await services.foodTruckSrv.updateLoyaltyProgram({
        levels: updatedLevels
      });

      setLoyaltyLevels(updatedLevels);
      setFilteredLevels(updatedLevels);
      setIsSidePanelOpen(false);
      setSelectedLevel(null);
    } catch (error) {
      console.error("Error updating level:", error);
      setError("Erreur lors de la mise à jour du niveau");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedLevel(prev => ({ ...prev, [name]: value }));
  };

  const sortedLevels = useMemo(() => {
    return [...filteredLevels].sort((a, b) => {
      const isAsc = order === 'asc';
      if (orderBy === 'duration') {
        const aValue = a.duration?.value || 0;
        const bValue = b.duration?.value || 0;
        return isAsc ? aValue - bValue : bValue - aValue;
      }
      if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
      return 0;
    });
  }, [filteredLevels, order, orderBy]);

  if (contextLoading || loading) return <Loader />;
  if (!foodTruckId) return <Typography color="error">Aucun restaurant sélectionné</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          <FaStar className="mr-3 text-emerald-500" />
          Programme de Fidélité
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={handleAddLevel}
        >
          Ajouter un niveau
        </Button>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Rechercher un niveau..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaSearch />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLevels.map((level) => (
              <TableRow key={level.id} hover>
                <TableCell>{level.name}</TableCell>
                <TableCell>{formatCurrency(level.price)}</TableCell>
                <TableCell>
                  {level.duration.value} {
                    level.duration.unit === 'day' ? 'jour(s)' :
                      level.duration.unit === 'week' ? 'semaine(s)' :
                        level.duration.unit === 'month' ? 'mois' :
                          'année(s)'
                  }
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                    {level.benefits?.length > 0 ? (
                      level.benefits.map((benefit) => (
                        <Tooltip
                          key={benefit.id}
                          title={
                            <Box component="span" sx={{ whiteSpace: 'pre-line' }}>
                              {formatBenefitDescription(benefit)}
                            </Box>
                          }
                          arrow
                        >
                          <Box>
                            <Chip
                              label={getBenefitLabel(benefit)}
                              size="medium"
                              sx={{
                                '& .MuiChip-icon': {
                                  color: 'inherit'
                                }
                              }}
                            />
                          </Box>
                        </Tooltip>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        Aucun avantage
                      </Typography>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleLevelSelect(level)}>
                    <FaEllipsisV />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer
        anchor="right"
        open={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
        sx={{
            '& .MuiDrawer-paper': {
                width: 600,
                boxSizing: 'border-box',
                bgcolor: 'background.paper',
            },
        }}
      >
        <LoyaltyLevelEditPanel
          level={editedLevel}
          isUpdating={isUpdating}
          menuItems={menuItems} 
          onClose={() => setIsSidePanelOpen(false)}
          onUpdate={handleLevelUpdate}
          onInputChange={handleInputChange}
          categories={categories}
        />
      </Drawer>
    </Box>
  );
};

export default LoyaltyProgramEditor;