import React from 'react';
import { formatTime } from '../../utils/helpers';
import Loader from '../Common/Loader';
import { Typography } from '@mui/material';



const SlotSelector = ({ suggestedSlots, loading, selectedSlot, onSelectSlot }) => {

    if (loading) return <Loader />;
    if (suggestedSlots.length === 0) return <Typography color="error">Aucun créneau disponible pour le moment.</Typography>;

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {suggestedSlots.map((slot, index) => (
                <div
                    key={index}
                    className={`p-3 border rounded cursor-pointer text-center text-lg 
              ${selectedSlot === slot.timeSlot ? 'bg-blue-100 border-blue-500' : 'hover:bg-gray-100'}`}
                    onClick={() => onSelectSlot(slot.timeSlot)}
                >
                    À {formatTime(slot.timeSlot)}
                </div>
            ))}
        </div>
    );
};

export default SlotSelector;