import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Chip, Tooltip } from '@mui/material';
import { FaPlus, FaMinus, FaUndo, FaTimes } from 'react-icons/fa';
import { formatCurrency } from '../../utils/helpers';

function PaymentDialog({ 
  open, 
  onClose, 
  paymentTypeList = [], 
  totalAmount = 0, 
  onConfirm, 
  existingPayments = []
}) {
  const [mixedPayment, setMixedPayment] = useState({});
  const [remaining, setRemaining] = useState(totalAmount);
  const [activeType, setActiveType] = useState('');

  const initializePayments = useCallback(() => {
    const initialMixedPayment = {};
    if (Array.isArray(paymentTypeList)) {
      paymentTypeList.forEach(type => {
        const existingAmount = (Array.isArray(existingPayments) ? existingPayments : [])
          .filter(p => p?.method?.type === type.toLowerCase())
          .reduce((sum, p) => sum + (p?.amount || 0), 0);
        initialMixedPayment[type] = existingAmount;
      });
    }
    return initialMixedPayment;
  }, [paymentTypeList, existingPayments]);

  useEffect(() => {
    if (open) {
      const initialPayments = initializePayments();
      setMixedPayment(initialPayments);
      
      const totalPaid = Object.values(initialPayments).reduce((sum, amount) => sum + Number(amount || 0), 0);
      setRemaining(Math.max(0, totalAmount - totalPaid));
      
      setActiveType('');
    }
  }, [open, totalAmount, initializePayments]);

  const handleMixedPaymentChange = (type, value) => {
    const newAmount = Math.max(0, Number(value) || 0);
    const oldAmount = Number(mixedPayment[type]) || 0;
    const difference = newAmount - oldAmount;

    if (remaining - difference < 0 && difference > 0) {
      return;
    }

    const newMixedPayment = {
      ...mixedPayment,
      [type]: newAmount
    };

    setMixedPayment(newMixedPayment);
    const newTotal = Object.values(newMixedPayment).reduce((sum, amount) => sum + Number(amount || 0), 0);
    setRemaining(Math.max(0, totalAmount - newTotal));
  };

  const handleQuickAdd = (amount) => {
    if (!activeType || remaining < amount) return;

    const newAmount = (Number(mixedPayment[activeType]) || 0) + amount;
    handleMixedPaymentChange(activeType, newAmount);
  };

  const calculateTotalPaid = () => {
    return Object.values(mixedPayment).reduce((sum, amount) => sum + Number(amount || 0), 0);
  };

  const calculateChange = () => {
    const totalPaid = calculateTotalPaid();
    return Math.max(0, totalPaid - totalAmount);
  };

  const handleReset = () => {
    const initialPayments = initializePayments();
    setMixedPayment(initialPayments);
    const totalPaid = Object.values(initialPayments).reduce((sum, amount) => sum + Number(amount || 0), 0);
    setRemaining(Math.max(0, totalAmount - totalPaid));
    setActiveType('');
  };

  const handleConfirm = () => {
    const validPayments = Object.fromEntries(
      Object.entries(mixedPayment).filter(([_, amount]) => Number(amount) > 0)
    );
    onConfirm(validPayments);
    onClose();
  };

  const renderQuickAddButtons = () => {
    const amounts = [1, 2, 5, 10, 20, 50];
    return amounts.map(amount => (
      <Button
        key={amount}
        variant="outlined"
        size="small"
        onClick={() => handleQuickAdd(amount)}
        disabled={!activeType || remaining < amount}
        style={{ minWidth: '40px', margin: '0 4px' }}
      >
        {amount}
      </Button>
    ));
  };

  // Vérification de sécurité pour paymentTypeList
  if (!Array.isArray(paymentTypeList) || paymentTypeList.length === 0) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <div>Aucun type de paiement disponible.</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <span>Paiement</span>
          <div className="flex items-center gap-2">
            {Array.isArray(existingPayments) && existingPayments.length > 0 && (
              <span className="text-sm text-gray-500">
                Paiements précédents: {formatCurrency(totalAmount)}
              </span>
            )}
            <Tooltip title="Fermer">
              <IconButton onClick={onClose} size="small">
                <FaTimes />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-wrap gap-2 mb-4">
          {paymentTypeList.map((type) => (
            <Chip
              key={type}
              label={type}
              onClick={() => setActiveType(type)}
              color={activeType === type ? "primary" : "default"}
              variant={activeType === type ? "filled" : "outlined"}
            />
          ))}
        </div>
        <div className="flex justify-center mb-4">
          {renderQuickAddButtons()}
        </div>
        <div className="grid grid-cols-1 gap-4 mt-4">
          {paymentTypeList.map((type) => (
            <div key={type} className="flex items-center justify-between border-b pb-2">
              <span className="font-medium">{type}</span>
              <div className="flex items-center">
                <IconButton 
                  onClick={() => handleMixedPaymentChange(type, (mixedPayment[type] || 0) - 1)} 
                  size="small"
                  disabled={!mixedPayment[type]}
                >
                  <FaMinus fontSize="small" />
                </IconButton>
                <TextField
                  type="number"
                  value={mixedPayment[type] || ''}
                  onChange={(e) => handleMixedPaymentChange(type, e.target.value)}
                  inputProps={{ min: 0, step: 0.01 }}
                  variant="outlined"
                  size="small"
                  style={{ width: '80px', margin: '0 8px' }}
                />
                <IconButton 
                  onClick={() => handleMixedPaymentChange(type, (mixedPayment[type] || 0) + 1)} 
                  size="small"
                  disabled={remaining <= 0}
                >
                  <FaPlus fontSize="small" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold text-lg">Total à payer :</span>
            <span className="font-bold text-lg">{formatCurrency(totalAmount)}</span>
          </div>
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold text-lg">Total payé :</span>
            <span className="font-bold text-lg text-green-600">{formatCurrency(calculateTotalPaid())}</span>
          </div>
          {remaining > 0 ? (
            <div className="flex justify-between items-center">
              <span className="font-bold text-lg">Restant :</span>
              <span className="font-bold text-lg text-red-600">{formatCurrency(remaining)}</span>
            </div>
          ) : (
            <div className="flex justify-between items-center">
              <span className="font-bold text-lg">Monnaie à rendre :</span>
              <span className="font-bold text-lg text-blue-600">{formatCurrency(calculateChange())}</span>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Tooltip title="Réinitialiser">
          <Button onClick={handleReset} color="secondary" startIcon={<FaUndo />}>
            Réinitialiser
          </Button>
        </Tooltip>
        <Button 
          onClick={handleConfirm} 
          color="primary" 
          variant="contained" 
          disabled={remaining > 0}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentDialog;